import { useQuery } from "react-query";
import axios from "axios";

function useGetQuery(url) {
  const { data, isLoading, error } = useQuery(url, async () => {
    const { data } = await axios.get(url);
    return data;
  });

  return [data, isLoading, error];
}

export default useGetQuery;


