import {
  isSelectedTeam,
  addToDisabledTeams,
  formatToNumber,
  orderData,
} from "pages/Games/siege_r6/lib/utils";
import { disableTeams } from "./components/DisableTeams";
import { useSelector, useDispatch } from "react-redux";
import { setDisabledTeams, setSelectedTeam } from "data/r6/CreateTeamSlice";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { useEffect, useState } from "react";
import useGetQuery from "hooks/useGetQuery";
import TableSkeleton from "components/skeletons/Table";

const Teams = ({ slug }) => {
  const teamRosterDataUrl =
    "https://script.google.com/macros/s/AKfycbxDaYLQH4dMclrGHbSs4A1-68DTKaDY_ThqATZO2fzIT7IfTfYH9m2EoNSJThglvwp9sg/exec";
  const [data, isLoading, error] = useGetQuery(
    `${teamRosterDataUrl}?id=${slug}`
  );

  const [teamsData, setTeamData] = useState([]);

  useEffect(() => {
    if (data) {
      setTeamData(orderData(data.data, "price"));
    }
  }, [data]);

  const { selectedPlayers, disabledTeams, selectedTeam } = useSelector(
    (state) => state.create_team_r6
  );
  const dispatch = useDispatch();

  const removeTeam = () => {
    dispatch(setSelectedTeam({}));

    const { disabledTeams } = disableTeams({}, selectedPlayers, teamsData);

    dispatch(setDisabledTeams(disabledTeams));
  };

  function selectTeam(team) {
    dispatch(setSelectedTeam(team));

    const { disabledTeams } = disableTeams(team, selectedPlayers, teamsData);

    dispatch(setDisabledTeams(disabledTeams));

    if (team.team_name) {
      const disabledTeams = addToDisabledTeams(teamsData);
      dispatch(setDisabledTeams(disabledTeams));
    }
  }

  if (isLoading) {
    return <TableSkeleton header={["Team", "Price", "Add"]} />;
  }

  if (error) {
    return (
      <div className=" bg-[#2C2A36] flex justify-center items-center">
        Error: {error.message}
      </div>
    );
  }
  return (
    <div className="relative overflow-auto whitespace-nowrap ">
      <table className=" text-left w-full primary-font ">
        <thead>
          <tr className="secondary-font">
            <th>Team</th>
            <th>Price</th>
            <th className=" sticky bg-[#ffffff0d] right-0  backdrop-blur-lg text-center ">
              Add
            </th>
          </tr>
        </thead>
        <tbody>
          {teamsData?.map((team, index) => (
            <tr
              key={team.team_code}
              className={`${
                isSelectedTeam(team, selectedTeam)
                  ? ""
                  : disabledTeams[team.team_code]
                  ? " bg-gray-700 opacity-40"
                  : ""
              } relative`}
            >
              <td
                className={`${
                  isSelectedTeam(team, selectedTeam)
                    ? " bg-[#191b2a] opacity-20"
                    : ""
                }`}
              >
                <div className="flex gap-4 items-center">
                  <img
                    src={`${team?.team_logo_url}`}
                    alt={`${team?.team_name}`}
                    width={24}
                  />
                  <p className="">{team.team_name}</p>
                </div>
              </td>

              <td
                className={`${
                  isSelectedTeam(team, selectedTeam)
                    ? " bg-[#191b2a] opacity-20"
                    : ""
                }`}
              >
                <p className="text-[#f9d25f] secondary-font font-medium text-lg">
                  ${formatToNumber(team.price, 0)}
                </p>
              </td>

              <td className="flex justify-center sticky bg-[#ffffff0d] right-0 backdrop-blur-lg ">
                {isSelectedTeam(team, selectedTeam) ? (
                  <button
                    className="p-1 border-2 border-[#F25F4C] w-fit flex content-end rounded cursor-pointer  text-[#F25F4C] "
                    onClick={() => removeTeam()}
                  >
                    <IoIosRemove size={24} />
                  </button>
                ) : (
                  <button
                    className="p-1 border w-fit flex content-end rounded cursor-pointer  "
                    onClick={() => selectTeam(team)}
                    disabled={disabledTeams[team.team_code]}
                  >
                    <IoIosAdd size={24} />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Teams;
