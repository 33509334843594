import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  playerName: "",
  teamName: "",
  price: [undefined, undefined],
};

export const FilterRosterSlice = createSlice({
  name: "filter_roster",
  initialState,
  reducers: {
    setPlayerName: (state, action) => {
      state.playerName = action.payload;
    },
    setTeamName: (state, action) => {
      state.teamName = action.payload;
    },
    setPrice: (state, action) => {
      state.price = [...action.payload];
    },
      reset: (state) => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setPlayerName, setTeamName, setPrice, reset } = FilterRosterSlice.actions;

export default FilterRosterSlice.reducer;
