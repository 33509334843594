import React, { useEffect, useRef } from "react";
import { SiDiscord } from "react-icons/si";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";

const MainSide = (props) => {
  const prevPropsRef = useRef(null);

  useEffect(() => {
    if (
      prevPropsRef.current &&
      prevPropsRef.current.text !== props.gameDetails.Title
    ) {
      const div = document.querySelector(".move-game-details");
      div.classList.add("hidden");
      setTimeout(() => {
        div.classList.remove("hidden");
      }, 0);
    }
    prevPropsRef.current = props.gameDetails.Title;
  });

  return (
    <div className="px-4 lg:pr-20 lg:py-16 flex flex-col min-h-screen secondary-font justify-around lg:justify-between ">
      <header className="hidden lg:block">
        <div className="flex gap-8  font-medium text-lg justify-end">
          <Link to={"/leaderboard"}>
            <p className="text-[#fffffe] transition ease-in-out delay-150  hover:opacity-80 relative nav">
              Leaderboard
            </p>
          </Link>
          <Link to={"/about"}>
            <p className="text-[#fffffe] transition ease-in-out delay-150  hover:opacity-80 relative nav">
              About Us
            </p>
          </Link>
        </div>
      </header>

      <div className=" flex flex-col gap-12 items-end move-main lg:pt-32 xl:pt-0 ">
        <div className="flex flex-col items-end gap-2">
          <h3 className="text-[#fffffe] text-xl md:text-3xl xl:text-4xl text-right">
            We Are A <br /> Fantasy Esports Discord Server
          </h3>
          <h3 className="text-[#fffffed7]  text-right text-md  md:text-2xl xl:text-3xl">
            We Host Fantasy Tournaments For...
          </h3>
        </div>
        <div className="flex flex-col gap-8 move-game-details">
          <div className="flex flex-col gap-4 ">
            <h1 className="text-3xl md:text-5xl xl:text-6xl primary-font font-bold text-[#F9D25F]  text-right">
              {props.gameDetails.Title}
            </h1>
            <p className=" md:w-[600px] text-right text-[#fffffeb1] text-md md:text-lg xl:text-xl">
              {props.gameDetails.Description}
            </p>
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row gap-4 text-sm">
          <a
            href="https://discord.gg/pYxKpat"
            className="flex gap-2 items-center border-solid border-[#F9D25F] border-2 px-4 py-2 lg:py-4 lg:px-8 rounded lg:rounded-lg text-[#F9D25F] hover:bg-[#F9D25F] hover:text-[#434343] duration-500"
            target="_blank"
          >
            <p>Join Our Discord</p>
            <SiDiscord size={24} />
          </a>
          {/* <Link
          
         to={'overall-leaderboard'}
          className="flex gap-2 bg-[#F9D25F] px-4 py-2 lg:py-4 lg:px-8 rounded lg:rounded-lg text-[#434343] hover:scale-105 duration-500 font-bold items-center justify-center"
        >
          Overall Leaderboard
        </Link> */}
        </div>
      </div>
      <footer className="flex flex-col items-end gap-4">
        <h1 className="text-[#FFFFFE]">Follow Us</h1>
        <div className="flex gap-8 text-[#fffffe]">
          <a
            href="https://twitter.com/InfamyEsports"
            className="link"
            target="_blank"
          >
            <AiFillTwitterCircle size={24} />
          </a>
          <a
            href="https://www.tiktok.com/@infamyesports"
            className="link"
            target="_blank"
          >
            <FaTiktok size={24} />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default MainSide;
