import React from "react";
import { Route, Routes } from "react-router-dom";
import LeaderBoard from "./LeaderBoard/LeaderBoard";
import SideBar from "../../components/SideBar";
import QuickStats from "./StatsOverview/QuickStats";
import PlayerRoster from "./ProPlayerRoster/PlayerRoster";
import PlayerPerformance from "./ProPlayerPerformance/PlayerPerformance";
import Rules from "./Rules/Rules";
import Matches from "./Matches/Matches";

const TournamentDetails = () => {
  let currentPath = window.location.pathname;
  currentPath = currentPath.split("/");
  const tournamentSlug = currentPath[4];

  return (
    <div className=" grid grid-cols-[auto_3fr] h-[100%] overflow-hidden text-white secondary-font">
      <SideBar slug={tournamentSlug} game={"r6-siege"} />

      <Routes>
        <Route
          path={`/rules/${tournamentSlug}`}
          element={<Rules tournamentSlug={tournamentSlug} />}
        />
        {/* https://script.google.com/macros/s/AKfycbxr7plOy-EZhOhpiCM_G7xGNUwSyvuVADx6-xIE7id1H_lOH8V5LAoDLUeKMbwZD1x0Dg/exec */}
        <Route
          path={`/quick-stats/${tournamentSlug}`}
          element={<QuickStats tournamentSlug={tournamentSlug} />}
        />
        {/* https://script.google.com/macros/s/AKfycbzkGtoCbZgNpPDBXZyNT0nag11XcvX4Ku7PDh9koacNdZaJpDHYY12ZTSS7UA6LjAgJ-g/exec */}
        <Route
          path={`/leaderboard/${tournamentSlug}`}
          element={<LeaderBoard tournamentSlug={tournamentSlug} />}
        />
        {/* https://script.google.com/macros/s/AKfycbwZdKVQkJQZzsoZi6cgjYEAkNoXK0-Gie-jWcqEXF7jYDYm_3oxu0Rw-DqErDBaiwie3g/exec */}
        <Route
          path={`/roster/${tournamentSlug}/*`}
          element={<PlayerRoster tournamentSlug={tournamentSlug} />}
        />
        {/* Pro player */}
        {/* https://script.google.com/macros/s/AKfycby1g3pVop0PR9bEAcK7HA9S9E9uZanVGXzQr-eKb1dBzBDQjECWHeqnXewo-yK9QjVrvA/exec */}

        {/* Team */}
        {/* https://script.google.com/macros/s/AKfycbxDaYLQH4dMclrGHbSs4A1-68DTKaDY_ThqATZO2fzIT7IfTfYH9m2EoNSJThglvwp9sg/exec */}
        <Route
          path={`/performance/${tournamentSlug}`}
          element={<PlayerPerformance tournamentSlug={tournamentSlug} />}
        />
        {/* Pro Player */}
        {/* https://script.google.com/macros/s/AKfycbwWr2ujrtzVB761VU5vho0CUQbDxQZ3I9cK1gjpeETv7A_molLcfRrcCRJkmCmjSGrUOQ/exec */}

        {/* Team */}
        {/* https://script.google.com/macros/s/AKfycbwAyAQy0Lu_XjqcdhkzfQ2ukM6MAbUiGgAgqpRyJuDbFMhJibd_xCXVmDjmsYMD1yyV/exec */}
        <Route
          path={`/matches/${tournamentSlug}`}
          element={<Matches tournamentSlug={tournamentSlug} />}
        />
        {/* https://script.google.com/macros/s/AKfycbz_TiP7aHsWU6OPPxhuILGmivt_W0oqtFCRQsFavp9Ny2n27MCA9NjwLsiH5axheRuQ/exec */}
      </Routes>
    </div>
  );
};

export default TournamentDetails;

/**
    R6 Siege  
    Tournament Id---8
    Tournament Name---Valorant Champions 2023
    ----------
    Team--Price
    ----------
    M80--14
    ----------
    Position--Pro_Player--Price
    ----------
    Pro 1--Spoit--15
    Pro 2--Virtue--16
    Pro 3--Phish--17
    Entry--Kheyze--15
    Support--nats--22
    ----------
 */
