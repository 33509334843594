import { useState } from "react";

export function ClipboardCopy({ copyText, players, hasError }) {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
  
      <button
        className={`text-[#674ea7] border-2 border-[#674ea7] px-4 py-2 rounded-md font-bold ${
          players.length !== 5 || hasError? "opacity-50" : ""
        }`}
        disabled={players.length !== 5 || hasError}
        onClick={handleCopyClick}
        id="copy"
      >
        <span>{isCopied ? "Copied!" : "Copy"}</span>
      </button>
    </>
  );
}
