import { useState } from "react";

export function ClipboardCopy({ copyText, players, hasError, team}) {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
    {
      (players.length < 5 || Object.keys(team).length === 0) || hasError?(
        <button
        className={`text-[#674ea7] border-2 border-[#674ea7] px-4 py-2 rounded-md font-bold opacity-50`}
        disabled={true}
      >
        <span>{isCopied ? "Copied!" : "Copy"}</span>
      </button>
      ):(
        <button
        className={`text-[#674ea7] border-2 border-[#674ea7] px-4 py-2 rounded-md font-bold`}
        onClick={handleCopyClick}>
          <span>{isCopied ? "Copied!" : "Copy"}</span>

        </button>
      )
    }
        <a target="_blank" href="https://discord.gg/8CpMWe3z3x"
        className={`text-[#f9d25f] text-center underline px-4 py-2 rounded-md `}
       id="copy"
        >
        Go to Discord
      </a>
     
    </>
  );
}
