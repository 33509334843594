import {
  addToDisabledPlayers,
  getSalaryLeft,
  DataFilter,
  getTeam,
  getRoles,
} from "lib/utils";

export function disablePlayers(selectedPlayers, players) {
  let salaryLeft = getSalaryLeft(selectedPlayers);
  let filteredByPrice = new DataFilter(players)
    .filterByPrice(salaryLeft + 1)
    .getData();

  let team = getTeam(selectedPlayers);
  let filteredByTeam = new DataFilter(players).filterByTeam(team).getData();

  let [roles, selectedRoles] = getRoles(selectedPlayers);
  let filteredByRoles = new DataFilter(players)
    .filterByAllRoles(roles)
    .getData();

  const disabledPlayers = addToDisabledPlayers([
    ...filteredByPrice,
    ...filteredByTeam,
    ...filteredByRoles,
    ...selectedPlayers,
  ]);

  return {
    disabledPlayers,
    selectedRoles
  }
}
