import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setPlayerName,
  setTeamName,
  setPrice,
  setRole,
  reset,
} from "data/valorant/FilterRosterSlice";

const FilterInput = () => {
  const filterRoster = useSelector((state) => state.filter_roster_valorant);
  const dispatch = useDispatch();

  const handlePlayerNameChange = (event) => {
    dispatch(setPlayerName(event.target.value));
  };

  const handleTeamNameChange = (event) => {
    dispatch(setTeamName(event.target.value));
  };

  const handleMinMaxPrice = (price, otherPrice, isMax) => {
    let minPrice, maxPrice;
    if (isMax) {
      maxPrice = price.target.value;
      minPrice = otherPrice;
    } else {
      minPrice = price.target.value;
      maxPrice = otherPrice;
    }
    if (+minPrice < 0) {
      minPrice = 0;
    }
    if (+maxPrice <= 0) {
      maxPrice = Infinity;
    }
    dispatch(setPrice([minPrice, maxPrice]));
  };

  const handleRoleClick = (role) => {
    if (filterRoster.role.includes(role)) {
      let roleIndex = filterRoster.role.indexOf(role);
      let roles = [...filterRoster.role];
      roles.splice(roleIndex, 1);
      dispatch(setRole(roles));
    } else {
      let roles = [...filterRoster.role, role];
      dispatch(setRole(roles));
    }
  };

  const handleResetClick = () => {
    dispatch(reset());
  };

  return (
    <div className="flex flex-wrap gap-6 items-end text-base">
      <div className="flex flex-col items-start">
        <h1 className=" opacity-75">Player</h1>
        <input
          className=" bg-transparent outline-none border-b-2 border-solid py-2 w-[200px]"
          type="text"
          value={filterRoster.playerName}
          onChange={handlePlayerNameChange}
          placeholder="Search Player Here"
        />
      </div>
      <div className="flex flex-col items-start">
        <h1 className=" opacity-75">Team</h1>
        <input
          className=" bg-transparent outline-none border-b-2 border-solid py-2 w-[200px]"
          type="text"
          value={filterRoster.teamName}
          onChange={handleTeamNameChange}
          placeholder="Search Team Here"
        />
      </div>

      <div className="flex flex-col items-start">
        <h1 className=" opacity-75">Roles</h1>
        <div className="bg-transparent border-b-2 pb-2 border-solid flex flex-wrap gap-3">
          <button
            className={`p-1 rounded  duration-100 ${
              filterRoster.role.includes("Sentinel")
                ? `bg-[#f9d25f] text-[#191b2a] hover:scale-95`
                : `hover:text-[#f9d25f]`
            } `}
            onClick={() => handleRoleClick("Sentinel")}
          >
            Sentinel
          </button>
          <button
            className={`p-1 rounded  duration-100 ${
              filterRoster.role.includes("Duelist")
                ? `bg-[#f9d25f] text-[#191b2a] hover:scale-95`
                : `hover:text-[#f9d25f]`
            } `}
            onClick={() => handleRoleClick("Duelist")}
          >
            Duelist
          </button>
          <button
            className={`p-1 rounded  duration-100 ${
              filterRoster.role.includes("Initiator")
                ? `bg-[#f9d25f] text-[#191b2a] hover:scale-95`
                : `hover:text-[#f9d25f]`
            } `}
            onClick={() => handleRoleClick("Initiator")}
          >
            Initiator
          </button>
          <button
            className={`p-1 rounded  duration-100 ${
              filterRoster.role.includes("Controller")
                ? `bg-[#f9d25f] text-[#191b2a] hover:scale-95`
                : `hover:text-[#f9d25f]`
            } `}
            onClick={() => handleRoleClick("Controller")}
          >
            Controller
          </button>
        </div>
      </div>

      <div className="flex flex-col gap-2 items-start">
        <h1 className=" opacity-75">Price</h1>
        <div className="flex gap-2 ">
          <input
            className=" bg-transparent outline-none  border-b-2 border-solid py-2 w-[100px]"
            type="number"
            value={filterRoster.price[0]}
            onChange={(e) => {
              handleMinMaxPrice(e, filterRoster.price[1], false);
            }}
            placeholder="Min Price"
          />
          <input
            className=" bg-transparent outline-none border-b-2 border-solid py-2 w-[100px]"
            type="number"
            value={filterRoster.price[1]}
            onChange={(e) => {
              handleMinMaxPrice(e, filterRoster.price[0], true);
            }}
            placeholder="Max Price"
          />
        </div>
      </div>

      <button
        onClick={handleResetClick}
        className="bg-[#674EA750] py-2 px-4  rounded-lg hover:bg-[#674EA7] duration-300"
      >
        Reset
      </button>
    </div>
  );
};

export default FilterInput;
