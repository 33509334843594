import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  playerName: "",
  teamName: "",
  orderBy: "infamy_score",
  role: [],
};

export const FilterPerformanceSlice = createSlice({
  name: "filter_performance_overwatch",
  initialState,
  reducers: {
    setPlayerName: (state, action) => {
      state.playerName = action.payload;
    },
    setTeamName: (state, action) => {
      state.teamName = action.payload;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    reset: (state) => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setPlayerName, setTeamName, setOrderBy, setRole, reset } =
  FilterPerformanceSlice.actions;

export default FilterPerformanceSlice.reducer;
