import React, { useState, useEffect } from "react";
import TournamentDetailsHeader from "../../components/TournamentDetailsHeader";
import useFetch from "hooks/useFetch";
import SummaryStats from "./components/SummaryStats";
import Accolades from "./components/Accolades";
import InfamyScoreChart from "./components/InfamyScoreChart";
import KDAPointsChart from "./components/KDAPointsChart";
import Select from "react-select";
import { formatForDropdown } from "../../../../../lib/utils";
import useGetQuery from "hooks/useGetQuery";
import { ErrorBoundary } from "react-error-boundary";

const QuickStats = ({ tournamentSlug }) => {
  const overviewStatsUrl =
    "https://script.google.com/macros/s/AKfycbyY4Gg5E6WRv8fP_Kc4cL_m-5POgDeUoAp6gliVKIxuqTmZPKFV45UoeGMArF0_pjv8/exec";
  const [data, isLoading, error] = useGetQuery(
    `${overviewStatsUrl}?id=${tournamentSlug}`
  );
  // const [data, isLoading, error] = useFetch(
  //   `${overviewStatsUrl}?id=${tournamentSlug}`
  // );

  const [overviewStats, setOverviewStats] = useState({});
  const [accolades, setAccolades] = useState({});
  const [teamsOptions, setTeamsOptions] = useState([]);
  const [infamyScoreChartData, setInfamyScoreChartData] = useState([]);
  const [KDAChartData, setKDAChartData] = useState([]);
  const [viewType, setViewType] = useState("viewByTeams");
  const [selectedTeam, setSelectedTeam] = useState("");

  useEffect(() => {
    if (data) {
      overviewStatsData();
      AccoladesData();

      let infamyData = data.data.infamy_score_data;
      setTeamsOptions(formatForDropdown(infamyData));

      setInfamyScoreChartData(infamyData);

      let KDAData = data.data.k_d_a_data;
      setKDAChartData(KDAData);
    }
  }, [data]);

  const overviewStatsData = () => {
    let StatsData = data.data;
    setOverviewStats({
      salary: StatsData.salary,
      average_cost: StatsData.average_cost,
      average_infamy_score: StatsData.average_infamy_score,
      average_pick_rate: StatsData.average_pick_rate,
    });
  };

  const AccoladesData = () => {
    setAccolades({
      ...data.data.accolades,
    });
  };

  const handleSelectedTeam = (a) => {
    setSelectedTeam(a?.value);
  };

  const handleSelectedViewType = (a) => {
    setViewType(a?.value);
  };

  const viewOptions = [
    { value: "viewByTeams", label: "View By Teams" },
    { value: "viewByPlayers", label: "View By Players" },
  ];

  return (
    <div className=" p-2 sm:p-5 h-full overflow-y-scroll flex flex-col gap-5 ">
      <div className="bg-[#191B2A] rounded-xl p-3 sm:p-8 flex flex-col gap-8 primary-font shadow-lg">
        <h1 className="text-xl sm:text-2xl md:text-3xl font-semibold py-2 text-center sm:text-left">
          Stats Overview
        </h1>
        <ErrorBoundary
          fallback={<div>Error:Tournament Details Failed to Load</div>}
        >
          <TournamentDetailsHeader tournamentSlug={tournamentSlug} />
        </ErrorBoundary>
        <ErrorBoundary
          fallback={<div>Error: Summary Stats Failed to Load</div>}
        >
          <SummaryStats
            statsLoading={isLoading}
            statsError={error}
            summaryData={overviewStats}
          />
        </ErrorBoundary>
      </div>
      <div className="bg-[#191B2A] rounded-xl p-3 sm:p-8 primary-font flex flex-col gap-4 shadow-lg">
        <div className="flex justify-between gap-2 items-center flex-wrap">
          <h3 className=" text-2xl ">Charts</h3>

          <div className="flex gap-2 flex-wrap">
            <Select
              options={viewOptions}
              placeholder={"Select View Type"}
              defaultValue={viewOptions[0].value}
              isClearable={true}
              onChange={handleSelectedViewType}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#674EA780", // option hover background color
                  primary: "#674EA7", //control border color and selected option background color
                  neutral80: "white", //control text color
                  neutral0: "#191B2A", // control background color
                },
              })}
            />
            <Select
              options={teamsOptions}
              defaultValue={""}
              placeholder={"Select Teams"}
              isClearable={true}
              onChange={handleSelectedTeam}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#674EA780", // option hover background color
                  primary: "#674EA7", //control border color and selected option background color
                  neutral80: "white", //control text color
                  neutral0: "#191B2A", // control background color
                },
              })}
            />
          </div>
        </div>

        <div className="w-full flex flex-col gap-4 border-[#ffffff16] border-2 border-solid rounded-lg">
          <p className="text-lg border-b-2  border-[#ffffff16] border-solid p-2 sm:p-4">
            Infamy Score Chart
          </p>
          <ErrorBoundary fallback={<div>Error: Chart Failed to Load</div>}>
            <InfamyScoreChart
              statsLoading={isLoading}
              statsError={error}
              infamyScoreChartData={infamyScoreChartData}
              viewType={viewType}
              team={selectedTeam}
            />
          </ErrorBoundary>
        </div>
        <div className="w-full flex flex-col gap-4 border-[#ffffff16] border-2 border-solid rounded-lg">
          <p className="text-lg border-b-2  border-[#ffffff16] border-solid p-2 sm:p-4">
            KDA Points Charts
          </p>
          <ErrorBoundary fallback={<div>Error: Chart Failed to Load</div>}>
            <KDAPointsChart
              statsLoading={isLoading}
              statsError={error}
              KDAChartData={KDAChartData}
              viewType={viewType}
              team={selectedTeam}
            />
          </ErrorBoundary>
        </div>
      </div>
      <div className="bg-[#191B2A] rounded-xl p-3 sm:p-8 primary-font shadow-lg">
        <Accolades
          statsLoading={isLoading}
          statsError={error}
          accoladesData={accolades}
        />
      </div>
    </div>
  );
};

export default QuickStats;
