import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";

const InfamyBarChart = ({ chartData, axisLabels }) => {
  if (Object.keys(chartData).length === 0) {

    chartData =  {
      labels: [],
      datasets: [
        {
          label: "Players Infamy Score",
          data: [],
          backgroundColor: "rgba(150,150,150 )",
        },
      ],
    };
  }
  
  return <Bar
    data={chartData}
    options={{
      responsive: true,
      interaction: {
        mode: "index",
        intersect: false,
      },
      
      scales: {
        x: {
          display: true,
          title: {
            display: true,
            text: axisLabels.x,
          },
        },
        y: {
          display: true,
          title: {
            display: true,
            text: axisLabels.y,
          },
        },
      },
    }}
  />;
};

export default InfamyBarChart;