import React from "react";

export default function TableSkeleton({ header = [], rowsNum = 10 }) {
  const rows = Array(rowsNum).fill("");
  return (
    <table className="overflow-auto">
      <thead>
        <tr>
          {header.map((col, i) => (
            <th key={i} className=" p-2 whitespace-nowrap secondary-font">
              {col}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="animate-pulse">
        {rows.map((row, j) => (
          <tr key={j}>
            {header.map((col, i) => (
              <th key={i} className="bg-[#ffffff10] h-[50px]"></th>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
