import React, { useState } from "react";
import PlayerDetails from "./PlayerDetails";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";
import { formatToNumber, formatToPercentage } from "lib/utils";
import ColumnControls, {
  FilterByRange,
  FilterByText,
  ResetColumn,
  SortData,
} from "pages/Games/components/columnControls/ColumnControls";
import TableSkeleton from "components/skeletons/Table";

const PlayerSide = ({
  playersData,
  statsLoading,
  statsError,
  setFilterPlayerData,
  columns,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [playerData, setPlayerData] = useState({});
  const [rank, setRank] = useState(0);

  const filterPerformance = useSelector(
    (state) => state.filter_performance_valorant.performance
  );

  const handleShowDetailsButton = (data, i) => {
    setPlayerData(data);
    setShowDetails(true);
    setRank(i + 1);
  };

  if (statsLoading) {
    const header = columns.map((col) => col.label);
    // header.push("Role(s)", "Add");
    return <TableSkeleton header={header} />;
  }

  if (statsError) {
    return (
      <div className=" bg-[#2C2A36] flex justify-center items-center">
        Error: {statsError.message}
      </div>
    );
  }

  return (
    <div className=" flex flex-col gap-8">
      <h1 className=" text-2xl">Players</h1>
      {showDetails ? (
        <ErrorBoundary
          fallback={<div>Error:Player Details Failed to Load</div>}
        >
          <PlayerDetails
            playerData={playerData}
            callBack={setShowDetails}
            rank={rank}
          />
        </ErrorBoundary>
      ) : (
        <></>
      )}
      <div className="overflow-x-auto">
        <table className="text-left w-[2000px] min-h-[300px] overflow-auto ">
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index} className="align-top">
                  <ColumnControls
                    columnName={column.label}
                    columnType={column.type}
                    data={playersData}
                    setData={setFilterPlayerData}
                    OrderByName={column.value}
                    isAscending={false}
                  ></ColumnControls>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {playersData?.map((player, index) => (
              <tr
                key={player?.pro_player_id}
                onClick={() => handleShowDetailsButton(player, index)}
                className=" cursor-pointer"
              >
                <td>
                  <div className="flex gap-4 items-center">
                    <img
                      src={`${player?.team_logo_url}`}
                      alt={`${player?.team_name}`}
                      width={24}
                    />
                    <p className="hidden sm:table-cell">{player.team_name}</p>
                  </div>
                </td>
                <td>
                  <div className=" flex gap-2">
                    <img
                      src={`${player?.pro_player_image_url}`}
                      alt={`${player?.pro_player}`}
                      width={24}
                      className="hidden sm:table-cell"
                    />
                    <p>{player?.pro_player}</p>
                    <>
                      {index === 0
                        ? "🥇"
                        : index === 1
                        ? "🥈"
                        : index === 2
                        ? "🥉"
                        : ""}
                    </>
                  </div>
                </td>

                <td>
                  <p>
                    {player?.role?.map((r) => (
                      <span key={r}> {r}</span>
                    ))}
                  </p>
                </td>
                {/* <td className="text-[#f9d25f] secondary-font font-medium text-lg">
                {filterPerformance.orderBy === "price" ? "$" : ""}
                {formatToNumber(player[filterPerformance.orderBy], 1)}
              </td> */}

                <td className="text-[#f9d25f] secondary-font text-lg font-normal">
                  ${formatToNumber(player?.price)}
                </td>
                <td className="text-[#f9d25f] secondary-font font-medium text-lg">
                  {formatToNumber(player?.infamy_score)}
                </td>
                <td className="secondary-font text-lg font-normal">
                  {formatToNumber(player?.kills)}
                </td>
                <td className="secondary-font text-lg font-normal">
                  {formatToNumber(player?.deaths)}
                </td>
                <td className="secondary-font text-lg font-normal">
                  {formatToNumber(player?.assists)}
                </td>
                <td className="secondary-font text-lg font-normal">
                  {formatToNumber(player["1st_kill"])}
                </td>
                <td className="secondary-font text-lg font-normal">
                  {formatToNumber(player["1st_death"])}
                </td>
                <td className="secondary-font text-lg font-normal">
                  {formatToNumber(player?.clutch)}
                </td>
                <td className="secondary-font text-lg font-normal">
                  {formatToNumber(player?.point_value, 2)}
                </td>
                <td className="secondary-font text-lg font-normal">
                  {formatToNumber(player?.picks)}
                </td>
                <td className="secondary-font text-lg font-normal">
                  {formatToPercentage(player?.pick_rate)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlayerSide;
