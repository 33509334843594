import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import LeaderBoard from "./LeaderBoard/LeaderBoard";
import SideBar from "../../components/SideBar";
import QuickStats from "./StatsOverview/QuickStats";
import PlayerRoster from "./ProPlayerRoster/PlayerRoster";
import PlayerPerformance from "./ProPlayerPerformance/PlayerPerformance";
import Rules from "./Rules/Rules";
import Matches from "./Matches/Matches";
import { useValorantStore } from "stores/useValorantStore";

const TournamentDetails = () => {
  const { setSlug, slug } = useValorantStore((state) => state);

  useEffect(() => {
    setSlug();
  }, [setSlug]);

  return (
    <div className=" grid grid-cols-[auto_3fr] h-[100%] overflow-hidden text-white secondary-font">
      <SideBar slug={slug} game={"valorant"} />

      <Routes>
        <Route
          path={`/rules/${slug}`}
          element={<Rules tournamentSlug={slug} />}
        />
        <Route
          path={`/quick-stats/${slug}`}
          element={<QuickStats tournamentSlug={slug} />}
        />
        <Route
          path={`/leaderboard/${slug}`}
          element={<LeaderBoard tournamentSlug={slug} />}
        />
        <Route
          path={`/roster/${slug}`}
          element={<PlayerRoster tournamentSlug={slug} />}
        />
        <Route
          path={`/performance/${slug}`}
          element={<PlayerPerformance tournamentSlug={slug} />}
        />

        <Route
          path={`/matches/${slug}`}
          element={<Matches tournamentSlug={slug} />}
        />
      </Routes>
    </div>
  );
};

export default TournamentDetails;
