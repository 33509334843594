"use client";
import React from "react";
import { ScaleLoader } from "react-spinners";

const RulesList = ({ rulesLoading, rulesError, rulesListData }) => {

  if (rulesLoading) {
    return (
      <div className="bg-[#2C2A36] flex justify-center items-center">
        <ScaleLoader color="#F9D25F" />
      </div>
    );
  }

  if (rulesError) {
    return (
      <div className="h-screen bg-[#2C2A36] flex justify-center items-center">
        Error: {rulesError.message}
      </div>
    );
  }

  return (
    <>
      <ul className="list-decimal space-y-4 secondary-font text-sm sm:text-base overflow-auto">
        <li>
          Use the given{" "}
          <span className="text-[#f9d25f]">
            {" "}
            Salary {rulesListData?.salary}
          </span>{" "}
          to pick{" "}
          <span className="text-[#f9d25f]"> 5 Overwatch League Pros</span>, 1
          for each role on a team ({rulesListData?.tank} Tank,{" "}
          {rulesListData?.support} Support, {rulesListData?.damage} Damage).
        </li>
        <li>
          You cannot have more than{" "}
          <span className="text-[#f9d25f]">
            {rulesListData?.same_team_players}
          </span>{" "}
          players from the same team
        </li>
        <li>
          Infamy Participants are not penalized for players off-rolling, and
          will receive the total points that player earns. If they do not play
          they do not score points.
        </li>
        <li>
          Scoring takes place over a weekend of matches / a tournament. New
          Tournaments will open each week and close before the 1st game begins
        </li>
        <li>
          To enter the tournament please leave a comment in the specified format
          below in our ⁠fantasy-overwatch channel on our discord{" "}
          <a
            href="
        https://discord.gg/DaeG8yFubu"
            className="text-[#f9d25f]"
          >
            https://discord.gg/DaeG8yFubu{" "}
          </a>
          !
        </li>
        <li>
          Template <br />
          <br />
          <pre className="bg-[#00000040] overflow-auto p-2 rounded">
            Overwatch
            <br />
            Tournament Id---7
            <br />
            Tournament Id---Summer Qualifiers
            <br />
            ----------
            <br />
            Position--Pro_Player--Role--Price
            <br />
            ----------
            <br />
            Damage 1--Dove--offense--13
            <br />
            Damage 2--SparkR--offense--17
            <br />
            Tank--Someone--tank--35
            <br />
            Support 1--CH0R0NG--support--25
            <br />
            Support 2--Rakattack--support--9
            <br />
            ----------
            <br />
          </pre>
        </li>
        <li>
          If your team is eligible, then we will put a reaction on your team
          post to notify you that your team has been added. You can also look at
          the Infamy Tournaments Leaderboard Tab for your Name/Team.
        </li>
        <li>
          If you'd like to make a roster change after your initial team
          submission, please reply to your original submission with your new
          lineup/changes so it shows up on our notifications.
        </li>
        <li>
          The participant who's team of 5 players score the most Infamy Points
          wins!
        </li>

        <li>
          Scoring Breakdown
          <table>
            <thead>
              <tr>
                <th>Stats</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(rulesListData).map((stat, index) => {
                if (index > 4) {
                  return (
                    <tr>
                      <td>{stat[0]}</td>
                      <td>{stat[1]}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </li>
      </ul>
    </>
  );
};

export default RulesList;
