import {
  addToDisabledPlayers,
  getSalaryLeft,
  DataFilter,
} from "pages/Games/siege_r6/lib/utils";

export function disablePlayers(selectedPlayers,selectedTeams, players) {
  let salaryLeft = getSalaryLeft([...selectedPlayers,selectedTeams]);
  let filteredByPrice = new DataFilter(players)
  .filterByPrice(salaryLeft + 1)
  .getData();

  const disabledPlayers = addToDisabledPlayers([
    ...filteredByPrice,
    ...selectedPlayers,
  ]);

  return {
    disabledPlayers,
  }
}
