import {
  addToDisabledTeams,
  formatToNumber,
  orderData,
  isSelectedMap,
  addToDisabledMaps,
} from "pages/Games/siege_r6/lib/utils";
import { disableMaps } from "./components/Disables";
import { useSelector, useDispatch } from "react-redux";
import { setDisabledMaps, setSelectedMap } from "data/r6/CreateTeamSlice";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import { useEffect, useState } from "react";
import useGetQuery from "hooks/useGetQuery";
import TableSkeleton from "components/skeletons/Table";

const Maps = ({ slug }) => {
  const endPointFunction = "get_maps_details_function";
  const teamRosterDataUrl =
    "https://script.google.com/macros/s/AKfycbzE41w0yyeYLYbewDqEUQgb3Y-bPmTQCsGTdDI2AJbbca6TTOODW5Kdq5VFeQRQJRyIZQ/exec";
  const [data, isLoading, error] = useGetQuery(
    `${teamRosterDataUrl}?slug=${slug}&func=${endPointFunction}`
  );

  const [mapDetails, setMapDetails] = useState([]);

  useEffect(() => {
    if (data) {
      setMapDetails(orderData(data.data, "map_name"));
    }
  }, [data]);

  const { disabledMaps, selectedMap } = useSelector(
    (state) => state.create_team_r6
  );

  const dispatch = useDispatch();

  const removeMap = () => {
    dispatch(setSelectedMap({}));
    dispatch(setDisabledMaps({}));
  };

  function selectMap(selectedMap) {
    dispatch(setSelectedMap(selectedMap));

    const { disabledMaps } = disableMaps(selectedMap, mapDetails);

    dispatch(setDisabledMaps(disabledMaps));

    if (selectedMap.map_name) {
      const disabledMaps = addToDisabledMaps(mapDetails);
      dispatch(setDisabledMaps(disabledMaps));
    }
  }

  if (isLoading) {
    return (
      <TableSkeleton
        header={["Map", "Picked", "Decider", "Ban", "Price", "Add"]}
      />
    );
  }

  if (error) {
    return (
      <div className=" bg-[#2C2A36] flex justify-center items-center">
        Error: {error.message}
      </div>
    );
  }
  return (
    <div className="relative overflow-auto whitespace-nowrap ">
      <table className=" text-left w-full ">
        <thead>
          <tr className="primary-font">
            <th>Map</th>
            <th>Price</th>
            <th className=" sticky bg-[#ffffff0d] right-0  text-center ">
              Add
            </th>
          </tr>
        </thead>
        <tbody>
          {mapDetails?.map((mapDetail, index) => (
            <tr
              key={index}
              className={`${
                isSelectedMap(mapDetail, selectedMap)
                  ? ""
                  : disabledMaps[mapDetail.map_id]
                  ? " bg-gray-700 opacity-40"
                  : ""
              } relative`}
            >
              <td
                className={`${
                  isSelectedMap(mapDetail, selectedMap)
                    ? " bg-[#191b2a] opacity-20"
                    : ""
                }`}
              >
                <div className="flex gap-4 items-center">
                  <p className="">{mapDetail.map_name}</p>
                </div>
              </td>

              <td
                className={`${
                  isSelectedMap(mapDetail, selectedMap)
                    ? " bg-[#191b2a] opacity-20"
                    : ""
                }`}
              >
                <p className="text-[#f9d25f] secondary-font font-medium text-lg">
                  ${formatToNumber(mapDetail.price, 0)}
                </p>
              </td>

              <td className="flex justify-center sticky bg-[#ffffff0d] right-0 ">
                {isSelectedMap(mapDetail, selectedMap) ? (
                  <button
                    className="p-1 border-2 border-[#F25F4C] w-fit flex content-end rounded cursor-pointer  text-[#F25F4C] "
                    onClick={() => removeMap()}
                  >
                    <IoIosRemove size={24} />
                  </button>
                ) : (
                  <button
                    className="p-1 border w-fit flex content-end rounded cursor-pointer  "
                    onClick={() => selectMap(mapDetail)}
                    disabled={disabledMaps[mapDetail.map_id]}
                  >
                    <IoIosAdd size={24} />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Maps;
