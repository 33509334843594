import { useState } from "react";
import {
  formatToCurrency,
  isSelected,
  getPosition,
  addToDisabledPlayers,
  formatToNumber,
  orderData,
} from "lib/utils";
import { disablePlayers } from "./DisablePlayer";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedPlayer,
  setSelectedRole,
  setDisabledPlayer,
  removeSelectedPlayer,
} from "data/valorant/CreateTeamSlice";
import { IoIosAdd, IoIosRemove } from "react-icons/io";
import ColumnControls, {
  FilterByRange,
  FilterByText,
  ResetColumn,
  SortData,
} from "pages/Games/components/columnControls/ColumnControls";
import TableSkeleton from "components/skeletons/Table";

const TeamsRosterTableView = ({
  statsError,
  statsLoading,
  playerData,
  setFilterPlayerData,
}) => {
  const columns = [
    { label: "Team", value: "team_name", type: "string" },
    { label: "Name", value: "pro_player", type: "string" },
    { label: "Price $", value: "price", type: "number" },
    { label: "Role", value: "role", type: "" },
  ];

  const positions = ["player1", "player2", "player3", "player4", "captain"];
  const { selectedPlayers, disabledPlayers } = useSelector(
    (state) => state.create_team_valorant
  );

  const filterPerformance = useSelector(
    (state) => state.filter_performance_valorant.performance
  );
  const dispatch = useDispatch();

  const removePlayer = (player) => {
    let newSelectedPlayers = selectedPlayers.filter(
      (item) => item?.pro_player_id !== player?.pro_player_id
    );

    dispatch(removeSelectedPlayer(getPosition(player, selectedPlayers)));

    const { disabledPlayers, selectedRoles } = disablePlayers(
      newSelectedPlayers,
      playerData
    );

    dispatch(setSelectedRole(selectedRoles));
    dispatch(setDisabledPlayer(disabledPlayers));
  };

  function selectPlayer(player) {
    const toRemove = selectedPlayers.map((p) => p?.position);
    const filteredPositions = positions.filter(function (position) {
      return !toRemove.includes(position);
    });
    let newSelectedPlayers = [
      ...selectedPlayers,
      {
        ...player,
        position: filteredPositions[0],
        selectedRole: player.role[0],
      },
    ];

    dispatch(
      setSelectedPlayer({
        ...player,
        position: filteredPositions[0],
        selectedRole:
          player?.role[0].toLowerCase() === "flex"
            ? "Initiator"
            : player?.role[0],
        role:
          player?.role[0].toLowerCase() === "flex"
            ? ["Initiator", "Sentinel", "Controller", "Duelist"]
            : player?.role,
      })
    );

    const { disabledPlayers, selectedRoles } = disablePlayers(
      newSelectedPlayers,
      playerData
    );

    dispatch(setSelectedRole(selectedRoles));
    dispatch(setDisabledPlayer(disabledPlayers));

    const numberOfPlayers = 5;
    if (newSelectedPlayers.length >= numberOfPlayers) {
      const disabledPlayers = addToDisabledPlayers(playerData);
      dispatch(setDisabledPlayer(disabledPlayers));
    }
  }

  function handleOnDrag(e, player) {
    e.dataTransfer.setData("player", JSON.stringify(player));
  }

  if (statsLoading) {
    const header = columns.map((col) => col.label);
    return <TableSkeleton header={header} />;
  }

  if (statsError) {
    return (
      <div className=" bg-[#2C2A36] flex justify-center items-center">
        Error: {statsError.message}
      </div>
    );
  }
  return (
    <div className="relative overflow-auto whitespace-nowrap ">
      <table className=" text-left w-full ">
        <thead>
          <tr className=" secondary-font">
            {columns.map((column, index) => (
              <th key={index} className="align-top">
                <ColumnControls
                  columnName={column.label}
                  columnType={column.type}
                  data={playerData}
                  setData={setFilterPlayerData}
                  OrderByName={column.value}
                  isAscending={false}
                ></ColumnControls>
              </th>
            ))}
            {/* <th>Role(s)</th> */}
            <th className=" sticky bg-[#ffffff0d] right-0 text-center ">Add</th>
          </tr>
        </thead>
        <tbody>
          {playerData?.map((player, index) => (
            <tr
              key={player.pro_player_id}
              className={`${
                isSelected(player, selectedPlayers)
                  ? ""
                  : disabledPlayers[player.pro_player_id]
                  ? " bg-gray-700 opacity-40"
                  : ""
              } relative`}
              draggable
              onDragStart={(e) => handleOnDrag(e, player)}
            >
              <td
                className={`${
                  isSelected(player, selectedPlayers)
                    ? " bg-[#191b2a] opacity-20"
                    : ""
                }`}
              >
                <div className="flex gap-4 items-center">
                  <img
                    src={`${player?.team_logo_url}`}
                    alt={`${player?.team_name}`}
                    width={24}
                  />
                  <p className="hidden xl:table-cell">{player.team_name}</p>
                </div>
              </td>
              <td
                className={`${
                  isSelected(player, selectedPlayers)
                    ? " bg-[#191b2a] opacity-20"
                    : ""
                }`}
              >
                <div className="flex gap-4 items-center">
                  <img
                    src={`${player?.pro_player_image_url}`}
                    alt={`${player?.pro_player}`}
                    width={24}
                    className="hidden xl:table-cell"
                  />

                  <p className="" title={`${player?.pro_player}`}>
                    {player?.pro_player}
                  </p>
                </div>
              </td>
              <td
                className={`${
                  isSelected(player, selectedPlayers)
                    ? " bg-[#191b2a] opacity-20"
                    : ""
                }`}
              >
                <p className="text-[#f9d25f] secondary-font font-medium text-lg">
                  ${formatToNumber(player.price, 0)}
                </p>
              </td>
              <td
                className={`${
                  isSelected(player, selectedPlayers)
                    ? " bg-[#191b2a] opacity-20"
                    : ""
                }`}
              >
                <p className=" flex gap-2 ">
                  {player?.role?.map((r) => (
                    <span key={r}>{r}</span>
                  ))}
                </p>
              </td>

              <td className="flex justify-center sticky bg-[#ffffff0d] right-0 ">
                {isSelected(player, selectedPlayers) ? (
                  <button
                    className="p-1 border-2 border-[#F25F4C] w-fit flex content-end rounded cursor-pointer  text-[#F25F4C] "
                    onClick={() => removePlayer(player)}
                  >
                    <IoIosRemove size={24} />
                  </button>
                ) : (
                  <button
                    className="p-1 border w-fit flex content-end rounded cursor-pointer  "
                    onClick={() => selectPlayer(player)}
                    disabled={disabledPlayers[player.pro_player_id]}
                  >
                    <IoIosAdd size={24} />
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TeamsRosterTableView;
