import React, { useEffect, useState } from "react";
import TournamentDetailsHeader from "../../components/TournamentDetailsHeader";
import useFetch from "hooks/useFetch";
import MatchCards from "./components/MatchCards";
import useGetQuery from "hooks/useGetQuery";
import { ErrorBoundary } from "react-error-boundary";

const PlayerRoster = ({ tournamentSlug }) => {
  const MatchesDataUrl =
    "https://script.google.com/macros/s/AKfycbw09Dh-bLGx9MWi_xku9RoPdGu3KfsZ7Kve7sh-iD50nH7ch6Nd3eE6rUW8GMXvLe132A/exec";
  // const [data, isLoading, error] = useFetch(
  //   `${MatchesDataUrl}?id=${tournamentSlug}`
  // );

  const [data, isLoading, error] = useGetQuery(
    `${MatchesDataUrl}?id=${tournamentSlug}`
  );

  const [matchesData, setMatchesData] = useState([]);
  useEffect(() => {
    if (data) {
      setMatchesData(data.data);
    }
  }, [data]);

  return (
    <div className=" p-2 sm:p-5 h-full overflow-y-scroll flex flex-col gap-5 ">
      <div className="bg-[#191B2A] rounded-xl p-3 sm:p-8 flex flex-col gap-8 primary-font shadow-lg">
        <h1 className="text-xl sm:text-2xl md:text-3xl font-semibold py-2 text-center sm:text-left">
          Matches
        </h1>

        <ErrorBoundary
          fallback={<div>Error:Tournament Details Failed to Load</div>}
        >
          <TournamentDetailsHeader tournamentSlug={tournamentSlug} />
        </ErrorBoundary>
      </div>

      <div className="bg-[#191B2A] rounded-xl p-3 sm:p-8 primary-font shadow-lg">
        <ErrorBoundary fallback={<div>Error:Match Details Failed to Load</div>}>
          <MatchCards
            statsLoading={isLoading}
            statsError={error}
            MatchesData={matchesData}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default PlayerRoster;
