// https://script.google.com/macros/s/AKfycbxCKB9DnxKkX8iX0J4sZqrS4MnNQnt0GPlNSM2e-BvfSoA4XmVKVXRRN5IRjMPikJnXfQ/exec

import React, { useState, useEffect } from "react";
import TournamentDetailsHeader from "../../components/TournamentDetailsHeader";
import useGetQuery from "hooks/useGetQuery";
import RulesList from "./components/RulesList";
import { ErrorBoundary } from "react-error-boundary";
import CreateTeamButton from "../../../components/CreateTeamButton";

const Rules = ({ tournamentSlug }) => {
  const rulesUrl =
"https://script.google.com/macros/s/AKfycby-mN1YApj-QQCrzgG8KNLbR5FSlGeq0lGC7UTQQ5GIY_yL31L99ivoG5VUtuXYBJJ0/exec"
  const [data, isLoading, error] = useGetQuery(
    `${rulesUrl}?id=${tournamentSlug}`
  );

  const [ruleData, setRuleData] = useState({});

  useEffect(() => {
    if (data) {
      setRuleData(data.data);
    }
  }, [data]);

  return (
    <div className=" p-2 sm:p-5 h-full overflow-y-scroll flex flex-col gap-5 ">
      <CreateTeamButton game={'valorant'} slug={tournamentSlug}/>
      <div className="bg-[#191B2A] rounded-xl p-3 sm:p-8 flex flex-col gap-8 primary-font shadow-lg">
        <h1 className="text-xl sm:text-2xl md:text-3xl font-semibold py-2 text-center sm:text-left">
          Rules
        </h1>
        <ErrorBoundary
          fallback={<div>Error:Tournament Details Failed to Load</div>}
        >
          <TournamentDetailsHeader tournamentSlug={tournamentSlug} />
        </ErrorBoundary>
      </div>
      <div className="bg-[#191B2A] rounded-xl p-3 sm:p-10 primary-font flex flex-col gap-4 shadow-lg">
        <ErrorBoundary fallback={<div>Error:Rules Failed to Load</div>}>
          
          <RulesList
            rulesLoading={isLoading}
            rulesError={error}
            rulesListData={ruleData}
          />
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default Rules;
