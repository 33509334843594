import TableSkeleton from "components/skeletons/Table";
import useGetQuery from "hooks/useGetQuery";
import { useLeaderboardStore } from "stores/useLeaderboardStore";
import { DataFilter, formatToNumber } from "lib/utils";
import ColumnControls from "pages/Games/components/columnControls/ColumnControls";
import React, { useCallback, useEffect, useState } from "react";

export default function LeaderboardTable() {
  const columns = [
    { label: "Rank", value: "rank", type: "number" },
    { label: "Discord Members", value: "discord_name", type: "string" },
    {
      label: "1st Place Finishes",
      value: "first_place_finishes",
      type: "number",
    },
    {
      label: "Tournaments Entered",
      value: "tournaments_entered",
      type: "number",
    },
    { label: "Notoriety", value: "infamy_score", type: "number" },
    { label: "Percentile", value: "infamy_score", type: "" },
  ];

  const discordName = useLeaderboardStore((state) => state.discord_name);
  const { update_select_options, leaderboard_list_url } = useLeaderboardStore(
    (state) => state
  );

  const [filteredData, setFilteredData] = useState([]);

  const params = new URLSearchParams(window.location.search);
  params.append("func", "get_leaderboard");
  params.delete("discord_name");

  const [data, isLoading, error] = useGetQuery(
    `${leaderboard_list_url}?${params.toString()}`
  );

  const filterData = useCallback((data, discordName) => {
    if (data) {
      let newFilteredData = new DataFilter(data)
        .filterByText("discord_name", discordName)
        .getData();

      let rankedData = rankData(newFilteredData);
      setFilteredData(rankedData);
    }
  }, []);

  useEffect(() => {
    if (data) {
      const leaderboard = data?.data?.leaderboard_data;
      filterData(leaderboard, discordName);

      let discord_names = [
        ...new Set(leaderboard?.map((a) => a["discord_name"])),
      ].map((b) => ({ value: b, label: b }));

      update_select_options(discord_names, "discord_name_options");
    }
  }, [data, discordName, update_select_options, filterData]);

  function rankData(data = []) {
    let newData = [];
    let currentRank = 0;

    for (let i = 0; i < data.length; i++) {
      if (data[i - 1]?.infamy_score !== data[i]?.infamy_score) {
        currentRank++;
      }

      newData[i] = { ...data[i], rank: currentRank };
    }
    return newData;
  }

  if (isLoading) {
    const header = columns.map((col) => col.label);
    return <TableSkeleton header={header} rowsNum={10} />;
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <table className="text-left ">
      <thead>
        <tr className=" secondary-font">
          {columns.map((column, index) => (
            <th key={index} className="align-top">
              <ColumnControls
                columnName={column.label}
                columnType={column.type}
                data={filteredData}
                setData={setFilteredData}
                OrderByName={column.value}
                isAscending={false}
              ></ColumnControls>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {filteredData?.map((infamyMember, id) => (
          <tr key={id}>
            <td className="">{infamyMember.rank}</td>
            <td className="">{infamyMember.discord_name}</td>
            <td className="">{infamyMember.first_place_finishes}</td>
            <td className="">
              {infamyMember.tournaments_entered} /{" "}
              {infamyMember.tournaments_hosted}
            </td>
            <td className="">{formatToNumber(infamyMember.infamy_score, 2)}</td>
            <td className="">
              {percentRank(filteredData, infamyMember.infamy_score)}%
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function percentRank(array, value) {
  const sortedArr = [...array].sort((a, b) => b.infamy_score - a.infamy_score);
  const arr = [...sortedArr].map((a) => a.infamy_score);

  const index = arr.indexOf(value);

  if (index === -1) {
    return null;
  }

  const rank = ((index + 1) / arr.length) * 100;

  return formatToNumber(rank, 2);
}
