import React from "react";
import TeamSide from "./TeamSide";
import PlayerSide from "./PlayerSide";
import { ErrorBoundary } from "react-error-boundary";

const TeamsRoster = ({ teamPlayers, statsError, statsLoading, playerData }) => {
  const teams = teamPlayers.allTeams;

  if (statsLoading) {
    return (
      <div className=" h-[50px] bg-gradient-to-r from-[#f9d25f80] to-[#191b2a00] p-2 border-l-2 border-[#F9D25F] border-solid font-semibold w-[300px] animate-pulse flex items-center">
        <p>Loading...</p>
      </div>
    );
  }

  if (statsError) {
    return (
      <div className=" bg-[#2C2A36] flex justify-center items-center">
        Error: {statsError.message}
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-10">
      {teams?.map((team) => (
        <div
          className="grid grid-rows-[auto_1fr] sm:grid-cols-[minmax(300px,_1fr)_3fr] w-full"
          key={team.team_id}
        >
          <ErrorBoundary
            fallback={<div>Error:Team Logo Failed to Load</div>}
          >
            <TeamSide teamData={team} />
          </ErrorBoundary>
          <ErrorBoundary
            fallback={<div>Error:Player Cards Failed to Load</div>}
          >
            <PlayerSide playersData={team[team.team_id]} />
          </ErrorBoundary>
        </div>
      ))}
    </div>
  );
};

export default TeamsRoster;
