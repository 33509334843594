import React, { useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";
import useGetQuery from "hooks/useGetQuery";
import TournamentTableList from "pages/Games/components/TournamentTableList";

const TournamentList = () => {
  const [ongoing, setOngoing] = useState([]);
  const [upcoming, setUpcoming] = useState([]);
  const [finished, setFinished] = useState([]);
  const tournamentListUrl =
    "https://script.google.com/macros/s/AKfycbyF9Ol_VS8KP9oSrIxpxk4jRQ0i-30x0wxkPbGC8BHgDGdRSscBoEZS2cbarkHtNv0DhA/exec";

  const [data, isLoading, error] = useGetQuery(tournamentListUrl);

  function subList(status) {
    return data.data
      .filter(function (tournament) {
        return tournament.tournament_status === status;
      })
      .sort((a, b) => b["tournament_id"] - a["tournament_id"]);
  }

  useEffect(() => {
    if (data) {
      console.log(data);

      setOngoing(subList("Ongoing"));
      setUpcoming(subList("Upcoming"));
      setFinished(subList("Finished"));
    }
  }, [data]);

  /**
   * PAGEs
   */

  // LOADING PAGE
  if (isLoading) {
    return (
      <div className="h-screen bg-[#191B2A] flex justify-center items-center">
        <ScaleLoader color="#F9D25F" />
      </div>
    );
  }

  // ERROR PAGE
  if (error) {
    return (
      <div className="h-screen bg-[#191B2A] flex justify-center items-center">
        Error: {error.message}
      </div>
    );
  }

  return (
    <>
      <div className="p-[5%] bg-[#191B2A] text-white primary-font flex flex-col gap-20">
        <div className=" flex flex-col gap-2">
          <h1 className="text-3xl md:text-4xl lg:text-5xl text-white primary-font">
            R6 SIEGE
          </h1>
          <p className="text-sm md:text-lg secondary-font font-thin text-purple-300">
            A high-precision, tactical shooter that prioritizes careful <br />{" "}
            planning teamwork and finely tuned tactical play.
          </p>
        </div>
        <div className="flex flex-col gap-8">
          <div className="flex flex-col gap-4">
            {/* Ongoing */}
            <h2 className="border-b-[1px] border-b-solid border-[#f9d25fac] text-lg md:text-xl">
              Ongoing
            </h2>

            <TournamentTableList tournaments={ongoing} tab={"leaderboard"} />
          </div>

          {/* Upcoming */}
          <div className="flex flex-col gap-4">
            <h2 className="border-b-[1px] border-b-solid border-[#f9d25fac] text-lg md:text-xl">
              Upcoming
            </h2>
            <TournamentTableList tournaments={upcoming} tab={"rules"} />
          </div>

          {/* Finished */}
          <div className="flex flex-col gap-4">
            <h2 className="border-b-[1px] border-b-solid border-[#f9d25fac] text-lg md:text-xl">
              Finished
            </h2>
            <TournamentTableList tournaments={finished} tab={"leaderboard"} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TournamentList;
