import React, { useState, useEffect } from "react";
import TournamentDetailsHeader from "../../components/TournamentDetailsHeader";
import { DataFilter, orderData } from "../../../../../lib/utils";
import FilterInput from "./components/FilterInput";
import { useSelector } from "react-redux";
import PlayerCardView from "./components/PlayerCardView";
import PlayersTableView from "./components/PlayersTableView";
import useGetQuery from "hooks/useGetQuery";
import { ErrorBoundary } from "react-error-boundary";

const PlayerRoster = ({ tournamentSlug }) => {
  const PerformanceDataUrl =
    "https://script.google.com/macros/s/AKfycbzfzvnAjUT3vH9dY_U5-JUPImv4dJO3ZnSYEN9bJEEzFckBf4-LKnkQkY7I4qDgcoZD/exec";

  const [data, isLoading, error] = useGetQuery(
    `${PerformanceDataUrl}?id=${tournamentSlug}`
  );
  const [tableView, setTableView] = useState(true);
  const filterPerformance = useSelector(
    (state) => state.filter_performance_overwatch
  );
  const [filterPlayerData, setFilterPlayerData] = useState([]);
  useEffect(() => {
    if (data) {
      const filteredData = new DataFilter(data.data)
        .filterByPlayerName(filterPerformance.playerName)
        .filterByTeam(filterPerformance.teamName)
        .filterByRoles(filterPerformance.role)
        .getData();

      const orderedFilteredData = orderData(
        filteredData,
        filterPerformance.orderBy,
        false
      );
      setFilterPlayerData(orderedFilteredData);
    }
  }, [data, filterPerformance]);

  return (
    <div className=" p-2 sm:p-5 h-full overflow-y-scroll flex flex-col gap-5 ">
      <div className="bg-[#191B2A] rounded-xl p-3 sm:p-8 flex flex-col gap-8 primary-font shadow-lg">
        <h1 className="text-xl sm:text-2xl md:text-3xl font-semibold py-2 text-center sm:text-left">
          Pro Player Performance
        </h1>

        <ErrorBoundary
          fallback={<div>Error:Tournament Details Failed to Load</div>}
        >
          <TournamentDetailsHeader tournamentSlug={tournamentSlug} />
        </ErrorBoundary>

        <FilterInput />
        <p>
          Players Found :{" "}
          <span className="text-[#f9d25f] font-bold">
            {filterPlayerData?.length}
          </span>
        </p>
      </div>

      <div className="bg-[#191B2A] rounded-xl p-3 sm:p-8 primary-font shadow-lg space-y-8 flex flex-col">
        <div>
          <button
            className={`${
              tableView
                ? `bg-[#f9d25f] text-[#191B2A] px-4 py-2 rounded-lg`
                : `font-bold hover:text-[#f9d25f]  px-4 py-2`
            } `}
            onClick={(e) => setTableView(!tableView)}
          >
            Table View
          </button>
          <button
            className={`${
              tableView
                ? `font-bold hover:text-[#f9d25f]  px-4 py-2`
                : `bg-[#f9d25f] text-[#191B2A] px-4 py-2 rounded-lg`
            }`}
            onClick={(e) => setTableView(!tableView)}
          >
            Card View
          </button>
        </div>
        {tableView ? (
          <ErrorBoundary
            fallback={<div>Error:Players Table Failed to Load</div>}
          >
            <PlayersTableView
              statsLoading={isLoading}
              statsError={error}
              playersData={filterPlayerData}
            />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary
            fallback={<div>Error:Player Cards Failed to Load</div>}
          >
            <PlayerCardView
              statsLoading={isLoading}
              statsError={error}
              playersData={filterPlayerData}
            />
          </ErrorBoundary>
        )}
      </div>
    </div>
  );
};

export default PlayerRoster;
