import { formatToNumber } from "lib/utils";
import React from "react";

const AccoladesCards = ({ cardDetails, unit, unitValuesKey,dSpace }) => {

  if (!cardDetails) {
    return "Infamy";
  }

  const rankStyle = [
    {
      rank: 1,
      bg_style: "from-[#f9d25f]",
    },
    {
      rank: 2,
      bg_style: "from-[#D9D9D9]",
    },
    {
      rank: 3,
      bg_style: "from-[#874300]",
    },
  ];


  return (


    <div className="p-5  flex gap-5 items-center  w-full overflow-x-auto ">
      {cardDetails?.map((card, index) => (
        
        <div
          key={index}
          className=" bg-[#191b2a]  rounded-xl border-2 border-solid border-[#AF9DDD80] group cursor-pointer relative z-0 "
        >
          <div className=" absolute top-0 bg-[#000000c3] rounded-xl hidden h-full w-full flex-col justify-center items-center gap-8 group-hover:flex z-20">
            <div className="flex flex-col items-center gap-2">
              <img
                src={`${card?.team_logo_url}`}
                alt="team"
                title={`${card?.team_name}`}
                width={100}
                height={100}
                className=" bg-gray-300 p-2 rounded-lg"
              />
              <h1 className=" text-xl font-semibold ">{card?.team_name}</h1>
            </div>

            <div className="flex flex-col items-center gap-2">
              <h1 className="border-b-2 border-solid border-[#f9d25f] px-2">
                Role[s]
              </h1>
              <p>{card?.role}</p>
            </div>
          </div>
          
          <div className={`bg-gradient-to-t rounded-t-xl  ${rankStyle[index].bg_style} to-[#000000] w-[200px] flex items-end pt-6`}>
            <img
              src={`${card?.pro_player_image_url}`}
              alt={`${card?.pro_player}`}
              width={200}
            />
          </div>
          <div className=" h-1 w-full bg-gradient-to-r  from-[#674EA7] to-[#FA2328] "></div>


          <div className="flex flex-col p-4 gap-2   bg-gradient-to-r  from-[#0000000a] to-[#674ea747] relative overflow-hidden z-10">
            <h1 className={`absolute bottom-0 -right-[50%] text-6xl opacity-5`}>
              INFAMY
            </h1>
            <p className="font-medium text-xl text-center" title={`${card?.pro_player}`}>
               
               {card?.pro_player.length < 9
                 ? card?.pro_player
                 : card?.pro_player.slice(0,8)+"..."
                 }
             </p>
            <div className="flex  flex-col items-center border-solid p-1">
              <p className="text-2xl">{rankStyle[index].rank}</p>
              <p>Rank</p>
            </div>
            <div className="flex flex-col gap-2 items-center ">


              <p className="text-[#f9d25f] secondary-font font-medium text-base">
                {formatToNumber(card[unitValuesKey],dSpace)} {unit}
                
              </p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AccoladesCards;
