import { orderData } from "lib/utils";
import React, { useState } from "react";
import { MdFilterList } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  BsSortAlphaDown,
  BsSortAlphaUp,
  BsSortNumericUp,
  BsSortNumericDown,
} from "react-icons/bs";

// r6
import {
  resetColumn as r6_resetColumn,
  setRange as r6_setRange,
  setText as r6_setText,
} from "data/r6/FilterPerformanceSlice";
// valorant
import {
  resetColumn as valorant_resetColumn,
  setRange as valorant_setRange,
  setText as valorant_setText,
} from "data/valorant/FilterPerformanceSlice";

// // overwatch
// import {
//   resetColumn as overwatch_resetColumn,
//   setRange as overwatch_setRange,
//   setText as overwatch_setText,
// } from "data/overwatch/FilterPerformanceSlice";
// games["overwatch"] = { overwatch_resetColumn, overwatch_setRange, overwatch_setText };

// Game States
const games = {
  r6: {
    resetColumn: r6_resetColumn,
    setRange: r6_setRange,
    setText: r6_setText,
  },
  valorant: {
    resetColumn: valorant_resetColumn,
    setRange: valorant_setRange,
    setText: valorant_setText,
  },
  // overwatch: { overwatch_resetColumn, overwatch_setRange, overwatch_setText },
};

function ColumnControls({
  columnName = "",
  columnType,
  children,
  OrderByName,
  data = [],
  setData,
  isAscending,
  className = "",
}) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={`flex flex-col relative z-10 ${className}`}>
      <div className="text-center flex justify-between items-center gap-4">
        <span className="secondary-font  whitespace-nowrap">{columnName}</span>

        <SortData
          data={data}
          setData={setData}
          OrderByName={OrderByName}
          isAscending={isAscending}
          columnType={columnType}
        />
      </div>
      {isOpen ? (
        <section className="text-xs grid w-[200px] absolute top-10 left-0  bg-[#191B2A] border-[#674ea7] border-2 shadow">
          {children}
        </section>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ColumnControls;

export function SortData({ OrderByName, data = [], setData, columnType }) {
  const [isAscending, setIsAscending] = useState(false);
  function sortData() {
    const orderedData = orderData(data, OrderByName, !isAscending);
    setData(orderedData);
    setIsAscending(!isAscending);
  }

  if (columnType === "string") {
    return (
      <button onClick={sortData} className="p-2 hover:scale-110 duration-300 ">
        {isAscending ? <BsSortAlphaDown /> : <BsSortAlphaUp />}
      </button>
    );
  }

  if (columnType === "number") {
    return (
      <button onClick={sortData} className="p-2 hover:scale-110 duration-300 ">
        {isAscending ? <BsSortNumericDown /> : <BsSortNumericUp />}
      </button>
    );
  }

  return <></>;
}

export function FilterByText({ value, filterColumn, tab, game }) {
  const dispatch = useDispatch();
  const handleTextChange = (e) => {
    const setText = games[game]["setText"];
    dispatch(setText({ key: filterColumn, value: e.target.value, tab }));
  };

  return (
    <div className="p-2 grid gap-2 border-[#674ea7] border-b">
      <label htmlFor="">Filter By Text</label>
      <input
        type="text"
        value={value}
        onChange={handleTextChange}
        placeholder="Search..."
        className="p-2 bg-[#00000040] w-full border-b"
      />
    </div>
  );
}
export function FilterByRange({ minValue, maxValue, filterColumn, tab, game }) {
  const setRange = games[game]["setRange"];
  const dispatch = useDispatch();
  const handleRange = (minRange, maxRange) => {
    dispatch(setRange({ key: filterColumn, value: [minRange, maxRange], tab }));
  };

  return (
    <div className="flex flex-col gap-2 items-start p-2 border-[#674ea7] border-b">
      <h1 className="">Filter By Range</h1>
      <div className="flex gap-2 flex-wrap ">
        <input
          className=" bg-transparent outline-none  border-b-2 border-solid p-2 w-[50px]"
          type="number"
          value={minValue}
          onChange={(e) => {
            handleRange(e.target.value, maxValue);
          }}
          placeholder="Min"
        />
        <input
          className=" bg-transparent outline-none border-b-2 border-solid p-2 w-[50px]"
          type="number"
          value={maxValue}
          onChange={(e) => {
            handleRange(minValue, e.target.value);
          }}
          placeholder="Max"
        />
      </div>
    </div>
  );
}

export function ResetColumn({ resetColumnKey, tab, game }) {
  const dispatch = useDispatch();
  const handleResetClick = () => {
    const resetColumn = games[game]["resetColumn"];
    dispatch(resetColumn({ key: resetColumnKey, tab }));
  };

  return (
    <button
      onClick={handleResetClick}
      className="p-2 border-[#674ea7] border-b duration-300 hover:text-[#674ea7] hover:underline"
    >
      Reset
    </button>
  );
}
// export function FilterTextData({viewOptions, onChange}) {
//   return (
//    <div className="p-2">
//      <Select
//       options={viewOptions}
//       placeholder={"Search..."}
//       isClearable={true}
//       onChange={onChange}
//       theme={(theme) => ({
//         ...theme,
//         colors: {
//           ...theme.colors,
//           primary25: "#674EA780", // option hover background color
//           primary: "#674EA7", //control border color and selected option background color
//           neutral80: "white", //control text color
//           neutral0: "#191B2A", // control background color
//         },
//       })}
//     />
//    </div>
//   );
// }
