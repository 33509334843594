import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";
import { ErrorBoundary } from "react-error-boundary";
import PlayerDetails from "./PlayerDetails";
import useGetQuery from "hooks/useGetQuery";
import { DataFilter, orderData } from "lib/utils";
import { formatToNumber, formatToPercentage } from "lib/utils";
import { AiFillCaretDown } from "react-icons/ai";
import ColumnControls, {
  FilterByRange,
  FilterByText,
  ResetColumn,
  SortData,
} from "pages/Games/components/columnControls/ColumnControls";
import { useSelector } from "react-redux";
import TableSkeleton from "components/skeletons/Table";

const LeaderBoardTable = ({ tournamentSlug }) => {
  const columns = [
    { label: "Rank", value: "rank", type: "number" },
    { label: "Discord Usernames", value: "discord_name", type: "string" },
    { label: "Picks Left", value: "players_left", type: "number" },
    { label: "Pro 1", value: "pro_1_points", type: "number" },
    { label: "Pro 2", value: "pro_2_points", type: "number" },
    { label: "Pro 3", value: "pro_3_points", type: "number" },
    { label: "Entry", value: "entry_points", type: "number" },
    { label: "Support", value: "support_points", type: "number" },
    { label: "Team", value: "team_points", type: "number" },
    { label: "Map", value: "map_points", type: "number" },
    { label: "Infamy Score", value: "infamy_score", type: "number" },
    { label: "Percentile", value: "percentile", type: "number" },
  ];

  const leaderBoardListUrl =
    "https://script.google.com/macros/s/AKfycbxakKu8jvan7C28uwLjkaGpCSrOna8Vbutn2xSc4gp3jUAOh3okvrDUtX3b8QoewbFG/exec";

  const [data, isLoading, error] = useGetQuery(
    `${leaderBoardListUrl}?id=${tournamentSlug}`
  );

  const filterLeaderboard = useSelector(
    (state) => state.filter_performance_r6.leaderboard
  );

  const [leaderBoard, setLeaderBoard] = useState([]);
  const [playerDetails, setPlayerDetails] = useState({});
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (data) {
      leaderBoardList();
    }
  }, [data]);

  const showMoreDetails = (player) => {
    let newShowDetails = { ...player };
    setPlayerDetails(newShowDetails);
    setShowDetails(true);
  };

  const leaderBoardList = () => {
    let leaderBoardData = data.data;
    const orderedData = orderData(leaderBoardData, "infamy_score", false);
    setLeaderBoard([...orderedData]);
  };

  useEffect(() => {
    if (data) {
      const filteredData = filterTableData(data.data);
      const orderedData = orderData(filteredData, "infamy_score", false);
      setLeaderBoard(orderedData);
    }
  }, [filterLeaderboard]);

  function filterTableData(data) {
    let newData = new DataFilter(data);

    for (let i = 0; i < columns.length; i++) {
      let column = columns[i];
      if (column.type === "string") {
        newData = newData.filterByText(
          column.value,
          filterLeaderboard[column.value]
        );
      } else if (column.type === "number") {
        newData = newData.filterByRange(
          column.value,
          ...filterLeaderboard[column.value]
        );
      }
    }

    return newData.getData();
  }

  function isPaidMembers(e) {
    if (e.target.value === "Paid Members") {
      let leaderBoardData = data.data;
      let filteredData = leaderBoardData.filter((d) => d?.emojis !== "");
      const orderedData = orderData(filteredData, "infamy_score", false);
      setLeaderBoard([...orderedData]);
    } else {
      let leaderBoardData = data.data;
      const orderedData = orderData(leaderBoardData, "infamy_score", false);
      setLeaderBoard([...orderedData]);
    }
  }
  if (isLoading) {
    return (
      <div className="overflow-x-auto">
        <TableSkeleton header={columns.map((col) => col.label)} />
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-screen bg-[#191B2A] p-4 rounded-lg flex justify-center items-center">
        Error: {error.message}
      </div>
    );
  }
  return (
    <>
      {/* Details */}
      {showDetails ? (
        <ErrorBoundary
          fallback={<div>Error:Player Details Failed to Load</div>}
        >
          <PlayerDetails
            playerDetails={playerDetails}
            callBack={setShowDetails}
          />
        </ErrorBoundary>
      ) : (
        <></>
      )}
      <div className="overflow-x-auto">
        <table className="text-left w-full secondary-font font-normal min-h-[400px] ">
          <thead>
            <tr className="">
              {columns.map((column, index) =>
                column.value === "discord_name" ? (
                  <th className=" font-normal flex gap-1">
                    <select
                      name="members"
                      id=""
                      className="bg-[#191B2A] outline-transparent"
                      onChange={(e) => isPaidMembers(e)}
                    >
                      <option value="Discord Members" className="">
                        Discord Usernames
                      </option>
                      <option value="Paid Members">Members Only</option>
                    </select>

                    <span>({leaderBoard.length})</span>
                  </th>
                ) : (
                  <th key={index} className="align-top">
                    {column.label}
                  </th>
                )
              )}

              {/* <th className=" font-normal flex gap-1">
              
              <select name="members" id="" className="bg-[#191B2A] outline-transparent" onChange={(e) => isPaidMembers(e)}>
                <option value="Discord Members" className="">Discord Usernames</option>
                <option value="Paid Members">Members Only </option>
              </select>

              <span>({leaderBoard.length})</span>
            </th> */}
            </tr>
          </thead>

          {/* MAIN TABLE */}
          <tbody>
            {leaderBoard?.map((player, index) => (
              <tr
                key={index}
                onClick={() => showMoreDetails(player)}
                className={` cursor-pointer ${
                  player.rank === 1
                    ? `bg-[#d4af37] text-[#000000] `
                    : player.rank === 2
                    ? `bg-[#808080] text-[#000000] `
                    : player.rank === 3
                    ? `bg-[#CD7F32] text-[#000000] `
                    : player.rank ===
                      orderData([...leaderBoard], "rank", false)[0].rank
                    ? `bg-[#964b0080]`
                    : ``
                }`}
              >
                <td className="text-center">{player?.rank}</td>
                <td className="">
                  {player?.discord_name}
                  {player?.emojis}
                </td>
                <td className="text-center">
                  {formatToNumber(player.players_left, 0)}
                </td>
                <td className="">
                  <b>{player?.pro_1}</b>
                  <p>{formatToNumber(player.pro_1_points, 0)}</p>
                </td>
                <td className="">
                  <b>{player?.pro_2}</b>
                  <p>{formatToNumber(player.pro_2_points, 0)}</p>
                </td>
                <td className="">
                  <b>{player?.pro_3}</b>
                  <p>{formatToNumber(player.pro_3_points, 0)}</p>
                </td>
                <td className="">
                  <b>{player?.entry}</b>
                  <p>
                    {" "}
                    {formatToNumber(
                      +player?.entry_points + +player?.entry_bonus_points,
                      0
                    )}
                  </p>
                </td>
                <td className="">
                  <b>{player?.support}</b>
                  <p>
                    {" "}
                    {formatToNumber(
                      +player?.support_points + +player?.support_bonus_points,
                      0
                    )}
                  </p>
                </td>
                <td className="l">
                  <b>{player?.team}</b>
                  <p>{formatToNumber(player.team_points, 0)}</p>
                </td>

                <td className="l">
                  <b>{player?.map}</b>
                  <p>{formatToNumber(player.map_points, 0)}</p>
                </td>
                <td className="bg-[#ffffff30] text-center">
                  {formatToNumber(+player?.infamy_score, 2)}
                </td>
                <td className="text-center">
                  {formatToPercentage(+player?.percentile, 2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LeaderBoardTable;
