import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { ClipboardCopy } from "./ClipboardCopy";
import { disablePlayers } from "./DisablePlayer";
import {
  replaceSelectedPlayer,
  setSelectedRole,
  removeSelectedPlayer,
  setDisabledPlayer,
} from "data/overwatch/CreateTeamSlice";
import { formatToNumber, getPosition } from "lib/utils";
import useGetQuery from "hooks/useGetQuery";
import { overwatchApiUrl } from "lib/constants";

const TeamBuilder = ({ slug, allPlayerData }) => {
  const tournamentDetailsUrl = `${overwatchApiUrl}?slug=${slug}&func=tournament_details`;
  // "https://script.google.com/macros/s/AKfycbzeqJgPKusWi_r6jxWoUghRV3_Kk7PxnB18zHQLUSOtWcDIRPYW2QbsJTMuoovh4eiw/exec";

  const [data, isLoading, error] = useGetQuery(tournamentDetailsUrl);

  const [tournamentDetails, setTournamentDetails] = useState({});
  useEffect(() => {
    if (data) {
      tournamentDetailsData();
      console.log(data.data);
    }
  }, [data]);

  const tournamentDetailsData = () => {
    let tournamentData = data.data[0];
    setTournamentDetails({ ...tournamentData });
  };

  slug = slug.split("-")[0];

  const dispatch = useDispatch();

  const { selectedRole, selectedPlayers, numberOfDisabledPlayers } =
    useSelector((state) => state.create_team_overwatch);

  const [generatedText, setGeneratedText] = useState(
    "Copy the text generated hear."
  );
  const [showError, setShowError] = useState({ hasError: false, messages: [] });

  const [isOpen, setIsOpen] = useState(false);

  const removePlayer = (player) => {
    let newSelectedPlayers = selectedPlayers.filter(
      (item) => item?.pro_player_id !== player?.pro_player_id
    );

    dispatch(removeSelectedPlayer(getPosition(player, selectedPlayers)));

    const { disabledPlayers, selectedRoles } = disablePlayers(
      newSelectedPlayers,
      allPlayerData
    );

    dispatch(setSelectedRole(selectedRoles));
    dispatch(setDisabledPlayer(disabledPlayers));
  };

  function changeRole(e, player) {
    let newSelectedPlayers = selectedPlayers.filter(
      (item) => item?.pro_player_id !== player?.pro_player_id
    );
    newSelectedPlayers.push({ ...player, selectedRole: e.target.value });

    dispatch(
      replaceSelectedPlayer([
        getPosition(player, selectedPlayers),
        { ...player, selectedRole: e.target.value },
      ])
    );

    const { disabledPlayers, selectedRoles } = disablePlayers(
      newSelectedPlayers,
      allPlayerData
    );

    dispatch(setSelectedRole(selectedRoles));
    dispatch(setDisabledPlayer(disabledPlayers));
  }

  const generateTeam = () => {
    let players = [
      findRole(selectedPlayers, "offense")[0],
      findRole(selectedPlayers, "offense")[1],
      findRole(selectedPlayers, "tank")[0],
      findRole(selectedPlayers, "support")[0],
      findRole(selectedPlayers, "support")[1],
    ];

    function findRole(players = [], role = "") {
      return players.filter((r) => r.selectedRole === role);
    }
    let illegalRole = Object.entries(selectedRole).filter(
      (item) => item[1] < 0
    );
    if (illegalRole.length >= 1) {
      let messages = [];
      for (let i = 0; i < illegalRole.length; i++) {
        messages.push(`${illegalRole[i][0]}`);
      }

      setShowError({ hasError: true, messages });
      return;
    }
    setShowError({ hasError: false, messages: [] });
    let text = `Overwatch
    Tournament Id---${slug}
    Tournament Name---${tournamentDetails.tournament_name}
    ----------
    Position--Pro_Player--Role--Price
    ----------
    Damage 1--${players[0].pro_player}--${players[0].selectedRole}--${players[0].price}
    Damage 2--${players[1].pro_player}--${players[1].selectedRole}--${players[1].price}
    Tank--${players[2].pro_player}--${players[2].selectedRole}--${players[2].price}
    Support 1--${players[3].pro_player}--${players[3].selectedRole}--${players[3].price}
    Support 2--${players[4].pro_player}--${players[4].selectedRole}--${players[4].price}
    ----------`;

    setGeneratedText(text);
  };
  return (
    <>
      <div className="bg-[#000000F3] xl:bg-[#191B2A] rounded xl:rounded-xl px-3 pb-3 sm:p-4 primary-font shadow-lg space-y-2   xl:w-auto xl:h-[80vh] bottom-0 sticky xl:top-0 mx-2 mt-4 xl:m-0 overflow-x-auto overflow-y-visible ">
        <button
          className=" xl:text-xl flex justify-between items-center border-b-2 border-[#674ea7E6] py-2 w-full"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div>
            <span>Team Builder </span> -{" "}
            <span className=" text-[#f9d25f] ">{selectedPlayers.length}</span>
          </div>
          <span
            onClick={() => setIsOpen(!isOpen)}
            className=" text-[#f9d25f] rounded-full p-2 bg-[#674ea780] "
          >
            {isOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}
          </span>
        </button>
        <div
          className={`xl:flex flex-col duration-300 items-start xl:h-auto overflow-auto space-y-4 ${
            isOpen ? " h-[60vh] " : " h-[0vh] "
          }  text-sm xl:text-base`}
        >
          <div className="flex justify-between w-full xl:flex-wrap flex-wrap-reverse gap-2 border-b-2 border-[#674ea780] p-2">
            <p>
              No. Valid players :{" "}
              <span className="text-[#f9d25f]">
                {allPlayerData.length - numberOfDisabledPlayers}
              </span>
            </p>
            <p>
              Budget Left :{" "}
              <span className="text-[#f9d25f]">
                $
                {100 -
                  selectedPlayers.reduce((total, current) => {
                    return total + +formatToNumber(current.price, 0);
                  }, 0)}
              </span>
            </p>
          </div>
          <div className="border-b-2 border-[#674ea780] p-2 w-full">
            <h1 className="">Roles Remaining</h1>
            <div className="bg-transparent flex flex-wrap gap-2 sm:gap-3 secondary-font ">
              <div>
                Tank : <span>{selectedRole.tank}</span>
              </div>
              <div>
                Support : <span>{selectedRole.support}</span>
              </div>
              <div>
                Offense : <span>{selectedRole.offense}</span>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-3 w-full overflow-x-auto xl:overflow-visible">
            <table className="w-full">
              <thead>
                <tr>
                  <th>Player</th>
                  <th>Role</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {selectedPlayers.map((player, index) => (
                  <tr className="text-center" key={index}>
                    <td>
                      <div className="flex gap-4 items-center">
                        <img
                          src={`${player?.team_logo_url}`}
                          alt={`${player?.team_name}`}
                          width={24}
                          className="hidden md:table-cell"
                        />
                        <p>{player?.pro_player}</p>
                      </div>
                    </td>
                    <td>
                      {player?.role?.length > 1 ? (
                        <select
                          name="Role"
                          id="Role"
                          className="bg-[#191B2A] outline-transparent"
                          onChange={(e) => changeRole(e, player)}
                        >
                          {player?.role?.map((r) => (
                            <option
                              value={r}
                              key={r}
                              selected={r === player.selectedRole}
                            >
                              {" "}
                              {r}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <p> {player?.role}</p>
                      )}
                    </td>
                    <td>${player.price}</td>
                    <td className="flex justify-center ">
                      <button onClick={() => removePlayer(player)}>
                        <IoMdCloseCircleOutline
                          className="text-[#F25F4C] cursor-pointer"
                          size={24}
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="w-full">
            <a href="#copy">
              <button
                className={`bg-[#674ea7] px-4 py-2 rounded-md text-center w-full  ${
                  selectedPlayers.length !== 5 ? "opacity-50" : ""
                }`}
                disabled={selectedPlayers.length !== 5}
                onClick={generateTeam}
              >
                Create Team
              </button>
            </a>
          </div>
          <div className=" flex flex-col  w-full gap-2 text-sm secondary-font md:text-base">
            {showError.hasError ? (
              <div className="w-full h-[300px] bg-transparent border-solid border-2 bg-red-500 border-[#f9d25f80] rounded-md p-2 overflow-auto">
                Error: Issue(s) with your team.
                {showError.messages?.map((message) => (
                  <p>--- You may have more than 2 {message}</p>
                ))}
                <p>or</p>
                <p>
                  --- You have another player with the same role as your captain
                </p>
              </div>
            ) : (
              <pre className="w-full h-[300px] bg-transparent border-solid border-2 border-[#f9d25f80] rounded-md p-2 overflow-auto">
                {generatedText}
              </pre>
            )}

            <ClipboardCopy
              copyText={generatedText}
              players={selectedPlayers}
              hasError={showError.hasError}
            />
            <a
              target="_blank"
              rel="noreferrer"
              href="https://discord.gg/BwYuQP3GqV"
              className={`text-[#f9d25f] text-center underline px-4 py-2 rounded-md `}
              id="copy"
            >
              Go to Discord
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamBuilder;
