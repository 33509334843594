import {
  addToDisabledTeams,
  addToDisabledPlayers,
  getSalaryLeft,
  DataFilter,
  addToDisabledMaps,
} from "pages/Games/siege_r6/lib/utils";

export function disablePlayers(selectedPlayers, selectedTeams, players) {
  let salaryLeft = getSalaryLeft([...selectedPlayers, selectedTeams]);
  let filteredByPrice = new DataFilter(players)
    .filterByPrice(salaryLeft + 1)
    .getData();

  const disabledPlayers = addToDisabledPlayers([
    ...filteredByPrice,
    ...selectedPlayers,
  ]);

  return {
    disabledPlayers,
  };
}

export function disableTeams(selectedTeam, selectedPlayers, teams) {
  let salaryLeft = getSalaryLeft([selectedTeam, ...selectedPlayers]);
  let filteredByPrice = new DataFilter(teams)
    .filterByPrice(salaryLeft + 1)
    .getData();

  const disabledTeams = addToDisabledTeams([...filteredByPrice, selectedTeam]);

  return {
    disabledTeams,
  };
}


export function disableMaps(selectedMap, maps) {
  let filteredResult = new DataFilter(maps).getData();
  const disabledMaps = addToDisabledMaps([...filteredResult, selectedMap]);
  return {
    disabledMaps,
  };
}
