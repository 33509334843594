import { create } from "zustand";

export const useValorantStore = create((set) => ({
  slug: "",
  setSlug: () => {
    let currentPath = window.location.pathname;
    currentPath = currentPath.split("/");
    const tournamentSlug = currentPath[currentPath.length - 1];
    set({ slug: tournamentSlug });
  },
}));
