import React from "react";
import { formatToNumber, formatToPercentage } from "lib/utils";

const SummaryStats = ({ statsLoading, statsError, summaryData }) => {
  if (statsLoading) {
    return (
     
        <div className=" h-[50px] bg-gradient-to-r from-[#f9d25f80] to-[#191b2a00] p-2 border-l-2 border-[#F9D25F] border-solid font-semibold w-[200px] animate-pulse flex items-center">
          <p>Loading...</p>
        </div>
    );
  }
  if (statsError) {
    return (
      <div className=" bg-[#2C2A36] flex justify-center items-center">
        Error: {statsError.message}
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-5">
      <div className="bg-gradient-to-r from-[#5aa74e80] to-[#191b2a00] p-2 border-l-2 border-[#5AA74E] border-solid font-semibold w-[150px]">
        <p>Salary</p>
        <p>{summaryData?.salary}</p>
      </div>
      <div className="flex gap-5 flex-wrap">
        <div className="bg-gradient-to-r from-[#674ea780] to-[#191b2a00] p-2 border-l-2 border-[#674EA7] border-solid font-semibold w-[250px]">
          <p>Average Pro Player Cost</p>
          <p>{summaryData?.average_pro_player_cost}</p>
        </div>
        <div className="bg-gradient-to-r from-[#674ea780] to-[#191b2a00] p-2 border-l-2 border-[#674EA7] border-solid font-semibold w-[250px]">
          <p>Average Pro Player Infamy Score</p>
          <p>{summaryData?.average_pro_player_infamy_score}</p>
        </div>
        <div className="bg-gradient-to-r from-[#674ea780] to-[#191b2a00] p-2 border-l-2 border-[#674EA7] border-solid font-semibold w-[250px]">
          <p>Average Pro Player Pick Rate</p>
          <p>{summaryData?.average_pro_player_pick_rate}</p>
        </div>
        <div className="bg-gradient-to-r from-[#674ea780] to-[#191b2a00] p-2 border-l-2 border-[#674EA7] border-solid font-semibold w-[250px]">
          <p>Average Team Cost</p>
          <p>{summaryData?.average_team_cost}</p>
        </div>
        <div className="bg-gradient-to-r from-[#674ea780] to-[#191b2a00] p-2 border-l-2 border-[#674EA7] border-solid font-semibold w-[250px]">
          <p>Average Team Infamy Score</p>
          <p>{formatToNumber(summaryData?.average_team_infamy_score,2)}</p>
        </div>
        <div className="bg-gradient-to-r from-[#674ea780] to-[#191b2a00] p-2 border-l-2 border-[#674EA7] border-solid font-semibold w-[250px]">
          <p>Average Team Pick Rate</p>
          <p>{formatToPercentage(summaryData?.average_team_pick_rate,2)}</p>
        </div>
      </div>
    </div>
  );
};

export default SummaryStats;
