import React, { useState } from "react";
import Valorant from "assets/Valorant_Cover_Aside.webp";
import Overwatch from "assets/Overwatch_Cover_Aside.jpg";
import R6Seige from "assets/R6_Siege_Cover_Aside.jpg";
import logo from "assets/Infamy_Logo_Crown_Logo.png";
import { Link } from "react-router-dom";

const Aside = (props) => {
  return (
    <>
      <aside className=" relative  py-8 lg:pl-8 xl:pl-20 lg:py-0 lg:min-h-screen xl:w-[50%] ">
        {/* <div className="absolute w-[90%] xl:w-[50%] h-full -z-10 left-0"></div> */}
        <header className="px-8 lg:px-0">
          <div
            href="/"
            className="logo flex items-center justify-between lg:justify-start"
          >
            <img src={logo} alt="" className="" width={90} />
            <h1 className="hidden lg:flex primary-font uppercase py-12 lg:text-3xl xl:text-4xl font-bold text-[#F9D25F]">
              Infamy
            </h1>
            <Link to={"/about"}>
              <p className=" secondary-font py-4 uppercase text-white text-sm md:text-lg lg:hidden">
                About
              </p>
            </Link>
          </div>
          <div className="text flex relative flex-row-reverse items-center gap-1 lg:gap-8 lg:flex-row">
            <h1 className="secondary-font uppercase text-white text-sm md:text-lg xl:text-xl ">
              Our Games:
            </h1>
          </div>
        </header>

        {/* Cards */}
        <figure className="cards flex gap-4 px-4 py-4 lg:p-0 move-main overflow-x-scroll lg:overflow-visible max-w-screen lg:w-fit lg:flex-col lg:py-12 snap-x lg:snap-none ">
          <Link to={"/valorant/tournaments"}>
            <img
              className="cursor-pointer card1 border-2 border-solid border-[#674EA7] rounded-[10px] opacity-80 ease-in-out delay-900 hover:scale-105 lg:hover:translate-x-20 hover:opacity-100 lg:hover:shadow-[0_0_123px_9px_rgb(103,78,167)] aspect-[2/1.2] duration-700 lg:aspect-[2/1.1] lg:w-[90%] xl:w-[60%] snap-center"
              src={Valorant}
              alt="Valorant"
              onClick={props.onButtonClick}
            />
          </Link>

          <Link to={"/r6-siege/tournaments"}>
            <img
              className="cursor-pointer card1 border-2 border-solid border-[#674EA7] rounded-[10px] opacity-80 ease-in-out delay-900 hover:scale-105 lg:hover:translate-x-20 hover:opacity-100 lg:hover:shadow-[0_0_123px_9px_rgb(103,78,167)] aspect-[2/1.2]  duration-700 lg:aspect-[2/1.1] lg:w-[90%] xl:w-[60%] snap-center"
              src={R6Seige}
              alt="R6Seige"
              onClick={props.onButtonClick}
            />
          </Link>

          <Link to={"/overwatch/tournaments"}>
            <img
              className="cursor-pointer card1 border-2 border-solid border-[#674EA7] rounded-[10px] opacity-80 ease-in-out delay-900 hover:scale-105 lg:hover:translate-x-20 hover:opacity-100 lg:hover:shadow-[0_0_123px_9px_rgb(103,78,167)] aspect-[2/1.2]  duration-700 lg:aspect-[2/1.1] lg:w-[90%] xl:w-[60%] snap-center"
              src={Overwatch}
              alt="Overwatch"
              onClick={props.onButtonClick}
            />
          </Link>
        </figure>
      </aside>
    </>
  );
};

export default Aside;
