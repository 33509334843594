import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Route, Routes } from "react-router-dom";
import Homepage from "./pages/Home/Homepage";
import About from "./pages/About";
import ValorantIndex from "pages/Games/valorant/Index";
import R6Index from "pages/Games/siege_r6/Index";
import Overwatch from "pages/Games/overwatch/Index";
import Membership from "pages/Membership";
import Wrapped from "pages/Wrapped";
// import Table from "components/Table/Table";
import Index from "pages/OverallLeaderboard/Index";
const TRACKING_ID = "UA-268846177-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      <Route path="/about" element={<About />} />
      <Route path="/membership" element={<Membership />} />
      <Route path="/wrapped" element={<Wrapped />} />
      <Route path="/valorant/*" element={<ValorantIndex />} />
      <Route path="/r6-siege/*" element={<R6Index />} />
      <Route path="/overwatch/*" element={<Overwatch />} />
      {/* <Route path="/test-table/*" element={<Table />}/> */}
      <Route path="/leaderboard/*" element={<Index />} />
    </Routes>
  );
}

export default App;
