import { create } from "zustand";

const params = new URLSearchParams(window.location.search);
export const useLeaderboardStore = create((set) => ({
  current_game: params.get("current_game") || "",
  current_year: params.get("current_year") || "All-Time",
  discord_name: params.get("discord_name") || "",
  discord_name_options: [
    { value: params.get("current_game"), label: params.get("current_game") },
  ],
  game_options: [
    { value: params.get("current_year"), label: params.get("current_year") },
  ],
  year_options: [
    { value: params.get("discord_name"), label: params.get("discord_name") },
  ],
  leaderboard_list_url:
    "https://script.google.com/macros/s/AKfycbxR_NIeGkRfYitxBJ6ajCLdyHDIDE2PDN-xX7jV00EVTOdr7qDlnsn-Pc0JdUteMtWR3g/exec",
  // Updates
  update_value: (value, defaultValue, key) => {
    const obj = {};

    if (!value || value === "") {
      params.set(key, "");
      window.history.pushState({}, "", `?${params.toString()}`);
      obj[key] = defaultValue;
      set(obj);
      return;
    }

    params.set(key, value);
    window.history.pushState({}, "", `?${params.toString()}`);
    obj[key] = value;
    set(obj);
  },

  update_select_options: (arr = [], key) => {
    const obj = {};
    obj[key] = arr;
    set(obj);
  },

  // selectGame: (game) => set({ currentGame: game }),
  // selectGame: (game) => set({ currentGame: game }),

  // Clear
  // removeAllBears: () => set({ value: "overall", label: "Overall" }),
  // removeAllBears: () => set({ value: "overall", label: "Overall" }),
}));
