import React from "react";
import Header from "pages/Games/components/Header";
import ThemedSelect from "components/Select";
import { useLeaderboardStore } from "stores/useLeaderboardStore";
import LeaderboardTable from "./components/LeaderboardTable";
import FilterSection from "./components/FilterSection";
import { ErrorBoundary } from "react-error-boundary";

const Index = () => {
  const {
    current_game,
    discord_name,
    update_value,
    discord_name_options,
    current_year,
  } = useLeaderboardStore((state) => state);

  function searchName(discord_name) {
    update_value(discord_name?.value, "", "discord_name");
  }

  return (
    <div className="h-screen grid grid-rows-[auto_1fr_auto] bg-[#2C2A36] ">
      <Header />
      <div className="p-2 sm:p-5 h-full overflow-y-scroll flex flex-col gap-4 text-white">
        <div className="bg-[#191B2A] rounded-xl p-4 flex flex-col gap-4 primary-font shadow-lg">
          <h1 className="text-xl sm:text-2xl md:text-3xl font-semibold text-center sm:text-left">
            {current_year} Infamy Leaderboard
            <span className="capitalize">{`: ${current_game}`}</span>
          </h1>

          <ErrorBoundary fallback={<div>Error:Something went wrong</div>}>
            <FilterSection />
          </ErrorBoundary>
        </div>

        {/* MAIN TABLE */}
        <div className="bg-[#191B2A] rounded-xl flex flex-col gap-4 primary-font shadow-lg p-4 w-full ">
          <div className="flex justify-between items-end flex-wrap">
            <h1 className="text-xl font-semibold text-center sm:text-left lato-regular capitalize">
              {current_game}
            </h1>

            <ErrorBoundary fallback={<div>Error:Something went wrong</div>}>
              <ThemedSelect
                options={discord_name_options}
                placeholder={"Discord name..."}
                defaultValue={
                  discord_name_options.filter(
                    (g) => g?.value === discord_name
                  )[0]
                }
                className="min-w-[250px] z-20"
                onChange={searchName}
              />
            </ErrorBoundary>
          </div>

          <div className="w-[100%] overflow-x-auto grid">
            <ErrorBoundary fallback={<div>Error:Something went wrong</div>}>
              <LeaderboardTable />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
