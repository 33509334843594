import React, { useState, useEffect } from "react";
import Aside from "./components/Aside";
import MainSide from "./components/MainSide";
import OverwatchBgImages from "assets/Overwatch_Bg_MainSide.webp";
import R6SiegeBgImages from "assets/R6_Siege_Bg_MainSide.jpg";
import ValorantBgImages from "assets/Valorant_MainSide.png";

const Homepage = () => {
  let games = {
    Valorant: {
      Title: "VALORANT",
      Description:
        "A 5v5 character-based tactical FPS where precise gunplay meets unique agent abilities.",
        image: `url(${ValorantBgImages})`,
        tournamentListUrl: '/valorant/tournaments',
    },
    R6Seige: {
      Title: "R6 SIEGE",
      Description:
        "Covering North American, European Regional Leagues & International Events",
      image: `url(${R6SiegeBgImages})`,
      tournamentListUrl: '/r6-siege/tournaments',
    },
    Overwatch: {
      Title: "OVERWATCH",
      Description:
        "A colorful team-based action game starring a diverse cast of powerful heroes. Travel the world, build a team, and contest objectives in exhilarating 5v5 combat.",
      image: `url(${OverwatchBgImages})`,
      tournamentListUrl: '/overwatch/tournaments',
    },
  };
  const [gameDetails, setGameDetails] = useState(games.Valorant);
  const handleButtonClick = (e) => {
    let gameName = e.target.alt;
    let newGameDetails = games[gameName];
    setGameDetails(newGameDetails);
  };

  useEffect(() => {
    document.body.style.backgroundImage = gameDetails.image;
    document.body.style.animation = "image 2s alternate"
  }, [gameDetails]);

  return (
    <div className="bg-[#191B2A80] min-h-screen ">
      <div className=" flex flex-col xl:justify-between lg:flex-row -space-x-4">
        <Aside onButtonClick={handleButtonClick}/>
        <MainSide gameDetails={gameDetails} />
      </div>
    </div>
  );
};

export default Homepage;