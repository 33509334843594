import { configureStore } from "@reduxjs/toolkit";
import SelectedTournamentReducer from "./SelectedTournamentSlice";
import FilterRosterReducerValorant from "./valorant/FilterRosterSlice";
import FilterPerformanceReducerValorant from "./valorant/FilterPerformanceSlice";
import CreateTeamReducerValorant from "./valorant/CreateTeamSlice";
import FilterRosterReducerOverwatch from "./overwatch/FilterRosterSlice";
import FilterPerformanceReducerOverwatch from "./overwatch/FilterPerformanceSlice";
import CreateTeamReducerOverwatch from "./overwatch/CreateTeamSlice";
// import CreateTeamReducerValorant from "./valorant/CreateTeamSlice";
import FilterRosterReducerR6 from "./r6/FilterRosterSlice";
import FilterPerformanceReducerR6 from "./r6/FilterPerformanceSlice";
import CreateTeamReducerR6 from "./r6/CreateTeamSlice";

export const store = configureStore({
  reducer: {
    tournament_details: SelectedTournamentReducer,
    filter_roster_valorant: FilterRosterReducerValorant,
    filter_performance_valorant: FilterPerformanceReducerValorant,
    create_team_valorant: CreateTeamReducerValorant,
    filter_roster_overwatch: FilterRosterReducerOverwatch,
    filter_performance_overwatch: FilterPerformanceReducerOverwatch,
    create_team_overwatch: CreateTeamReducerOverwatch,
    filter_roster_r6: FilterRosterReducerR6,
    filter_performance_r6: FilterPerformanceReducerR6,
    create_team_r6: CreateTeamReducerR6,
  },
});
