import {
  addToDisabledTeams,
  getSalaryLeft,
  DataFilter,
} from "pages/Games/siege_r6/lib/utils";

export function disableTeams(selectedTeam, selectedPlayers, teams) {
  let salaryLeft = getSalaryLeft([selectedTeam, ...selectedPlayers]);
  let filteredByPrice = new DataFilter(teams)
    .filterByPrice(salaryLeft + 1)
    .getData();

  const disabledTeams = addToDisabledTeams([...filteredByPrice, selectedTeam]);

  return {
    disabledTeams,
  };
}


export function disableMaps(selectedTeam, selectedPlayers, teams) {
  let salaryLeft = getSalaryLeft([selectedTeam, ...selectedPlayers]);
  let filteredByPrice = new DataFilter(teams)
    .filterByPrice(salaryLeft + 1)
    .getData();

  const disabledTeams = addToDisabledTeams([...filteredByPrice, selectedTeam]);

  return {
    disabledTeams,
  };
}
