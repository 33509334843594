import { formatToNumber, formatToPercentage } from "lib/utils";
import React from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";

const PlayerDetails = ({ playerData, callBack, rank }) => {
  return (
    <div className="absolute top-0 h-screen bg-[#00000080] left-0 w-screen z-50 flex justify-center items-center p-2 sm:p-0">
      <div className="pt-16 pb-4 px-4 max-h-[90%] md:w-[700px]  bg-[#191B2A] rounded-lg grid grid-cols-1  md:grid-cols-[auto,_1fr] gap-4 items-center justify-center relative overflow-y-auto">
        <IoMdCloseCircleOutline
          className="text-[#F25F4C] cursor-pointer absolute top-4 right-4"
          onClick={(e) => callBack(false)}
          size={24}
        />
        {/* playerData */}
        <section className="grid place-content-center">
          <div
            key={playerData?.pro_player_id}
            className=" bg-[#191b2a]  rounded-xl border-2 border-solid border-[#AF9DDD80] w-[250px] md:w-[300px]"
          >
            <div className="bg-gradient-to-t rounded-t-xl relative  from-[#674EA7] to-[#000000] flex items-end pt-6 overflow-hidden h-[300px]">
              <img
                src={`${playerData?.pro_player_image_url}`}
                alt={`${playerData?.pro_player}`}
                className="absolute top-20"
              />
            </div>
            <div className=" h-1 w-full bg-gradient-to-r  from-[#674EA7] to-[#FA2328] "></div>
            <div className="grid grid-cols-[auto,auto,_1fr] p-4 items-center   bg-gradient-to-r  from-[#0000000a] to-[#674ea747] relative overflow-hidden z-10">
              <p className="p-2">Rank {rank}</p>

              <span className="w-[1px] bg-white h-full"></span>

              <div className="p-2">
                <p className="font-medium" title={`${playerData?.pro_player}`}>
                  {playerData?.pro_player.length < 9
                    ? playerData?.pro_player
                    : playerData?.pro_player.slice(0, 8) + "..."}
                </p>
                <h1 className=" flex gap-2 ">
                  {playerData?.role?.map((r) => (
                    <span key={r} className="text-xs text-[#f9d25f]">{r}</span>
                  ))}
                </h1>
                <div className="flex flex-col gap-1 text-sm">
                  <p>{`${playerData?.team_name}`}</p>
                  <img
                    src={`${playerData?.team_logo_url}`}
                    alt={`${playerData?.team_name}`}
                    title={`${playerData?.team_name}`}
                    width={25}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" h-full grid grid-rows-[auto,_1fr,_auto] text-sm secondary-font ">
          <div className="">
            <p className="flex flex-col items-center py-4">
              <span>Infamy Score</span>
              <span className=" text-[#f9d25f]">
                {playerData?.infamy_score.toFixed(2)}
              </span>
            </p>
          </div>
          {/* <div className="grid grid-cols-[repeat(3,_minmax(auto,1fr)] "> */}
          <div className="grid grid-cols-3 border-y">
            <p className="flex flex-col border-b  justify-center border-r items-center">
              <span>Solo Kills</span>
              <span className=" text-[#f9d25f]">
                {formatToNumber(playerData?.solo_kills)}
              </span>
            </p>
            <p className="flex flex-col border-b  justify-center border-r items-center">
              <span>Deaths</span>
              <span className=" text-[#f9d25f]">{playerData?.deaths}</span>
            </p>
            <p className="flex flex-col border-b  justify-center items-center">
              <span>Final Blows</span>
              <span className=" text-[#f9d25f]">
                {" "}
                {formatToNumber(playerData?.final_blows)}
              </span>
            </p>
            <p className="flex flex-col border-b border-r justify-center  items-center">
              <span>Pick Rate</span>
              <span className=" text-[#f9d25f]">
                {playerData?.pick_rate.toFixed(2)}
              </span>
            </p>
            <p className="flex flex-col border-b border-r justify-center items-center">
              <span>Picks</span>
              <span className=" text-[#f9d25f]">{playerData?.picks}</span>
            </p>
            <p className="flex flex-col border-b justify-center items-center">
              <span>Healing Done</span>
              <span className=" text-[#f9d25f]">
                {formatToNumber(playerData.healing_done)}
              </span>
            </p>
            <p className="flex flex-col border-r justify-center items-center">
              <span className="text-center">Hero Damage Done</span>
              <span className=" text-[#f9d25f]">
                {formatToNumber(playerData.hero_damage_done)}
              </span>
            </p>
            <p className="flex flex-col border-r justify-center items-center">
              <span>Elimination</span>
              <span className=" text-[#f9d25f]">
                {formatToNumber(playerData?.elimination)}
              </span>
            </p>
          </div>

          <div>
            <p className="flex flex-col items-center p-4">
              <span>Point Value</span>
              <span className=" text-[#f9d25f]">
                {playerData?.point_value.toFixed(2)}
              </span>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default PlayerDetails;
