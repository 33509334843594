import React, { useEffect, useState } from "react";
import useGetQuery from "hooks/useGetQuery";
import { overwatchApiUrl } from "lib/constants";

const TournamentDetailsHeader = ({ tournamentSlug }) => {
  const tournamentDetailsUrl = `${overwatchApiUrl}?slug=${tournamentSlug}&func=tournament_details`;

  const [data, isLoading, error] = useGetQuery(tournamentDetailsUrl);

  const [tournamentDetails, setTournamentDetails] = useState({});
  useEffect(() => {
    if (data) {
      tournamentDetailsData();
    }
  }, [data]);

  const options = { month: "short", day: "numeric", year: "numeric" };

  const tournamentDetailsData = () => {
    let tournamentData = data.data[0];
    setTournamentDetails({ ...tournamentData });
  };

  if (isLoading) {
    return (
      <div className=" rounded-md p-4  w-full ">
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-[#f9d25f29] rounded"></div>
            <div className="space-y-3">
              <div className="grid grid-cols-3 gap-4">
                <div className="h-2 bg-[#f9d25f29] rounded col-span-2"></div>
                <div className="h-2 bg-[#f9d25f29] rounded col-span-1"></div>
              </div>
              <div className="h-2 bg-[#f9d25f29] rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className=" bg-[#2C2A36] flex justify-center items-center">
        Error: {error.message}
      </div>
    );
  }

  return (
    <div>
      <div className=" text-3xl flex flex-col sm:flex-row items-center gap-4">
        <img
          src={tournamentDetails.tournament_logo_link}
          alt={tournamentDetails.tournament_name}
          className="h-16 sm:h-28"
        ></img>
        <div className="flex flex-col gap-2">
          <h1 className="text-xl text-center sm:text-2xl sm:text-left">
            {tournamentDetails.overwatch_tournament_name}
          </h1>
          <div className=" text-xs sm:text-sm flex gap-4 flex-wrap lg:flex-row primary-font py-2 justify-center md:justify-start">
            <div className="">
              <p className="flex gap-1 text-center sm:text-left">
                <span>
                  {new Date(
                    tournamentDetails.tournament_start_date
                  ).toLocaleDateString("en-US", options)}
                </span>
                -
                <span>
                  {new Date(
                    tournamentDetails.tournament_end_date
                  ).toLocaleDateString("en-US", options)}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TournamentDetailsHeader;
