"use client";
import React from "react";
import { ScaleLoader } from "react-spinners";

const RulesList = ({ rulesLoading, rulesError, rulesListData }) => {
  if (rulesLoading) {
    return (
      <div className="bg-[#2C2A36] flex justify-center items-center">
        <ScaleLoader color="#F9D25F" />
      </div>
    );
  }

  if (rulesError) {
    return (
      <div className="h-screen bg-[#2C2A36] flex justify-center items-center">
        Error: {rulesError.message}
      </div>
    );
  }

  return (
    <>
      <ul className=" list-inside list-decimal space-y-4 secondary-font text-sm sm:text-base overflow-auto">
        <li>
          Use the given{" "}
          <span className="text-[#f9d25f]">
            {" "}
            Salary {rulesListData?.salary}
          </span>{" "}
          to pick <span className="text-[#f9d25f]"> 5 pros</span>.
        </li>
        <li>
          The total cost of the 5 pros picked{" "}
          <span className="text-[#f9d25f]">cannot exceed the Salary Cap</span>,
          otherwise the roster is invalid.
        </li>
        <li>
          You may only have{" "}
          <span className="text-[#f9d25f]">
            {rulesListData?.same_role_players} pros
          </span>{" "}
          that play the same role on your roster. <br /> <br />
          <ul className="indent-4 sm:indent-8 space-y-2">
            <li>
              Example 1: If you have{" "}
              <span className="text-[#f9d25f]">
                {rulesListData?.same_role_players} Controllers
              </span>{" "}
              you cannot add a third.
            </li>
            <li>
              Example 2: You can double on multiple roles as well (
              {rulesListData?.same_role_players} Controllers,{" "}
              {4 - rulesListData?.same_role_players} Sentinels & 1 Duelist).
            </li>
            <li>
            <span className="text-[#f9d25f]">Note:</span> If a player has a
            secondary role (Duelist, Sentinel) you can choose which one you're
            selecting them to fill for your team.
            </li>
          </ul>
        </li>
        <li>
          You may only have a{" "}
          <span className="text-[#f9d25f]">
            {" "}
            MAXIMUM of {rulesListData?.same_team_players}
          </span>{" "}
          pros from the same team.
        </li>
        <li>
          There is no reward to any Salary money left over so spend wisely.
        </li>
        <li>
          To enter the tournament please leave a comment in the specified format
          below in our fantasy-valorant channel on our discord.{" "}
          <a
            target="_blank"
            href="https://discord.gg/pYxKpat"
            className="text-[#f9d25f] hover:underline"
          >
            https://discord.gg/pYxKpat
          </a>
        </li>
        <li>
          Team Entry Template: <br />
          <br />
          <pre className="bg-[#00000040] overflow-auto p-2 lg:p-4 rounded">
            Tournament Id---60 <br />
            ----------
            <br />
            Position--Pro_PlayerName--Role--Price
            <br />
            ----------
            <br />
            Player 1--Genghsta--InItiator--$18
            <br />
            Player 2--Trent--Initiator--$18
            <br />
            Player 3--Tex--Duelist--$19
            <br />
            Player 4--SYM--duelist--$19
            <br />
            Captain--Aproto--Sentinel--$26
            <br />
            ----------
            <br />
          </pre>
        </li>
        <li>
          If your team is eligible, then we will put a reaction on your team
          post to notify you that your team has been added. You can also look on
          the Tournaments Leaderboard for your Name/Team.
        </li>
        <li>
          You can choose any Pro on your team to be your Captain. They will
          receive a{" "}
          <span className="text-[#f9d25f]">
            {rulesListData?.captain_multiplier} multiplier
          </span>{" "}
          for their Infamy Points.
        </li>
        <li>
          Number of players that can share the same role of the Captain:{" "}
          <span className="text-[#f9d25f]">
            {rulesListData?.same_role_captain - 1} players
          </span>
          <br />
          <br />
          Example: If the Captain is a Duelist, they must be your ONLY Duelist.
          For your Captain, please list him/her LAST in your team.
        </li>

        <li>
          <span className="text-[#f9d25f] text-xl">
            {" "}
            The participant whose team of 5 players score the most Infamy Points
            wins!
          </span>
        </li>
        <li className="flex flex-col gap-2 items-start">
          Scoring Breakdown:
          <table>
            <thead>
              <tr>
                <th>Stats</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(rulesListData).map((stat, index) => {
                if (index > 4) {
                  return (
                    <tr key={index}>
                      <td>{stat[0]}</td>
                      <td>{stat[1]}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
          {/* <p>
            <br />
            Stage Point Breakdown:
            <br />
            <br />
            1 STAGE POINT = 100 INFAMY POINTS
            <br />
            <br />
            Stage points are granted for skipping games. All members of the team
            get them (they are shared by subs).
            <br />
            <br />
            Group Stage Points = 1 Stage Point for the top seed of each group
            (aka the team that Wins out of Winner's Bracket).
            <br />
            <br />
            Playoff Stage Points = 3 Stage Points (300 points total) for the 4
            team that do not have to play through Groups.
            <br />
            <br />
            1 Stage Point for each team that players in the Upper Bracket
            Finals.
            <br />
            <br />1 additional Stage Point for the team that wins Upper Bracket
            Finals and goes right into Grand Finals.
          </p> */}
          <p>
            <span className="text-[#f9d25f]">Note:</span> Stage Points are
            granted only in Tournament Events to teams that skip games due to
            seeding or advancing in a Winners Bracket.
          </p>
        </li>
      </ul>
    </>
  );
};

export default RulesList;
