import React from 'react'
import { Link } from 'react-router-dom'

function CreateTeamButton({game, slug}) {
  return (
    <div className='flex justify-end sticky top-0 right-0 '>
      <Link to={`/${game}/tournaments/pro-player-roster/${slug}`} className='items-end text-sm w-fit bg-[#674ea7] px-4 py-2 rounded-md text-center'>Create A Team</Link>
    </div>
  )
}

export default CreateTeamButton