import { useEffect, useState } from "react";
import { useDetailsStore } from "stores/useDetailsStore";
import { formatToNumber, formatToPercentage } from "lib/utils";
import { valorantApiUrl } from "lib/constants";
import useGetQuery from "hooks/useGetQuery";
import { useValorantStore } from "stores/useValorantStore";
import ErrorBoundary from "hooks/ErrorBoundary";
import Modal from "pages/Games/components/Modal";

export default function PlayerDetails({ playerDetails }) {
  const { setOpenMoreDetails, openMoreDetails } = useDetailsStore(
    (state) => state
  );
  const [currentPlayer, setCurrentPlayer] = useState(playerDetails?.pro_1);

  return (
    <>
      <ErrorBoundary fallback={<div>Error:Modal Failed to Load</div>}>
        <Modal setOpen={setOpenMoreDetails} open={openMoreDetails}>
          <MoreDetails playerName={currentPlayer} rank={1} />
        </Modal>
      </ErrorBoundary>

      <div className="flex justify-between items-center">
        <h1 className=" text-2xl ">
          {" "}
          {playerDetails?.discord_name}
          {playerDetails?.emojis}
        </h1>
      </div>

      <div className="flex flex-col gap-2 sm:gap-8 overflow-auto text-sm sm:text-base ">
        <div className="flex flex-col gap-2  sm:flex-wrap ">
          <p className="flex-1">
            <span className=" text-[#F9D25F]">Rank : </span>
            {playerDetails?.rank}
          </p>
          <p className="flex-1">
            <span className=" text-[#F9D25F]">Infamy Score : </span>
            {formatToNumber(playerDetails?.infamy_score, 2)}
          </p>
          <p className="flex-1">
            <span className=" text-[#F9D25F]">Percentile : </span>
            {formatToPercentage(playerDetails?.percentile, 2)}
          </p>
        </div>

        <div className="grid grid-cols-fluid-xs gap-4">
          <ErrorBoundary fallback={<div>Error:Player Card Failed to Load</div>}>
            <PlayerCard
              playerName={playerDetails?.pro_2}
              point={playerDetails.points_2}
              setCurrentPlayer={setCurrentPlayer}
            />
          </ErrorBoundary>
          <ErrorBoundary fallback={<div>Error:Player Card Failed to Load</div>}>
            <PlayerCard
              playerName={playerDetails?.pro_1}
              point={playerDetails.points_1}
              setCurrentPlayer={setCurrentPlayer}
            />
          </ErrorBoundary>
          <ErrorBoundary fallback={<div>Error:Player Card Failed to Load</div>}>
            <PlayerCard
              playerName={playerDetails?.pro_3}
              point={playerDetails.points_3}
              setCurrentPlayer={setCurrentPlayer}
            />
          </ErrorBoundary>
          <ErrorBoundary fallback={<div>Error:Player Card Failed to Load</div>}>
            <PlayerCard
              playerName={playerDetails?.pro_4}
              point={playerDetails.points_4}
              setCurrentPlayer={setCurrentPlayer}
            />
          </ErrorBoundary>

          <ErrorBoundary fallback={<div>Error:Player Card Failed to Load</div>}>
            <PlayerCard
              playerName={playerDetails?.pro_5_cpt}
              point={+playerDetails?.points_5 + +playerDetails?.cpt_points}
              setCurrentPlayer={setCurrentPlayer}
              isCaptain={true}
            />
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
}

function PlayerCard({
  playerName = "Infamy",
  point = 0,
  setCurrentPlayer,
  isCaptain = false,
}) {
  const { setOpenMoreDetails } = useDetailsStore((state) => state);

  return (
    <>
      <button
        className="bg-[#00000050] p-2 hover:bg-[#00000080] flex flex-col rounded duration-300"
        onClick={() => {
          setCurrentPlayer(playerName);
          setOpenMoreDetails(true);
        }}
      >
        <p className="text-sm text-gray-400">
          {playerName}
          {isCaptain ? " 🫡" : ""}
        </p>
        <h3 className="font-semibold tracking-tight flex items-baseline gap-1 text-xl tabular-nums">
          {formatToNumber(point, 2)}
          <span className="text-xs font-normal tracking-normal text-gray-500">
            points
          </span>
        </h3>
      </button>
    </>
  );
}

function MoreDetails({ playerName, rank }) {
  const { slug } = useValorantStore((state) => state);
  const [data, isLoading, error] = useGetQuery(
    `${valorantApiUrl}?slug=${slug}&func=pro_player_performance`
  );
  const [playerData, setPlayerData] = useState(undefined);

  useEffect(() => {
    if (data) {
      const [selectedPlayer] = data.data.filter(
        (a) => a?.pro_player?.toLowerCase() === playerName?.toLowerCase()
      );
      setPlayerData(selectedPlayer);
    }
  }, [data, playerName]);

  if (error) {
    return <div className="text-red-400">Error: {error?.message}</div>;
  }

  if (isLoading) {
    return (
      <h3 className="font-semibold tracking-tight flex items-baseline gap-1 text-xl tabular-nums">
        Loading...
      </h3>
    );
  }

  if (!playerData) {
    return (
      <h3 className="font-semibold tracking-tight flex items-baseline gap-1 text-xl tabular-nums">
        No Data Found
      </h3>
    );
  }

  return (
    <div className="  bg-[#191B2A]  grid grid-cols-1  md:grid-cols-[auto,_1fr] gap-4 items-center justify-center relative overflow-y-auto">
      <section className="grid place-content-center">
        <div
          key={playerData?.pro_player_id}
          className=" bg-[#191b2a]  rounded-xl border-2 border-solid border-[#AF9DDD80] w-[250px] md:w-[300px]"
        >
          <div className="bg-gradient-to-t rounded-t-xl relative  from-[#674EA7] to-[#000000] flex items-end pt-6 overflow-hidden h-[300px]">
            <img
              src={`${playerData?.pro_player_image_url}`}
              alt={`${playerData?.pro_player}`}
              className="absolute top-30 left-1/2 -translate-x-1/2"
            />
          </div>
          <div className=" h-1 w-full bg-gradient-to-r  from-[#674EA7] to-[#FA2328] "></div>
          <div className="grid grid-cols-[auto,auto,_1fr] p-4 items-center   bg-gradient-to-r  from-[#0000000a] to-[#674ea747] relative overflow-hidden z-10">
            <p className="p-2">Rank {rank}</p>

            <span className="w-[1px] bg-white h-full"></span>
            <div className="p-2">
              <p className="font-medium" title={`${playerData?.pro_player}`}>
                {playerData?.pro_player.length < 9
                  ? playerData?.pro_player
                  : playerData?.pro_player.slice(0, 8) + "..."}
              </p>
              <h1 className=" flex gap-2 ">
                {playerData?.role?.map((r) => (
                  <span key={r} className="text-xs text-[#f9d25f]">
                    {r}
                  </span>
                ))}
              </h1>
              <div className="flex flex-col gap-1 text-sm">
                <p>{`${playerData?.team_name}`}</p>
                <img
                  src={`${playerData?.team_logo_url}`}
                  alt={`${playerData?.team_name}`}
                  title={`${playerData?.team_name}`}
                  width={25}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" h-full grid grid-rows-[auto,_1fr,_auto] text-sm secondary-font ">
        <div className="">
          <p className="flex flex-col items-center py-4">
            <span>Infamy Score</span>
            <span className=" text-[#f9d25f]">
              {playerData?.infamy_score.toFixed(2)}
            </span>
          </p>
        </div>
        <div className="grid grid-cols-3 border-y">
          <p className="flex flex-col border-b  justify-center border-r items-center">
            <span>Kills</span>
            <span className=" text-[#f9d25f]">{playerData?.kills}</span>
          </p>
          <p className="flex flex-col border-b  justify-center border-r items-center">
            <span>Deaths</span>
            <span className=" text-[#f9d25f]">{playerData?.deaths}</span>
          </p>
          <p className="flex flex-col border-b  justify-center items-center">
            <span>Assists</span>
            <span className=" text-[#f9d25f]">{playerData?.assists}</span>
          </p>
          <p className="flex flex-col border-b border-r justify-center  items-center">
            <span>Pick Rate</span>
            <span className=" text-[#f9d25f]">
              {playerData?.pick_rate.toFixed(2)}
            </span>
          </p>
          <p className="flex flex-col border-b border-r justify-center items-center">
            <span>Picks</span>
            <span className=" text-[#f9d25f]">{playerData?.picks}</span>
          </p>
          <p className="flex flex-col border-b justify-center items-center">
            <span>1st Kill</span>
            <span className=" text-[#f9d25f]">{playerData?.["1st_kill"]}</span>
          </p>
          <p className="flex flex-col border-r justify-center items-center">
            <span className="text-center">1st Death</span>
            <span className=" text-[#f9d25f]">{playerData?.["1st_death"]}</span>
          </p>
          <p className="flex flex-col border-r justify-center items-center">
            <span>Clutch</span>
            <span className=" text-[#f9d25f]">{playerData?.clutch}</span>
          </p>
          <p className="flex flex-col  justify-center items-center">
            <span>ACS</span>
            <span className=" text-[#f9d25f]">
              {formatToNumber(playerData?.average_combat_score, 2)}
            </span>
          </p>
        </div>

        <div>
          <p className="flex flex-col items-center p-4">
            <span>Point Value</span>
            <span className=" text-[#f9d25f]">
              {playerData?.point_value.toFixed(2)}
            </span>
          </p>
        </div>
      </section>
    </div>
  );
}
