import React from 'react'
import Header from '../components/Header'
import TournamentList from './TournamentList'
import { Route, Routes } from 'react-router-dom'
import TournamentDetails from './TournamentDetails/TournamentDetails'

const Index = () => {
  return (
    <div className='h-screen grid grid-rows-[auto_1fr_auto] bg-[#2C2A36] '>
        <Header/>
          <Routes>
        <Route path="/tournaments" element={<TournamentList />}/>
        <Route path="/tournaments/*" element={<TournamentDetails />}/>
      </Routes>
    </div>
  )
}

export default Index

// tournament list ="https://script.google.com/macros/s/AKfycbw5bdLj63bddMqXXxOhl3AOmXbXicrmHcJwEs1NS6aXLAmrlCaiIUh3A1Jd6O-N8_I_hQ/exec"
// Rules = "https://script.google.com/macros/s/AKfycbxVtYUb3PiRM8lKKIHd1xWuB7IJD4fr7jvL6LlK1zY7dW5kJCu2oOjz_ERLEYUKTBPvcA/exec"
// Leaderboard = "https://script.google.com/macros/s/AKfycbzTd193Kv4mJHo-xE9ilRz47bkzWktifRO4XmlM3G5ABsQhNgSaKqC4bldUG8ZKeo3c/exec"
// Matches = "https://script.google.com/macros/s/AKfycbyYEXX7C7smlyXN2GFiSEx101FhLPZ8vtPhh2O7Gu7W7gWeetqVsM2rkjQ7dKAcnKw4XA/exec"
// Details = "https://script.google.com/macros/s/AKfycbzeqJgPKusWi_r6jxWoUghRV3_Kk7PxnB18zHQLUSOtWcDIRPYW2QbsJTMuoovh4eiw/exec"
// Pro player Roster = "https://script.google.com/macros/s/AKfycbzOn7-rgZa8XF2XT5pjYQVOlTluHHMrF7sMk4qs13bUaJT92unhrlXn9a_t5dVKdb48Mg/exec"
// overview stats = "https://script.google.com/macros/s/AKfycbw6afuSoivSovoqGQ1kIY-dnglHHhNYMW7hbUj_TATusSvaGzZ7r8llWpZ7Sk6ebij4rw/exec"




// pro player performance = "https://script.google.com/macros/s/AKfycbzfzvnAjUT3vH9dY_U5-JUPImv4dJO3ZnSYEN9bJEEzFckBf4-LKnkQkY7I4qDgcoZD/exec"