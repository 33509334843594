import BackButton from "components/BackButton";
import React from "react";
import { MdArrowBackIosNew } from "react-icons/md";
import { SiDiscord } from "react-icons/si";
import { Link } from "react-router-dom";

const Membership = () => {
  return (
    <div className="secondary-font min-h-screen px-4 py-16 md:px-8 lg:px-16 xl:p-32 flex flex-col gap-8 xl:gap-16 bg-[#191B2A80]">
      <header className=" text-2xl font-medium text-[#fffffe] text-left ">
      <BackButton/>

      
      </header>
    <div className="items-center flex flex-col gap-8 xl:gap-16">
      <h1 className="text-3xl md:text-5xl lg:text-6xl primary-font font-bold text-[#F9D25F] move-main text-center">About Us</h1>
      <p className="text-[#fffffe] text-xl md:text-2xl lg:text-3xl move-main">
      Welcome to Infamy, the premier fantasy esports discord community for Valorant, Rainbow 6 Siege, and Overwatch 2! We're a group of passionate gamers who have come together to create a unique and engaging experience for players like you. 
      </p>
      <p className="text-[#fffffe] text-xl md:text-2xl lg:text-3xl move-main">
      Our tournaments are hosted using Google Sheets, making it easy and accessible for everyone to join in on the fun. Our community is dedicated to fostering a friendly and competitive environment where players can showcase their skills, make new friends, and most importantly, have fun.
      </p>
      <p className="text-[#fffffe] text-xl md:text-2xl lg:text-3xl move-main">
      Whether you're a seasoned veteran or a newcomer to the world of fantasy esports, Infamy is the perfect place for you. So what are you waiting for? Join us today and become part of the Infamy community!
      </p>
      
    </div>
      <footer className="flex items-center justify-center">

      <a href="https://discord.gg/pYxKpat" className="flex gap-2 bg-[#F9D25F] px-4 py-2 lg:py-4 lg:px-8 rounded lg:rounded-lg text-[#434343] hover:scale-105 duration-500" target="_blank">
 <p>Join Our Discord</p><SiDiscord size={24} />
    </a>
      </footer>
    <a href="https://sites.google.com/view/justify-between/home" className="relative nav text-[#fffffe] text-xl font-medium">Created By: Justify-Between</a>
    </div>

  );
};

export default Membership;
