import React from "react";
import { formatDate } from "lib/utils";


const MatchCards = ({ MatchesData, statsLoading, statsError }) => {

  if (statsLoading) {
    return (
      <div className=" h-[50px] bg-gradient-to-r from-[#f9d25f80] to-[#191b2a00] p-2 border-l-2 border-[#F9D25F] border-solid font-semibold w-full animate-pulse flex items-center">
        <p>Loading...</p>
      </div>
    );
  }

  if (statsError) {
    return (
      <div className=" bg-[#2C2A36] flex justify-center items-center">
        Error: {statsError.message}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6 lg:gap-8 pt-4 lg:p-0 secondary-font" >
      <h1 className=" lg:text-2xl w-full text-center">Up Coming Matches</h1>
      <div className="grid  grid-cols-fluid-sm md:grid-cols-fluid-lg gap-4 lg:gap-8">
        {MatchesData?.map((match, index) => (
          <div
            className=" bg-[#191B2A] gap-1"
            key={index}
          >
             <div className="bg-gradient-to-r from-[#191b2a00] from-10% via-[#674ea740] via-80% to-[#191b2a00] to-100% flex  items-center justify-center col-span-2 px-2 lg:px-16 lg:py-4  py-2  ">
              
              <p>{match.round}</p>
            </div>
            <div className="relative grid grid-cols-2 gap-1 my-1">
            <p className=" absolute left-0 right-0 top-0 bottom-0 m-auto flex justify-center items-center text-[#F9D25F] bg-[#191B2A] w-[40px] h-[40px] rounded-full z-50 text-sm ">
                VS
              </p>
            <div className=" flex flex-col gap-3 items-center justify-between bg-gradient-to-l from-[#674ea740] to-[#191b2a00] p-4 ">
              <img
                src={match.team_1_logo_url}
                alt=""
                className=" "
                width={150}
              />
              <p className="text-center ">{match.team_1_name}</p>
            </div>
           

            <div className=" flex flex-col gap-3 items-center justify-between bg-gradient-to-r from-[#674ea740] to-[#191b2a00] p-4">
            
              <img
                src={match.team_2_logo_url}
                alt=""
                className=" "
                width={150}
              />
              <p className="text-center ">{match.team_2_name}</p>
            </div>
            </div>
            <div className="bg-gradient-to-r from-[#191b2a00] from-10% via-[#674ea740] via-80% to-[#191b2a00] to-100% flex  justify-center items-center  px-2 lg:px-16 py-2 lg:py-4  col-span-2 ">
          
              <p className="text-[#F9D25F]">
                {formatDate(match.start_time)}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MatchCards;
