export function stringToSlug(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

export function groupPlayersByTeams(data) {
  const uniqueTeamIds = getUniqueTeamIds_(data);

  let filteredTeamData = {
    allTeams: [],
  };

  for (let i = 0; i < uniqueTeamIds.length; i++) {
    const filteredData = new DataFilter(data)
      .filterByTeamId(uniqueTeamIds[i])
      .getData();
    let team = {
      group: filteredData[0].group,
      team_id: filteredData[0].team_id,
      team_name: filteredData[0].team_name,
      team_logo_url: filteredData[0].team_logo_url,
    };
    team[team["team_id"]] = [];

    for (let j = 0; j < filteredData.length; j++) {
      let playerData = {
        pro_player_id: filteredData[j].pro_player_id,
        pro_player: filteredData[j].pro_player,
        pro_player_image_url: filteredData[j].pro_player_image_url,
        role: filteredData[j].role,
        price: filteredData[j].price,
      };

      team[team["team_id"]].push(playerData);
    }

    filteredTeamData.allTeams.push(team);
  }

  return filteredTeamData;
}

function getUniqueTeamIds_(jsonData) {
  const teams = new Set();
  for (const teamData of jsonData) {
    teams.add(teamData.team_id);
  }
  return Array.from(teams);
}

export class DataFilter {
  // Constructor function that initializes the data to filter
  constructor(data) {
    // Performs a deep copy of the data to avoid modifying the original data
    this.data = JSON.parse(JSON.stringify(data));
  }

  // Function to filter the data by player name
  filterByText(columnName = "", text = "") {
    if (columnName === "" || text === "") return this;
    this.data = this.data.filter((item) =>
      item[columnName].toLowerCase().includes(text.toLowerCase())
    );
    // Return the instance of the class to enable method chaining
    return this;
  }

  filterByNumber(columnName = "", number = "") {
    if (columnName === "" || isNaN(number)) return this;
    this.data = this.data.filter((item) => +item[columnName] === +number);
    // Return the instance of the class to enable method chaining
    return this;
  }

  // Function to filter the data by player name
  filterByPlayerName(playerName = "") {
    this.data = this.data.filter((item) =>
      `${item.pro_player}`.toLowerCase().includes(playerName.toLowerCase())
    );
    // Return the instance of the class to enable method chaining
    return this;
  }

  // Function to filter the data by team name
  filterByTeam(teamName = "") {
    this.data = this.data.filter((item) =>
      `${item.team_name}`.toLowerCase().includes(teamName.toLowerCase())
    );
    // Return the instance of the class to enable method chaining
    return this;
  }

  // Function to filter the data by team name
  filterByTeamId(teamId = "") {
    this.data = this.data.filter((item) => item.team_id === teamId);
    // Return the instance of the class to enable method chaining
    return this;
  }

  // Function to filter the data by range
  filterByRange(columnName = "", minRange = 0, maxRange = 1000) {
    if (minRange === "" && maxRange === "") {
      return this;
    }

    // If both minRange and maxRange are provided, filter data between the range
    if (minRange !== undefined && maxRange !== undefined) {
      this.data = this.data.filter(
        (item) => item[columnName] >= minRange && item[columnName] <= maxRange
      );
      // If only minRange is provided, filter data with a price greater than or equal to minRange
    } else if (minRange !== undefined) {
      this.data = this.data.filter((item) => item[columnName] >= minRange);
      // If only maxRange is provided, filter data with a price less than or equal to maxRange
    } else if (maxRange !== undefined) {
      this.data = this.data.filter((item) => item[columnName] <= maxRange);
    }
    // Return the instance of the class to enable method chaining
    return this;
  }
  // Function to filter the data by price range
  filterByPrice(minPrice = 0, maxPrice = 1000) {
    if (minPrice === "" && maxPrice === "") {
      return this;
    }

    // If both minPrice and maxPrice are provided, filter data between the range
    if (minPrice !== undefined && maxPrice !== undefined) {
      this.data = this.data.filter(
        (item) => item.price >= minPrice && item.price <= maxPrice
      );
      // If only minPrice is provided, filter data with a price greater than or equal to minPrice
    } else if (minPrice !== undefined) {
      this.data = this.data.filter((item) => item.price >= minPrice);
      // If only maxPrice is provided, filter data with a price less than or equal to maxPrice
    } else if (maxPrice !== undefined) {
      this.data = this.data.filter((item) => item.price <= maxPrice);
    }
    // Return the instance of the class to enable method chaining
    return this;
  }

  // A Function to filter the data by roles
  filterByAllRoles(roles = []) {
    // If roles array is empty, return the instance of the class to enable method chaining
    if (roles.length === 0) {
      return this;
    }

    //Filter the data by roles that are included in the roles array
    this.data = this.data.filter((item) => {
      if (Array.isArray(item.role)) {
        let r = [];
        if (item.role[0].toLowerCase() === "flex") {
          r = ["Initiator", "Sentinel", "Controller", "Duelist"];
        } else {
          r = item.role;
        }
        return r.every((r) => roles.includes(r));
      }
    });

    // Return the instance of the class to enable method chaining
    return this;
  }

  filterByRoles(roles = []) {
    // If roles array is empty, return the instance of the class to enable method chaining
    if (roles.length === 0) {
      return this;
    }

    //Filter the data by roles that are included in the roles array
    this.data = this.data.filter((item) => {
      if (Array.isArray(item.role)) {
        let r = [];
        if (item.role[0].toLowerCase() === "flex") {
          r = ["Initiator", "Sentinel", "Controller", "Duelist"];
        } else {
          r = item.role;
        }
        return r.some((r) => roles.includes(r));
      }
    });

    // Return the instance of the class to enable method chaining
    return this;
  }

  // Function to return the filtered data
  getData() {
    return this.data;
  }
}

// get unique teams names from the data and format for dropdown button
export function formatForDropdown(data = []) {
  let teams = getUniqueTeams_(data);

  let options = teams.map((team) => {
    return {
      value: team,
      label: team,
    };
  });

  return options;
}

function getUniqueTeams_(jsonData) {
  const teams = new Set();
  for (const teamData of jsonData) {
    teams.add(teamData.team_name);
  }
  return Array.from(teams);
}

export function generatePlayerViewChartData(data) {
  if (!data) return;

  return {
    labels: data?.map((d) => d.pro_player),
    datasets: [
      {
        label: "Players Infamy Score",
        data: data?.map((d) => d.infamy_score),
        backgroundColor: "rgba(0, 71, 62, 5)",
        borderColor: "rgba(0, 71, 62, 0.8)",
      },
    ],
  };
}

export function generateTeamViewChartData(data) {
  if (!data) return;

  data = aggregateTeamScore_(data, "infamy_score");

  return {
    labels: data?.map((d) => d.team_name),
    datasets: [
      {
        label: "Teams Infamy Score",
        data: data?.map((d) => d.infamy_score),
        backgroundColor: "rgba(0, 71, 62, 5)",
        borderColor: "rgba(0, 71, 62, 0.8)",
      },
    ],
  };
}

function aggregateTeamScore_(data, pointName) {
  const uniqueTeamIds = getUniqueTeamIds_(data);

  let aggregatedTeamData = [];

  for (let i = 0; i < uniqueTeamIds.length; i++) {
    const filteredData = new DataFilter(data)
      .filterByTeamId(uniqueTeamIds[i])
      .getData();
    let numbers = filteredData?.map((d) => d[pointName]);
    const sum = numbers.reduce(
      (accumulator, currentValue) => +accumulator + currentValue,
      0
    );

    let obj = {};
    obj["team_name"] = filteredData[0]?.team_name;
    obj[pointName] = sum;
    aggregatedTeamData[i] = obj;
  }

  return aggregatedTeamData;
}

export function generateKDAPlayerViewChartData(data) {
  if (!data) return;

  return {
    labels: data?.map((d) => d.pro_player),
    datasets: [
      {
        label: "Kills",
        data: data?.map((d) => d.kills),
        backgroundColor: "rgba(147, 196, 125, 1)",
      },
      {
        label: "Deaths",
        data: data?.map((d) => d.deaths),
        backgroundColor: "rgba(204,65,37, 1)",
      },
      {
        label: "Assists",
        data: data?.map((d) => d.assists),
        backgroundColor: "rgba(255,217,102, 1)",
      },
    ],
  };
}

export function generateKDATeamViewChartData(data) {
  if (!data) return;

  let killsData = aggregateTeamScore_(data, "kills");
  let deathsData = aggregateTeamScore_(data, "deaths");
  let assistsData = aggregateTeamScore_(data, "assists");

  return {
    labels: killsData?.map((d) => d.team_name),
    datasets: [
      {
        label: "Kills",
        data: killsData?.map((d) => d.kills),
        backgroundColor: "rgba(147, 196, 125, 1)",
      },
      {
        label: "Deaths",
        data: deathsData?.map((d) => d.deaths),
        backgroundColor: "rgba(204,65,37, 1)",
      },
      {
        label: "Assists",
        data: assistsData?.map((d) => d.assists),
        backgroundColor: "rgba(255,217,102, 1)",
      },
    ],
  };
}

export function orderData(data = [], orderBy = "", ascending = true) {
  return [...data].sort((a, b) => {
    let comparison = 0;

    if (typeof a[orderBy] === "number" && typeof b[orderBy] === "number") {
      comparison = a[orderBy] - b[orderBy];
    } else {
      // Handle non-numeric values, you might want to customize this based on your use case
      comparison = String(a[orderBy]).localeCompare(String(b[orderBy]));
    }

    return ascending ? comparison : -comparison;
  });
}

export function formatToNumber(number = 0, decimals = 0) {
  if (isNaN(number)) {
    number = 0;
  }

  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

export function formatToPercentage(number = 0, decimals = 2) {
  if (isNaN(number)) {
    number = 0;
  }

  return new Intl.NumberFormat(undefined, {
    style: "percent",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

export function formatToCurrency(number = 0, currency = "USD") {
  if (isNaN(number)) {
    number = 0;
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(number);
}

export function addToDisabledPlayers(players = []) {
  let obj = {};
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    obj[player.pro_player_id] = player.pro_player;
  }
  return obj;
}

export function getSalaryLeft(players = []) {
  let salary = 100;
  return (
    salary -
    players.reduce((total, current) => {
      return total + +formatToNumber(current.price, 0);
    }, 0)
  );
}

export function getTeam(players = []) {
  let obj = {};

  for (let i = 0; i < players.length; i++) {
    let player = players[i].team_name;
    if (!obj[player]) {
      obj[player] = 1;
      continue;
    }
    obj[player] += 1;
    if (obj[player] >= 3) {
      return player;
    }
  }

  return "-----";
}
export function getRoles(players = []) {
  let obj = {
    Initiator: 2,
    Duelist: 2,
    Sentinel: 2,
    Controller: 2,
  };
  let arr = ["none"];
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    if (player.position === "captain") {
      obj[player.selectedRole] -= 2;
    } else {
      obj[player.selectedRole] -= 1;
    }

    if (obj[player.selectedRole] <= 0) {
      arr.push(player.selectedRole);
    }
  }

  return [arr, obj];
}

export function isSelected(player = {}, selectedPlayers = []) {
  for (let i = 0; i < selectedPlayers.length; i++) {
    let selectedPlayer = selectedPlayers[i];

    if (player.pro_player_id === selectedPlayer.pro_player_id) {
      return true;
    }
  }

  return false;
}

export function getPosition(player = {}, selectedPlayers = []) {
  for (let i = 0; i < selectedPlayers.length; i++) {
    let selectedPlayer = selectedPlayers[i];

    if (player.pro_player_id === selectedPlayer.pro_player_id) {
      return i;
    }
  }

  return -1;
}

export function formatDate(dateString) {
  const options = { month: "short", day: "numeric", year: "numeric" };
  let d = new Date(dateString).toLocaleDateString("en-US", options);
  if (d === "Invalid Date") return "";
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const dateParts = dateString.split("-");
  const year = dateParts[0];
  const month = months[parseInt(dateParts[1]) - 1];
  const day = parseInt(dateParts[2]);
  const daySuffix = getDaySuffix(day);
  const weekday = days[new Date(dateString).getDay()];

  return `${weekday}, ${day}${daySuffix} ${month} ${year}`;
}

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return "th";
  }

  switch (day % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}
