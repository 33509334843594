import React, { useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import useGetQuery from "hooks/useGetQuery";
import { DataFilter, orderData } from "lib/utils";
import { formatToNumber, formatToPercentage } from "lib/utils";
import { useSelector } from "react-redux";
import TableSkeleton from "components/skeletons/Table";
import PlayerDetails from "./PlayersDetails";
import { useDetailsStore } from "stores/useDetailsStore";
import { valorantApiUrl } from "lib/constants";
import Modal from "pages/Games/components/Modal";

const columns = [
  { label: "Rank", value: "rank", type: "number" },
  { label: "Discord Usernames", value: "discord_name", type: "string" },
  { label: "Picks Left", value: "players_left", type: "number" },
  { label: "Pro 1", value: "points_1", type: "number" },
  { label: "Pro 2", value: "points_2", type: "number" },
  { label: "Pro 3", value: "points_3", type: "number" },
  { label: "Pro 4", value: "points_4", type: "number" },
  { label: "Captain", value: "points_5", type: "number" },
  { label: "Infamy Score", value: "infamy_score", type: "number" },
  { label: "Percentile", value: "percentile", type: "number" },
];

const LeaderBoardTable = ({ tournamentSlug }) => {
  const [data, isLoading, error] = useGetQuery(
    `${valorantApiUrl}?slug=${tournamentSlug}&func=leaderboard`
  );

  const filterLeaderboard = useSelector(
    (state) => state.filter_performance_valorant.leaderboard
  );

  const [leaderBoard, setLeaderBoard] = useState([]);
  const [playerDetails, setPlayerDetails] = useState({});
  const { setOpen, open } = useDetailsStore((state) => state);

  useEffect(() => {
    if (data) {
      leaderBoardList();
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const filteredData = filterTableData(data.data);
      const orderedData = orderData(filteredData, "infamy_score", false);
      setLeaderBoard(orderedData);
    }
  }, [filterLeaderboard]);

  function filterTableData(data) {
    let newData = new DataFilter(data);

    for (let i = 0; i < columns.length; i++) {
      let column = columns[i];
      if (column.type === "string") {
        newData = newData.filterByText(
          column.value,
          filterLeaderboard[column.value]
        );
      } else if (column.type === "number") {
        newData = newData.filterByRange(
          column.value,
          ...filterLeaderboard[column.value]
        );
      }
    }

    return newData.getData();
  }

  const showMoreDetails = (player) => {
    let newShowDetails = { ...player };
    setOpen(true);
    setPlayerDetails(newShowDetails);
    // setShowDetails(true);
  };

  const leaderBoardList = () => {
    let leaderBoardData = data.data;
    const orderedData = orderData(leaderBoardData, "infamy_score", false);
    setLeaderBoard([...orderedData]);
  };

  function isPaidMembers(e) {
    if (e.target.value === "Paid Members") {
      let leaderBoardData = data.data;
      let filteredData = leaderBoardData.filter((d) => d?.emojis !== "");
      const orderedData = orderData(filteredData, "infamy_score", false);
      setLeaderBoard([...orderedData]);
    } else {
      let leaderBoardData = data.data;
      const orderedData = orderData(leaderBoardData, "infamy_score", false);
      setLeaderBoard([...orderedData]);
    }
  }

  if (isLoading) {
    return (
      // <div className="bg-[#191B2A] p-4 rounded-lg">
      <TableSkeleton header={columns.map((a) => a.label)} rowsNum={10} />
      // {/* </div> */}
    );
  }

  if (error) {
    return (
      <div className="h-screen bg-[#191B2A] p-4 rounded-lg flex justify-center items-center">
        Error: {error.message}
      </div>
    );
  }
  return (
    <>
      <ErrorBoundary fallback={<div>Error:Player Details Failed to Load</div>}>
        <Modal setOpen={setOpen} open={open}>
          <PlayerDetails playerDetails={playerDetails} />
        </Modal>
      </ErrorBoundary>
      <div className="overflow-x-auto">
        <table className="text-left  secondary-font font-normal min-h-[300px] w-full">
          <thead>
            <tr className="">
              {columns.map((column, index) =>
                column.value === "discord_name" ? (
                  <th className=" font-normal flex gap-1" key={index}>
                    <select
                      name="members"
                      id=""
                      className="bg-[#191B2A] outline-transparent"
                      onChange={(e) => isPaidMembers(e)}
                    >
                      <option value="Discord Members" className="">
                        Discord Usernames
                      </option>
                      <option value="Paid Members">Members Only</option>
                    </select>

                    <span>({leaderBoard.length})</span>
                  </th>
                ) : (
                  <th key={index} className="align-top">
                    {column.label}
                  </th>
                )
              )}
            </tr>
          </thead>

          {/* MAIN TABLE */}
          <tbody>
            {leaderBoard?.map((player, index) => (
              <tr
                key={index}
                onClick={() => showMoreDetails(player)}
                className={` cursor-pointer ${
                  player.rank === 1
                    ? `bg-[#d4af37] text-[#000000] `
                    : player.rank === 2
                    ? `bg-[#808080] text-[#000000] `
                    : player.rank === 3
                    ? `bg-[#CD7F32] text-[#000000] `
                    : player.rank ===
                      orderData([...leaderBoard], "rank", false)[0].rank
                    ? `bg-[#964b0080]`
                    : ``
                }`}
              >
                <td className="text-center">{player?.rank}</td>
                <td className="">
                  {player?.discord_name}
                  {player?.emojis}
                </td>
                <td className="text-center">
                  {formatToNumber(player.players_left, 0)}
                </td>
                <td className="">
                  <b>{player?.pro_1}</b>
                  <p>{formatToNumber(player.points_1, 2)}</p>
                </td>
                <td className="">
                  <b>{player?.pro_2}</b>
                  <p>{formatToNumber(player.points_2, 2)}</p>
                </td>
                <td className="">
                  <b>{player?.pro_3}</b>
                  <p>{formatToNumber(player.points_3, 2)}</p>
                </td>
                <td className="">
                  <b>{player?.pro_4}</b>
                  <p>{formatToNumber(player.points_4, 2)}</p>
                </td>
                <td className="">
                  <b>{player?.pro_5_cpt}</b>
                  <p>
                    {" "}
                    {formatToNumber(+player?.points_5 + +player?.cpt_points, 2)}
                  </p>
                </td>
                <td className="bg-[#ffffff30] text-center">
                  {formatToNumber(+player?.infamy_score, 2)}
                </td>
                <td className=" text-center">
                  {formatToPercentage(+player?.percentile, 2)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LeaderBoardTable;
