import React, { useState, useEffect } from "react";
import TournamentDetailsHeader from "../../components/TournamentDetailsHeader";
import { DataFilter, orderData } from "../../../../../lib/utils";
// import FilterInput from "./components/FilterInput";
import { useSelector } from "react-redux";
import PlayerCardView from "./components/PlayerCardView";
import PlayersTableView from "./components/PlayersTableView";
import useGetQuery from "hooks/useGetQuery";
import { ErrorBoundary } from "react-error-boundary";

const PlayerRoster = ({ tournamentSlug }) => {
  const PerformanceDataUrl =
    "https://script.google.com/macros/s/AKfycbyk2uhGHPBtkgWpb3n--pUJn05MIOM96QXh0XLF8oO4FDQNrTCaw1tQTeOlzQHJVtfK/exec";

  const columns = [
    { label: "Team", value: "team_name", type: "string" },
    { label: "Name", value: "pro_player", type: "string" },
    { label: "Role(s)", value: "role", type: "option" },
    { label: "Price $", value: "price", type: "number" },
    { label: "Infamy Score", value: "infamy_score", type: "number" },
    { label: "Kills", value: "kills", type: "number" },
    { label: "Deaths", value: "deaths", type: "number" },
    { label: "Assists", value: "assists", type: "number" },
    { label: "1st Kill", value: "1st_kill", type: "number" },
    { label: "1st Death", value: "1st_death", type: "number" },
    { label: "Clutch", value: "clutch", type: "number" },
    { label: "Point Value", value: "point_value", type: "number" },
    { label: "Picks", value: "picks", type: "number" },
    { label: "Pick Rate", value: "pick_rate", type: "number" },
  ];

  const [data, isLoading, error] = useGetQuery(
    `${PerformanceDataUrl}?id=${tournamentSlug}`
  );
  const [tableView, setTableView] = useState(true);
  const filterPerformance = useSelector(
    (state) => state.filter_performance_valorant.performance
  );
  const [filterPlayerData, setFilterPlayerData] = useState([]);

  function filterTableData(data) {
    let newData = new DataFilter(data);

    for (let i = 0; i < columns.length; i++) {
      let column = columns[i];
      if (column.type === "string") {
        newData = newData.filterByText(
          column.value,
          filterPerformance[column.value]
        );
      } else if (column.type === "number") {
        newData = newData.filterByRange(
          column.value,
          ...filterPerformance[column.value]
        );
      }
    }

    return newData.getData();
  }

  useEffect(() => {
    if (data) {
      const filteredData = filterTableData(data.data);

      const orderedFilteredData = orderData(
        filteredData,
        "infamy_score",
        false
      );
      setFilterPlayerData(orderedFilteredData);
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      const filteredData = filterTableData(data.data);

      setFilterPlayerData(filteredData);
    }
  }, [filterPerformance]);

  // useEffect(() => {
  //   if (data) {
  //     const filteredData = new DataFilter(data.data)
  //       .filterByPlayerName(filterPerformance.playerName)
  //       .filterByTeam(filterPerformance.teamName)
  //       .filterByRoles(filterPerformance.role)
  //       .getData();

  //     const orderedFilteredData = orderData(
  //       filteredData,
  //       filterPerformance.orderBy,
  //       false
  //     );
  //     setFilterPlayerData(orderedFilteredData);
  //   }
  // }, [data, filterPerformance]);

  return (
    <div className=" p-2 sm:p-5 h-full overflow-y-scroll flex flex-col gap-5 ">
      <div className="bg-[#191B2A] rounded-xl p-3 sm:p-8 flex flex-col gap-8 primary-font shadow-lg">
        <h1 className="text-xl sm:text-2xl md:text-3xl font-semibold py-2 text-center sm:text-left">
          Pro Player Performance
        </h1>

        <ErrorBoundary
          fallback={<div>Error:Tournament Details Failed to Load</div>}
        >
          <TournamentDetailsHeader tournamentSlug={tournamentSlug} />
        </ErrorBoundary>

        <ErrorBoundary fallback={<div>Error:Input failed to load</div>}>
          {/* <FilterInput /> */}
        </ErrorBoundary>
        <p>
          Players Found :{" "}
          <span className="text-[#f9d25f] font-bold">
            {filterPlayerData?.length}
          </span>
        </p>
      </div>

      <div className="bg-[#191B2A] rounded-xl flex flex-col gap-4 primary-font  p-4">
        {/* bg-[#191B2A] rounded-xl flex flex-col gap-4 primary-font shadow-lg p-4 overflow-x-auto */}
        <div>
          <div
            className={`${`bg-[#f9d25f] text-[#191B2A] px-4 py-2 rounded-lg w-fit`} `}
          >
            Table View
          </div>
          {/* <button
            className={`${
              tableView
                ? `bg-[#f9d25f] text-[#191B2A] px-4 py-2 rounded-lg`
                : `font-bold hover:text-[#f9d25f]  px-4 py-2`
            } `}
            onClick={() => setTableView(!tableView)}
          >
            Table View
          </button> */}
          {/* <button
            className={`${
              tableView
                ? `font-bold hover:text-[#f9d25f]  px-4 py-2`
                : `bg-[#f9d25f] text-[#191B2A] px-4 py-2 rounded-lg`
            }`}
            onClick={() => setTableView(!tableView)}
          >
            Card View
          </button> */}
        </div>
        {tableView ? (
          <ErrorBoundary
            fallback={<div>Error:Players Table Failed to Load</div>}
          >
            <PlayersTableView
              statsLoading={isLoading}
              statsError={error}
              playersData={filterPlayerData}
              setFilterPlayerData={setFilterPlayerData}
              columns={columns}
            />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary
            fallback={<div>Error:Player Cards Failed to Load</div>}
          >
            <PlayerCardView
              statsLoading={isLoading}
              statsError={error}
              playersData={filterPlayerData}
            />
          </ErrorBoundary>
        )}
      </div>
    </div>
  );
};

export default PlayerRoster;
