import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BiStats, BiTrophy, BiDiamond } from "react-icons/bi";
import { TfiViewListAlt } from "react-icons/tfi";
import { BsFileRuled } from "react-icons/bs";
import { TiThMenuOutline } from "react-icons/ti";
import { ReactComponent as OverwatchLogo } from "assets/Overwatch_logo.svg";
import { ReactComponent as R6Logo } from "assets/R6_logo.svg";
import { ReactComponent as ValorantLogo } from "assets/valorant_logo.svg";

const SideBar = ({ slug, game }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [currentTab, setCurrentTab] = useState("");
  useEffect(() => {
    let currentPath = window.location.pathname;
    currentPath = currentPath.split("/");
    setCurrentTab(currentPath[3]);
  }, []);

  const handleClickedTab = (tab) => {
    setOpenMenu(false);
    setCurrentTab(tab);
  };

  return (
    <div className="h-full relative z-50">
      <div
        className={`${
          openMenu ? "scale-0" : "scale-100"
        } sm:hidden absolute top-3 left-3 rounded-full bg-[#191B2A80] p-3 border-2 border-[#F9D25F] z-50 text-[#F9D25F] duration-300`}
        onClick={() => setOpenMenu(true)}
      >
        <TiThMenuOutline size={18} />
      </div>
      <div
        className={`${
          openMenu ? "translate-x-0 flex " : "-translate-x-96"
        } sm:translate-x-0 h-full flex-col   bg-[#191B2A] px-4 py-8 gap-4 text-lg border-r-[1px] border-r-solid border-[#f9d25f] shadow-lg fixed sm:static sm:flex duration-500 z-50`}
      >
        <Link
          to={`/${game}/tournaments`}
          className={`flex items-center gap-2 sm:gap-4 group relative pb-4 px-3   hover:scale-90 duration-300`}
        >
          {game === "overwatch" ? (
            <OverwatchLogo />
          ) : game === "valorant" ? (
            <ValorantLogo />
          ) : (
            <R6Logo />
          )}

          <span className=" w-max sm:absolute left-[72px] sm:scale-0 rounded-lg sm:bg-[#F9D25F] sm:text-[#191B2A] sm:font-semibold px-2 py-1  group-hover:scale-100 duration-300 delay-200 capitalize ">
            {game} Tournaments
          </span>
        </Link>

        {/* Rules */}
        <Link
          to={`rules/${slug}`}
          className={`flex items-center gap-2 sm:gap-4 group relative sm:p-3 py-1 px-2 ${
            currentTab === "rules"
              ? "bg-[#F9D25F] text-[#191B2A]"
              : "bg-[#ffffff0b]"
          }  rounded-lg sm:rounded-full  hover:scale-90 duration-300`}
          onClick={() => handleClickedTab("rules")}
        >
          <div className="">
            <BsFileRuled size={24} />
          </div>
          <span className=" w-max sm:absolute left-[72px] sm:scale-0 rounded-lg sm:bg-[#F9D25F] sm:text-[#191B2A] sm:font-semibold px-2 py-1  group-hover:scale-100 duration-300 delay-200 ">
            Rules
          </span>
        </Link>

        {/* Roaster */}
        <Link
          to={`roster/${slug}`}
          className={`flex items-center gap-2 sm:gap-4 group relative sm:p-3 py-1 px-2 ${
            currentTab === "roster"
              ? "bg-[#F9D25F] text-[#191B2A]"
              : "bg-[#ffffff0b]"
          }  rounded-lg sm:rounded-full  hover:scale-90 duration-300`}
          onClick={() => handleClickedTab("roster")}
        >
          <div className="">
            <TfiViewListAlt size={24} />
          </div>
          <span className=" w-max sm:absolute left-[72px] sm:scale-0 rounded-lg sm:bg-[#F9D25F] sm:text-[#191B2A] sm:font-semibold px-2 py-1  group-hover:scale-100 duration-300 delay-200 z-50">
            Roster
          </span>
        </Link>

        {/* Leaderboard */}
        <Link
          to={`leaderboard/${slug}`}
          className={`flex items-center gap-2 sm:gap-4 group relative sm:p-3 py-1 px-2 ${
            currentTab === "leaderboard"
              ? "bg-[#F9D25F] text-[#191B2A]"
              : "bg-[#ffffff0b]"
          }  rounded-lg sm:rounded-full  hover:scale-90 duration-300`}
          onClick={() => handleClickedTab("leaderboard")}
        >
          <div className="">
            <BiTrophy size={24} />
          </div>
          <span className=" w-max sm:absolute left-[72px] sm:scale-0 rounded-lg sm:bg-[#F9D25F] sm:text-[#191B2A] sm:font-semibold px-2 py-1  group-hover:scale-100 duration-300 delay-200 ">
            Leaderboard
          </span>
        </Link>

        {/* Performance */}
        <Link
          to={`performance/${slug}`}
          className={`flex items-center gap-2 sm:gap-4 group relative sm:p-3 py-1 px-2 ${
            currentTab === "performance"
              ? "bg-[#F9D25F] text-[#191B2A]"
              : "bg-[#ffffff0b]"
          }  rounded-lg sm:rounded-full  hover:scale-90 duration-300`}
          onClick={() => handleClickedTab("performance")}
        >
          <div className="">
            <BiDiamond size={24} />
          </div>
          <span className=" w-max sm:absolute left-[72px] sm:scale-0 rounded-lg sm:bg-[#F9D25F] sm:text-[#191B2A] sm:font-semibold px-2 py-1  group-hover:scale-100 duration-300 delay-200 ">
            Performance
          </span>
        </Link>

        {/* Quick Stats */}
        <Link
          to={`quick-stats/${slug}`}
          className={`flex items-center gap-2 sm:gap-4 group relative sm:p-3 py-1 px-2 ${
            currentTab === "quick-stats"
              ? "bg-[#F9D25F] text-[#191B2A]"
              : "bg-[#ffffff0b]"
          }  rounded-lg sm:rounded-full  hover:scale-90 duration-300`}
          onClick={() => handleClickedTab("quick-stats")}
        >
          <div className="">
            <BiStats size={24} />
          </div>
          <span className=" w-max sm:absolute left-[72px] sm:scale-0 rounded-lg sm:bg-[#F9D25F] sm:text-[#191B2A] sm:font-semibold px-2 py-1  group-hover:scale-100 duration-300 delay-200 ">
            Quick Stats
          </span>
        </Link>

        {/* Matches */}
        {/* <Link
          to={`matches/${slug}`}
          className={`flex items-center gap-2 sm:gap-4 group relative sm:p-3 py-1 px-2 ${
            currentTab === "matches"
              ? "bg-[#F9D25F] text-[#191B2A]"
              : "bg-[#ffffff0b]"
          }  rounded-lg sm:rounded-full  hover:scale-90 duration-300`}
          onClick={() => handleClickedTab("matches")}
        >
          <div className="">
            <BsCalendar4Event size={24} />
          </div>
          <span className=" w-max sm:absolute left-[72px] sm:scale-0 rounded-lg sm:bg-[#F9D25F] sm:text-[#191B2A] sm:font-semibold px-2 py-1  group-hover:scale-100 duration-300 delay-200 ">
            Matches
          </span>
        </Link> */}
      </div>
    </div>
  );
};

export default SideBar;
