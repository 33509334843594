import React from 'react'
import Header from '../components/Header'
import TournamentDetails from './TournamentDetails/TournamentDetails'
import TournamentList from './TournamentList'
import { Route, Routes } from 'react-router-dom'

const Index = () => {
  return (
    <div className='h-screen grid grid-rows-[auto_1fr_auto] bg-[#2C2A36] '>
        <Header/>
          <Routes>
        <Route path="/tournaments" element={<TournamentList />}/>
        <Route path="/tournaments/*" element={<TournamentDetails />}/>
      </Routes>
    </div>
  )
}

export default Index