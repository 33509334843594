import ThemedSelect from "components/Select";
import useGetQuery from "hooks/useGetQuery";
import { useLeaderboardStore } from "stores/useLeaderboardStore";
import React, { useEffect } from "react";

export default function FilterSection() {
  const {
    update_value,
    update_select_options,
    game_options,
    year_options,
    leaderboard_list_url,
  } = useLeaderboardStore((state) => state);

  function selectGame(game) {
    update_value(game?.value, "", "current_game");
  }

  function selectYear(year) {
    update_value(year?.value, "All-Time", "current_year");
  }

  const [data, isLoading, error] = useGetQuery(
    `${leaderboard_list_url}?func=get_leaderboard`
  );

  useEffect(() => {
    update_select_options(data?.data?.game_options, "game_options");
    update_select_options(data?.data?.year_options, "year_options");
  }, [data, update_select_options]);

  if (isLoading) {
    return (
      <section className="flex gap-4">
        <div className="w-[200px] rounded h-[40px] animate-pulse bg-[#ffffff40]"></div>
        <div className="w-[200px] rounded h-[40px] animate-pulse bg-[#ffffff40]"></div>
      </section>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <section className="flex gap-4 flex-wrap justify-center sm:justify-start ">
      <ThemedSelect
        options={game_options}
        placeholder={"Select game"}
        defaultValue={"r6"}
        className="min-w-[200px]"
        onChange={selectGame}
      />
      <ThemedSelect
        options={year_options}
        placeholder={"Select year"}
        defaultValue={"2024"}
        className="min-w-[200px]"
        onChange={selectYear}
      />
    </section>
  );
}
