import { createSlice } from "@reduxjs/toolkit";

const initialState = {

  selectedPlayers:[ ],
  selectedRole: {},
  disabledPlayers: {},
  numberOfDisabledPlayers:0,

};

export const CreateTeamSlice = createSlice({
  name: "create_team",
  initialState,
  reducers: {
    setSelectedRole: (state, action) => {
        state.selectedRole = action.payload;
    },
    setSelectedPlayer: (state, action) => { 
        
        let playerDetails = action.payload
      state.selectedPlayers.push(playerDetails) 
    },
    removeSelectedPlayer: (state, action) => { 
        
        let position = action.payload
      state.selectedPlayers.splice(position,1)
    },
    replaceSelectedPlayer: (state, action) => { 
        
        let [position,player] = action.payload
      state.selectedPlayers.splice(position,1, player)
    },
    setDisabledPlayer: (state, action) => {
      const disabledPlayers = action.payload;
      state.numberOfDisabledPlayers = Object.keys(disabledPlayers).length
      state.disabledPlayers = disabledPlayers
    },
      reset: (state) => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedRole, setSelectedPlayer, setDisabledPlayer,removeSelectedPlayer, reset, replaceSelectedPlayer } = CreateTeamSlice.actions;

export default CreateTeamSlice.reducer;
