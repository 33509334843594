import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { ClipboardCopy } from "./tableView/components/ClipboardCopy";
import { disablePlayers } from "./tableView/components/DisablePlayers";
import {
  replaceSelectedPlayer,
  setSelectedRole,
  removeSelectedPlayer,
  setDisabledPlayer,
  setDisabledTeams,
  setSelectedTeam,
} from "data/r6/CreateTeamSlice";
import { formatToNumber, getPosition } from "lib/utils";
import useGetQuery from "hooks/useGetQuery";
import { disableTeams } from "./tableView/components/DisableTeams";

const TeamBuilder = ({ slug, playerData, teamsData }) => {
  slug = slug.split("-")[0];

  const tournamentDetailsUrl =
    // "https://script.google.com/macros/s/AKfycbzY569W8oQIKCshfCGEFYZxSIClwlWkIQmF1bLpxVeuEFiA-TP6hjJYyRVItjBVXar9/exec";
    "https://script.google.com/macros/s/AKfycbxkKT45K8fvJpLVuQqJ6Wbchteg7b8Birpoygidl7HTlC5hGPXLxYTvfCa6QwRne1U4/exec";

  const [data] = useGetQuery(`${tournamentDetailsUrl}?id=${slug}`);

  const [tournamentDetails, setTournamentDetails] = useState({});
  useEffect(() => {
    if (data) {
      tournamentDetailsData();
    }
  }, [data]);

  const tournamentDetailsData = () => {
    let tournamentData = data.data[0];
    setTournamentDetails({ ...tournamentData });
  };

  const dispatch = useDispatch();

  const {
    selectedPlayers,
    numberOfDisabledPlayers,
    selectedTeam,
    selectedMap,
  } = useSelector((state) => state.create_team_r6);

  const [generatedText, setGeneratedText] = useState(
    "Copy the text generated here."
  );
  const [showError, setShowError] = useState({ hasError: false, messages: [] });
  const [isOpen, setIsOpen] = useState(false);

  const removeTeam = () => {
    dispatch(setSelectedTeam({}));
    const { disabledTeams } = disableTeams({}, selectedPlayers, []);
    dispatch(setDisabledTeams(disabledTeams));
  };

  const removePlayer = (player) => {
    let newSelectedPlayers = selectedPlayers.filter(
      (item) => item?.pro_player_id !== player?.pro_player_id
    );

    dispatch(removeSelectedPlayer(getPosition(player, selectedPlayers)));

    const { disabledPlayers } = disablePlayers(
      newSelectedPlayers,
      selectedTeam,
      playerData
    );

    dispatch(setDisabledPlayer(disabledPlayers));
  };

  const generateTeam = () => {
    let players = [...selectedPlayers];
    players.sort((a, b) => a.position.localeCompare(b.position));

    setShowError({ hasError: false, messages: [] });
    let text = `R6 Siege 
    Tournament Id---${slug}
    Tournament Name---${tournamentDetails.tournament_name}
    ----------
    Team--Price
    ----------
    ${selectedTeam.team_name}--${selectedTeam.price} 
    ----------
    Map--Price
    ----------
    ${selectedMap.map_name}--${0} 
    ----------
    Position--Pro_Player--Role--Price
    ----------
    Pro 1--${players[1].pro_player}--${players[1].price}
    Pro 2--${players[2].pro_player}--${players[2].price}
    Pro 3--${players[3].pro_player}--${players[3].price}
    Entry--${players[0].pro_player}--${players[0].price}
    Support--${players[4].pro_player}--${players[4].price}
    ----------`;

    setGeneratedText(text);
  };

  return (
    <>
      <div className="bg-[#000000F3] lg:bg-[#191B2A] rounded lg:rounded-xl px-3 pb-3 sm:p-4 primary-font shadow-lg space-y-2   lg:w-auto lg:h-[80vh] bottom-0 sticky lg:top-0 mx-2 mt-4 lg:m-0 overflow-x-auto overflow-y-visible ">
        <button
          className=" lg:text-xl flex justify-between items-center border-b-2 border-[#674ea7E6] py-2 w-full"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div>
            <span>Team Builder </span> -{" "}
            <span className=" text-[#f9d25f] ">{selectedPlayers.length}</span>
          </div>
          <span
            onClick={() => setIsOpen(!isOpen)}
            className=" text-[#f9d25f] rounded-full p-2 bg-[#674ea780] flex lg:hidden "
          >
            {isOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}
          </span>
        </button>
        <div
          className={`lg:flex flex-col duration-300 items-start lg:h-auto overflow-auto space-y-4 ${
            isOpen ? " h-[60vh] " : " h-[0vh] "
          }  text-sm lg:text-base`}
        >
          <div className="flex justify-between w-full lg:flex-wrap flex-wrap-reverse gap-2 border-b-2 border-[#674ea780] p-2">
            <p>
              No. Valid players :{" "}
              <span className="text-[#f9d25f]">
                {playerData.length - numberOfDisabledPlayers}
              </span>
            </p>
            <p>
              Budget Left :{" "}
              <span className="text-[#f9d25f]">
                $
                {120 -
                  selectedPlayers.reduce((total, current) => {
                    return total + +formatToNumber(current.price, 0);
                  }, 0) -
                  +formatToNumber(selectedTeam.price, 0)}
              </span>
            </p>
          </div>
          <div className="flex flex-col gap-3 w-full overflow-x-auto lg:overflow-visible">
            <table className="w-full">
              <thead>
                <tr>
                  <th>Player</th>
                  <th>Position</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {selectedPlayers
                  .map((a) => a)
                  .sort((a, b) => a.index - b.index)
                  .map((player, index) => (
                    <tr className="text-center" key={index}>
                      <td>
                        <div className="flex gap-4 items-center">
                          <img
                            src={`${player?.team_logo_url}`}
                            alt={`${player?.team_name}`}
                            width={24}
                            className="hidden md:table-cell"
                          />
                          <p>
                            {player?.pro_player}
                            {player?.position === "captain" ? " 👑" : ""}
                          </p>
                        </div>
                      </td>
                      <td>{player?.position}</td>
                      <td>${player.price}</td>
                      <td className="flex justify-center ">
                        <button onClick={() => removePlayer(player)}>
                          <IoMdCloseCircleOutline
                            className="text-[#F25F4C] cursor-pointer"
                            size={24}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <table className="w-full">
              <thead>
                <tr>
                  <th>Team</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td>
                    <div className="flex gap-4 items-center">
                      <img
                        src={`${selectedTeam?.team_logo_url}`}
                        // alt={`${selectedTeam?.team_name}`}
                        width={24}
                        className="hidden md:table-cell"
                      />
                      <p>{selectedTeam?.team_name}</p>
                    </div>
                  </td>
                  <td>${selectedTeam.price}</td>
                  <td className="flex? justify-center ">
                    <button onClick={() => removeTeam()}>
                      <IoMdCloseCircleOutline
                        className="text-[#F25F4C] cursor-pointer"
                        size={24}
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="text-[#f9d25f] animate-pulse">
              <b>Don't Forget to Select a Map!!!</b>
            </p>
            <table className="w-full">
              <thead>
                <tr>
                  <th>Map</th>
                  <th>Price</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td>
                    <div className="flex gap-4 items-center">
                      <img
                        src={`${selectedMap?.image}`}
                        // alt={`${selectedMap?.map_name}`}
                        width={24}
                        className="hidden md:table-cell"
                      />
                      <p>{selectedMap?.map_name}</p>
                    </div>
                  </td>
                  <td>${selectedMap?.price}</td>
                  <td className="flex? justify-center ">
                    <button onClick={() => removeTeam()}>
                      <IoMdCloseCircleOutline
                        className="text-[#F25F4C] cursor-pointer"
                        size={24}
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="w-full">
            <a href="#copy">
              <button
                className={`bg-[#674ea7] px-4 py-2 rounded-md text-center w-full  ${
                  selectedPlayers.length < 5 ||
                  Object.keys(selectedTeam).length === 0
                    ? "opacity-50"
                    : ""
                }`}
                disabled={
                  selectedPlayers.length < 5 ||
                  Object.keys(selectedTeam).length === 0
                }
                onClick={generateTeam}
              >
                Create Team
              </button>
            </a>
          </div>
          <div className=" flex flex-col  w-full gap-2 text-sm secondary-font md:text-base">
            {showError.hasError ? (
              <div className="w-full h-[300px] bg-transparent border-solid border-2 bg-red-500 border-[#f9d25f80] rounded-md p-2 overflow-auto">
                Error: Issue(s) with your team.
                {showError.messages?.map((message) => (
                  <p>--- You may have more than 2 {message}</p>
                ))}
                <p>or</p>
                <p>
                  --- You have another player with the same role as your captain
                </p>
              </div>
            ) : (
              <pre className="w-full min-h-[100px] h-fit bg-transparent border-solid border-2 border-[#f9d25f80] rounded-md p-2 overflow-auto">
                {generatedText}
              </pre>
            )}

            <ClipboardCopy
              copyText={generatedText}
              players={selectedPlayers}
              hasError={showError.hasError}
              team={selectedTeam}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamBuilder;
