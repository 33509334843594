import React from "react";
import { Link } from "react-router-dom";
import logo from "assets/Infamy_Logo_Crown_Logo.png";
import { AiFillCaretDown } from "react-icons/ai";
import { SiDiscord } from "react-icons/si";
import { ReactComponent as OverwatchLogo } from "assets/Overwatch_logo.svg";
import { ReactComponent as R6Logo } from "assets/R6_logo.svg";
import { ReactComponent as ValorantLogo } from "assets/valorant_logo.svg";
import { useState } from "react";
import {
  Dialog,
  DialogPanel,
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/react";
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PhoneIcon,
  PlayCircleIcon,
} from "@heroicons/react/20/solid";

// const Header = () => {
//   return (
//     <header className="py-6 px-[5%] h-8 primary-font  flex items-center justify-between border-b-[1px] border-b-solid border-[#f9d25f] sticky top-0 w-full bg-[#191B2A] z-50">
//       <Link className="flex items-center" to="/">
//         <img src={logo} alt="" className="w-[50px]" />
//         <h1 className="hidden lg:flex uppercase lg:text-2xl xl:text-3xl font-bold text-[#F9D25F]">
//           Infamy
//         </h1>
//       </Link>
//       <div className="flex gap-4 items-center secondary-font text-sm sm:text-base text-[#FFFFFF] relative">
//         <Link
//           to={"/leaderboard"}
//           className="hover:text-[#F9D25F] duration-300 "
//         >
//           Leaderboard
//         </Link>
//         <div className="group  delay-300">
//           <div className="flex gap-2 items-center cursor-pointer hover:text-[#F9D25F] duration-300  ">
//             <p>Games</p>
//             <AiFillCaretDown />
//           </div>
//           <div className=" hidden group-hover:flex flex-col py-1 bg-[#F9D25F] absolute top-6 rounded ">
//             <Link
//               to={"/valorant/tournaments"}
//               className="hover:bg-[#674EA7] text-black hover:text-white py-2 px-4"
//             >
//               Valorant
//             </Link>
//             <Link
//               to={"/r6-siege/tournaments"}
//               className="hover:bg-[#674EA7] text-black hover:text-white  py-2 px-4"
//             >
//               Siege
//             </Link>
//             <Link
//               to={"/overwatch/tournaments"}
//               className="hover:bg-[#674EA7] text-black hover:text-white  py-2 px-4"
//             >
//               Overwatch
//             </Link>
//           </div>
//         </div>

//         <Link to={"/about"} className="hover:text-[#F9D25F] duration-300 ">
//           About Us
//         </Link>
//         <a
//           href="https://discord.gg/pYxKpat"
//           className="hover:text-[#F9D25F]  duration-300"
//           target="_blank"
//           rel="noreferrer"
//         >
//           <SiDiscord size={24} />
//         </a>
//       </div>
//     </header>
//   );
// };

// export default Header;

const games = [
  {
    name: "Valorant",
    description:
      "A 5v5 character-based tactical FPS where precise gunplay meets unique agent abilities.",
    href: "/valorant/tournaments",
    icon: ValorantLogo,
  },
  {
    name: "R6 SIEGE",
    description:
      "A high-precision, tactical shooter that prioritizes careful planning teamwork and finely tuned tactical play.",
    href: "/r6-siege/tournaments",
    icon: R6Logo,
  },
  {
    name: "Overwatch",
    description:
      "A colorful team-based action game starring a diverse cast of powerful heroes.",
    href: "/overwatch/tournaments",
    icon: OverwatchLogo,
  },
];

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <header className="primary-font bg-[#191B2A] border-b-[1px] border-b-solid border-[#f9d25f] sticky top-0 z-50">
      <nav
        aria-label="Global"
        className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8"
      >
        <div className="flex lg:flex-1">
          <Link className="flex items-center" to="/">
            <img src={logo} alt="" className="w-[50px]" />
            <h1 className="hidden lg:flex uppercase lg:text-2xl xl:text-3xl font-bold text-[#F9D25F]">
              Infamy
            </h1>
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            onClick={() => setMobileMenuOpen(true)}
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-300"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="h-6 w-6" />
          </button>
        </div>
        <PopoverGroup className="hidden lg:flex lg:gap-x-12 text-gray-200">
          <Popover className="relative">
            <PopoverButton className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-200">
              Game
              <ChevronDownIcon
                aria-hidden="true"
                className="h-5 w-5 flex-none text-gray-400"
              />
            </PopoverButton>

            <PopoverPanel
              transition
              className="secondary-font absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-[#191B2A] shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="p-4 ">
                {games.map((item) => (
                  <div
                    key={item.name}
                    className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-[#ffffff10] duration-300"
                  >
                    <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-[#191B2A] ">
                      <item.icon
                        aria-hidden="true"
                        className="h-6 w-6 bg-transparent"
                      />
                    </div>
                    <div className="flex-auto">
                      <Link
                        to={item.href}
                        className="block font-semibold text-gray-200"
                      >
                        {item.name}
                        <span className="absolute inset-0" />
                      </Link>

                      <p className="mt-1 text-gray-400">{item.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </PopoverPanel>
          </Popover>

          <Link
            to={"/leaderboard"}
            className="hover:text-[#F9D25F] duration-300 "
          >
            Leaderboard
          </Link>
          {/* <Link
      to={"/leaderboard"}
      className="hover:text-[#F9D25F] duration-300 "
    >
      Wrapped
    </Link> */}
          <Link to={"/about"} className="hover:text-[#F9D25F] duration-300 ">
            About Us
          </Link>
        </PopoverGroup>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end text-gray-200">
          <a
            href="https://discord.gg/pYxKpat"
            className="hover:text-[#F9D25F]  duration-300"
            target="_blank"
            rel="noreferrer"
          >
            <SiDiscord size={24} />
          </a>
        </div>
      </nav>
      <Dialog
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
        className="lg:hidden"
      >
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="primary-font fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-[#191B2A] px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                alt=""
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                className="h-8 w-auto"
              />
            </a>
            <button
              type="button"
              onClick={() => setMobileMenuOpen(false)}
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="h-6 w-6" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-200 hover:bg-[#ffffff10]">
                    Games
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-none group-data-[open]:rotate-180"
                    />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2">
                    {[...games].map((item) => (
                      <DisclosureButton
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-200 hover:bg-gray-50"
                      >
                        {item.name}
                      </DisclosureButton>
                    ))}
                  </DisclosurePanel>
                </Disclosure>
                <Link
                  to={"/leaderboard"}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-200 hover:bg-gray-50"
                >
                  Leaderboard
                </Link>
                {/* <Link
      to={"/leaderboard"}
      className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-200 hover:bg-gray-50"
      >
      Wrapped
      </Link> */}
                <Link
                  to={"/about"}
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-200 hover:bg-gray-50"
                >
                  About Us
                </Link>
              </div>
              <div className="py-6">
                <a
                  href="https://discord.gg/pYxKpat"
                  // className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-200 hover:bg-gray-50"
                  className="hover:text-[#F9D25F] text-white  duration-300"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SiDiscord size={24} />
                </a>
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  );
}
