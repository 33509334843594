import useFetch from "hooks/useFetch";
import React, { useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";
import { ErrorBoundary } from "react-error-boundary";
import PlayerDetails from "./PlayerDetails";
import useGetQuery from "hooks/useGetQuery";
import { orderData } from "lib/utils";
import { formatToNumber, formatToPercentage } from "lib/utils";

const LeaderBoardTable = ({ tournamentSlug }) => {
  const leaderBoardListUrl =
"https://script.google.com/macros/s/AKfycbxu610JWV_KR09ECoTf9oYm0w0Wcr6R4NxVW4SjCgmbRC-bgEYL7ENfAeq49LvyRVY2/exec"

  const [data, isLoading, error] = useGetQuery(
    `${leaderBoardListUrl}?id=${tournamentSlug}`
  );

  const [leaderBoard, setLeaderBoard] = useState([]);
  const [playerDetails, setPlayerDetails] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  useEffect(() => {
    if (data) {
      leaderBoardList();
    }
  }, [data]);

  const showMoreDetails = (player) => {
    let newShowDetails = { ...player };
    setPlayerDetails(newShowDetails);
    setShowDetails(true);
  };

  const leaderBoardList = () => {
    let leaderBoardData = data.data;
    const orderedData = orderData(leaderBoardData, "infamy_score", false);
    setLeaderBoard([...orderedData]);
  };

  function isPaidMembers(e){
    if(e.target.value === "Paid Members"){
      let leaderBoardData = data.data;
      let filteredData =  leaderBoardData.filter((d) => d?.emojis !== "")
      const orderedData = orderData(filteredData, "infamy_score", false);
      setLeaderBoard([...orderedData]);
    }else{
      let leaderBoardData = data.data;
      const orderedData = orderData(leaderBoardData, "infamy_score", false);
      setLeaderBoard([...orderedData]);
    }
  }
  
  if (isLoading) {
    return (
      <div className="bg-[#2C2A36] flex justify-center items-center">
        <ScaleLoader color="#F9D25F" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-screen bg-[#2C2A36] flex justify-center items-center">
        Error: {error.message}
      </div>
    );
  }
  return (
    <>
      {/* Details */}
      {showDetails ? (
        <ErrorBoundary
          fallback={<div>Error:Player Details Failed to Load</div>}
        >
          <PlayerDetails
            playerDetails={playerDetails}
            callBack={setShowDetails}
          />
        </ErrorBoundary>
      ) : (
        <></>
      )}
      <table className="text-left  secondary-font font-normal ">
        <thead>
          <tr className="">
            <th className=" font-normal text-center ">Rank</th>
            <th className=" font-normal flex gap-1">
              
              <select name="members" id="" className="bg-[#191B2A] outline-transparent" onChange={(e) => isPaidMembers(e)}>
                <option value="Discord Members" className="">Discord Usernames</option>
                <option value="Paid Members">Members Only</option>
              </select>

              <span>({leaderBoard.length})</span>
            </th>
            <th className=" font-normal">Picks Left Left</th>
            <th className="hidden lg:table-cell font-normal">Damage 1</th>
            <th className="hidden lg:table-cell font-normal">Damage 2</th>
            <th className="hidden lg:table-cell font-normal">Support 1</th>
            <th className="hidden lg:table-cell font-normal">Support 2</th>
            <th className="hidden lg:table-cell font-normal">Tank</th>
            <th className="bg-[#ffffff20] text-center font-normal">
              Infamy Score
            </th>
            <th className="hidden xl:table-cell text-center font-normal">
              Percentile
            </th>
          </tr>
        </thead>

        {/* MAIN TABLE */}
        <tbody>
          {leaderBoard?.map((player, index) => (
            <tr
              key={index}
              onClick={(e) => showMoreDetails(player)}
              className={` cursor-pointer ${
                player.rank === 1
                  ? `bg-[#d4af37] text-[#000000] `
                  : player.rank === 2
                  ? `bg-[#808080] text-[#000000] `
                  : player.rank === 3
                  ? `bg-[#CD7F32] text-[#000000] `
                  : player.rank === leaderBoard.length
                  ? `bg-[#964b0080]`
                  : ``
              }`}
            >
              <td className="text-center">{player?.rank}</td>
              <td className="">{player?.discord_name}</td>
              <td className="text-center">
                {formatToNumber(player.players_left, 0)}
              </td>
              <td className="hidden lg:table-cell">
                <b>{player?.damage_1}</b>
                <p>{formatToNumber(player.damage_1_points, 2)}</p>
              </td> 
              <td className="hidden lg:table-cell">
                <b>{player?.damage_2}</b>
                <p>{formatToNumber(player.damage_2_points, 2)}</p>
              </td>
              <td className="hidden lg:table-cell">
                <b>{player?.support_1}</b>
                <p>{formatToNumber(player.support_1_points, 2)}</p>
              </td>
              <td className="hidden lg:table-cell">
                <b>{player?.support_2}</b>
                <p>{formatToNumber(player.support_2_points, 2)}</p>
              </td>
              <td className="hidden lg:table-cell">
                <b>{player?.tank}</b>
                <p>
                  {" "}
                  {formatToNumber(
                    +player?.tank_points,
                    2
                  )}
                </p>
              </td>
              <td className="bg-[#ffffff30] text-center">
                {formatToNumber(+player?.infamy_score, 2)}
              </td>
              <td className="hidden xl:table-cell text-center">
                {formatToPercentage(+player?.percentile, 2)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default LeaderBoardTable;
