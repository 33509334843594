import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setPlayerName,
  setTeamName,
  setOrderBy,
  setRole,
  reset,
} from "data/overwatch/FilterPerformanceSlice";
import Select from "react-select";

const FilterInput = () => {
  const filterPerformance = useSelector((state) => state.filter_performance_overwatch);
  const dispatch = useDispatch();

  const handlePlayerNameChange = (event) => {
    dispatch(setPlayerName(event.target.value));
  };

  const handleTeamNameChange = (event) => {
    dispatch(setTeamName(event.target.value));
  };

  const handleRoleClick = (role) => {
    role = role.toLocaleLowerCase()
    if (filterPerformance.role.includes(role)) {
      let roleIndex = filterPerformance.role.indexOf(role);
      let roles = [...filterPerformance.role];
      roles.splice(roleIndex, 1);

      dispatch(setRole(roles));
    } else {
      let roles = [...filterPerformance.role, role];
      dispatch(setRole(roles));
    }
  };

  const handleResetClick = () => {
    dispatch(reset());
  };

  const handleSelectedOrderBy = (a) => {
    if (a === null) {
      dispatch(setOrderBy("infamy_score"));
    } else {
      dispatch(setOrderBy(a?.value));
    }
  };

  const viewOptions = [
    { value: "infamy_score", label: "Infamy Score" },
    { value: "pick_rate", label: "Pick Rate" },
    { value: "solo_kills", label: "Solo Kills" },
    { value: "point_value", label: "Point Value" },
    { value: "deaths", label: "Deaths" },
    { value: "final_blows", label: "Final Blows" },
    { value: "price", label: "Price" }
  ];

  return (
    <div className="flex flex-wrap gap-6 items-end text-base">
      <div className="flex flex-col items-start">
        <h1 className=" opacity-75">Player</h1>
        <input
          className=" bg-transparent outline-none border-b-2 border-solid py-2 w-[200px]"
          type="text"
          value={filterPerformance.playerName}
          onChange={handlePlayerNameChange}
          placeholder="Search Player Here"
        />
      </div>
      <div className="flex flex-col items-start">
        <h1 className=" opacity-75">Team</h1>
        <input
          className=" bg-transparent outline-none border-b-2 border-solid py-2 w-[200px]"
          type="text"
          value={filterPerformance.teamName}
          onChange={handleTeamNameChange}
          placeholder="Search Team Here"
        />
      </div>

      <div className="flex flex-col items-start">
        <h1 className=" opacity-75">Roles</h1>
        <div className="bg-transparent border-b-2 pb-2 border-solid flex flex-wrap gap-3">
          <button
            className={`p-1 rounded  duration-100 ${
              filterPerformance.role.includes("tank")
                ? `bg-[#f9d25f] text-[#191b2a] hover:scale-95`
                : `hover:text-[#f9d25f]`
            } `}
            onClick={() => handleRoleClick("tank")}
          >
            Tank
          </button>
          <button
            className={`p-1 rounded  duration-100 ${
              filterPerformance.role.includes("offense")
                ? `bg-[#f9d25f] text-[#191b2a] hover:scale-95`
                : `hover:text-[#f9d25f]`
            } `}
            onClick={() => handleRoleClick("offense")}
          >
            Offense
          </button>
          <button
            className={`p-1 rounded  duration-100 ${
              filterPerformance.role.includes("support")
                ? `bg-[#f9d25f] text-[#191b2a] hover:scale-95`
                : `hover:text-[#f9d25f]`
            } `}
            onClick={() => handleRoleClick("support")}
          >
            Support
          </button>
          
        </div>
      </div>

      <div className="flex flex-col gap-2 items-start">
        <h1 className=" opacity-75">Order By</h1>

        <Select
          options={viewOptions}
          placeholder={"Order By ..."}
          defaultValue={viewOptions[0]}
          isClearable={true}
          onChange={handleSelectedOrderBy}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: "#674EA780", // option hover background color
              primary: "#674EA7", //control border color and selected option background color
              neutral80: "white", //control text color
              neutral0: "#191B2A", // control background color
            },
          })}
        />
      </div>

      <button
        onClick={handleResetClick}
        className="bg-[#674EA750] px-4 py-2 rounded-lg hover:bg-[#674EA7] duration-300"
      >
        Reset
      </button>
    </div>
  );
};

export default FilterInput;
