export function groupPlayersByTeams(data) {
  const uniqueTeamIds = getUniqueTeamIds_(data);

  let filteredTeamData = {
    allTeams: [],
  };

  for (let i = 0; i < uniqueTeamIds.length; i++) {
    const filteredData = new DataFilter(data)
      .filterByTeamId(uniqueTeamIds[i])
      .getData();
    let team = {
      group: filteredData[0].group,
      team_id: filteredData[0].team_id,
      team_name: filteredData[0].team_name,
      team_logo_url: filteredData[0].team_logo_url,
    };
    team[team["team_id"]] = [];

    for (let j = 0; j < filteredData.length; j++) {
      let playerData = {
        pro_player_id: filteredData[j].pro_player_id,
        pro_player: filteredData[j].pro_player,
        pro_player_image_url: filteredData[j].pro_player_image_url,
        role: filteredData[j].role,
        price: filteredData[j].price,
      };

      team[team["team_id"]].push(playerData);
    }

    filteredTeamData.allTeams.push(team);
  }

  return filteredTeamData;
}

function getUniqueTeamIds_(jsonData) {
  const teams = new Set();
  for (const teamData of jsonData) {
    teams.add(teamData.team_code);
  }
  return Array.from(teams);
}

export class DataFilter {
  // Constructor function that initializes the data to filter
  constructor(data) {
    // Performs a deep copy of the data to avoid modifying the original data
    this.data = JSON.parse(JSON.stringify(data));
  }

  // Function to filter the data by player name
  filterByPlayerName(playerName = "") {
    this.data = this.data.filter((item) =>
      item.pro_player.toLowerCase().includes(playerName.toLowerCase())
    );
    // Return the instance of the class to enable method chaining
    return this;
  }

  // Function to filter the data by team name
  filterByTeam(teamName = "") {
    this.data = this.data.filter((item) =>
      item.team_name.toLowerCase().includes(teamName.toLowerCase())
    );
    // Return the instance of the class to enable method chaining
    return this;
  }

  // Function to filter the data by team name
  filterByTeamId(teamId = "") {
    this.data = this.data.filter((item) =>
      item.team_code.toString().includes(teamId)
    );
    // Return the instance of the class to enable method chaining
    return this;
  }

  // Function to filter the data by price range
  filterByPrice(minPrice = -Infinity, maxPrice = Infinity) {
    // If both minPrice and maxPrice are provided, filter data between the range
    if (minPrice !== undefined && maxPrice !== undefined) {
      this.data = this.data.filter(
        (item) => item.price >= minPrice && item.price <= maxPrice
      );
      // If only minPrice is provided, filter data with a price greater than or equal to minPrice
    } else if (minPrice !== undefined) {
      this.data = this.data.filter((item) => item.price >= minPrice);
      // If only maxPrice is provided, filter data with a price less than or equal to maxPrice
    } else if (maxPrice !== undefined) {
      this.data = this.data.filter((item) => item.price <= maxPrice);
    }
    // Return the instance of the class to enable method chaining
    return this;
  }

  // A Function to filter the data by roles
  filterByAllRoles(roles = []) {
    // If roles array is empty, return the instance of the class to enable method chaining
    if (roles.length === 0) {
      return this;
    }

    //Filter the data by roles that are included in the roles array
    this.data = this.data.filter((item) => {
      if (Array.isArray(item.role)) {
        let r = [];
        if (item.role[0].toLowerCase() === "flex") {
          r = ["Initiator", "Sentinel", "Controller", "Duelist"];
        } else {
          r = item.role;
        }
        return r.every((r) => roles.includes(r));
      }
    });

    // Return the instance of the class to enable method chaining
    return this;
  }

  filterByRoles(roles = []) {
    // If roles array is empty, return the instance of the class to enable method chaining
    if (roles.length === 0) {
      return this;
    }

    //Filter the data by roles that are included in the roles array
    this.data = this.data.filter((item) => {
      if (Array.isArray(item.role)) {
        let r = [];
        if (item.role[0].toLowerCase() === "flex") {
          r = ["Initiator", "Sentinel", "Controller", "Duelist"];
        } else {
          r = item.role;
        }
        return r.some((r) => roles.includes(r));
      }
    });

    // Return the instance of the class to enable method chaining
    return this;
  }

  // Function to return the filtered data
  getData() {
    return this.data;
  }
}

// get unique teams names from the data and format for dropdown button
export function formatForDropdown(data = []) {
  let teams = getUniqueTeams_(data);

  let options = teams.map((team) => {
    return {
      value: team,
      label: team,
    };
  });

  return options;
}

function getUniqueTeams_(jsonData) {
  const teams = new Set();
  for (const teamData of jsonData) {
    teams.add(teamData.team_name);
  }
  return Array.from(teams);
}

export function generatePlayerViewChartData(data) {
  if (!data) return;

  return {
    labels: data?.map((d) => d.pro_player),
    datasets: [
      {
        label: "Players Infamy Score",
        data: data?.map((d) => d.infamy_score),
        backgroundColor: "rgba(0, 71, 62, 5)",
        borderColor: "rgba(0, 71, 62, 0.8)",
      },
    ],
  };
}

export function generateTeamViewChartData(data) {
  if (!data) return;

  data = aggregateTeamScore_(data, "infamy_score");

  return {
    labels: data?.map((d) => d.team_name),
    datasets: [
      {
        label: "Teams Infamy Score",
        data: data?.map((d) => d.infamy_score),
        backgroundColor: "rgba(0, 71, 62, 5)",
        borderColor: "rgba(0, 71, 62, 0.8)",
      },
    ],
  };
}

function aggregateTeamScore_(data, pointName) {
  const uniqueTeamIds = getUniqueTeamIds_(data);
  let aggregatedTeamData = [];

  for (let i = 0; i < uniqueTeamIds.length; i++) {
    const filteredData = new DataFilter(data)
      .filterByTeamId(uniqueTeamIds[i])
      .getData();

    let numbers = filteredData?.map((d) => d[pointName]);
    const sum = numbers.reduce(
      (accumulator, currentValue) => +accumulator + currentValue,
      0
    );

    let obj = {};
    obj["team_name"] = filteredData[0]?.team_name;
    obj[pointName] = sum;
    aggregatedTeamData[i] = obj;
  }

  return aggregatedTeamData;
}

export function generateKDAPlayerViewChartData(data) {
  if (!data) return;

  return {
    labels: data?.map((d) => d.pro_player),
    datasets: [
      {
        label: "1st Kill",
        data: data?.map((d) => d["1st_kill"]),
        backgroundColor: "rgba(147, 196, 125, 1)",
      },
      {
        label: "1st Death",
        data: data?.map((d) => d["1st_death"]),
        backgroundColor: "rgba(147, 196, 125, 1)",
      },
      {
        label: "Deaths",
        data: data?.map((d) => d.deaths),
        backgroundColor: "rgba(204,65,37, 1)",
      },
      {
        label: "Kills",
        data: data?.map((d) => d.kills),
        backgroundColor: "rgba(255,217,102, 1)",
      },
    ],
  };
}

export function generateKDATeamViewChartData(data) {
  if (!data) return;

  let kill1stData = aggregateTeamScore_(data, "1st_kill");
  let death1stData = aggregateTeamScore_(data, "1st_death");
  let deathsData = aggregateTeamScore_(data, "deaths");
  let killsData = aggregateTeamScore_(data, "kills");

  return {
    labels: killsData?.map((d) => d.team_name),
    datasets: [
      {
        label: "1st Kill",
        data: kill1stData?.map((d) => d["1st_kill"]),
        backgroundColor: "rgba(100, 100, 100, 1)",
      },
      {
        label: "1st Death",
        data: death1stData?.map((d) => d["1st_death"]),
        backgroundColor: "rgba(147, 196, 125, 1)",
      },
      {
        label: "Deaths",
        data: deathsData?.map((d) => d.deaths),
        backgroundColor: "rgba(204,65,37, 1)",
      },
      {
        label: "Kills",
        data: killsData?.map((d) => d.kills),
        backgroundColor: "rgba(255,217,102, 1)",
      },
    ],
  };
}

export function orderData(data = [], orderBy = "", ascending = true) {
  return ascending
    ? data?.sort((a, b) => a[orderBy] - b[orderBy])
    : data?.sort((a, b) => b[orderBy] - a[orderBy]);
}

export function formatToNumber(number = 0, decimals = 0) {
  if (isNaN(number)) {
    number = 0;
  }

  return new Intl.NumberFormat(undefined, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

export function formatToPercentage(number = 0, decimals = 2) {
  if (isNaN(number)) {
    number = 0;
  }

  return new Intl.NumberFormat(undefined, {
    style: "percent",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

export function formatToCurrency(number = 0, currency = "USD") {
  if (isNaN(number)) {
    number = 0;
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(number);
}

export function addToDisabledPlayers(players = []) {
  let obj = {};
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    obj[player.pro_player_id] = player.pro_player;
  }
  return obj;
}

export function addToDisabledTeams(teams = []) {
  let obj = {};
  for (let i = 0; i < teams.length; i++) {
    let team = teams[i];
    obj[team.team_code] = team.team_name;
  }

  return obj;
}

export function addToDisabledMaps(maps = []) {
  let obj = {};
  for (let i = 0; i < maps.length; i++) {
    let map = maps[i];
    obj[map.map_id] = map.map_name;
  }
  return obj;
}


export function getSalaryLeft(players = []) {
  let salary = 120;
  return (
    salary -
    players.reduce((total, current) => {
      return total + +formatToNumber(current.price, 0);
    }, 0)
  );
}

export function getTeam(players = []) {
  let obj = {};

  for (let i = 0; i < players.length; i++) {
    let player = players[i].team_name;
    if (!obj[player]) {
      obj[player] = 1;
      continue;
    }
    obj[player] += 1;
    if (obj[player] >= 3) {
      return player;
    }
  }

  return "-----";
}
export function getRoles(players = []) {
  let obj = {
    tank: 1,
    support: 2,
    offense: 2,
  };
  let arr = ["none"];
  for (let i = 0; i < players.length; i++) {
    let player = players[i];
    if (player.position === "captain") {
      obj[player.selectedRole] -= 2;
    } else {
      obj[player.selectedRole] -= 1;
    }

    if (obj[player.selectedRole] <= 0) {
      arr.push(player.selectedRole);
    }
  }

  return [arr, obj];
}

export function isSelected(player = {}, selectedPlayers = []) {
  for (let i = 0; i < selectedPlayers.length; i++) {
    let selectedPlayer = selectedPlayers[i];

    if (player.pro_player_id === selectedPlayer.pro_player_id) {
      return true;
    }
  }

  return false;
}

export function isSelectedTeam(team = {}, selectedTeam = {}) {
  return team.team_code === selectedTeam.team_code;
}

export function isSelectedMap(team = {}, selectedTeam = {}) {
  return team.map_id === selectedTeam.map_id;
}

export function getPosition(player = {}, selectedPlayers = []) {
  for (let i = 0; i < selectedPlayers.length; i++) {
    let selectedPlayer = selectedPlayers[i];

    if (player.pro_player_id === selectedPlayer.pro_player_id) {
      return i;
    }
  }

  return -1;
}
