import React from "react";
import Teams from "./Teams";
import ProPlayers from "./ProPlayers";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Maps from "./Maps";

function TableView({ playerData, slug }) {
  const location = useLocation();
  const { pathname } = location;
  let currentTab = pathname.split("/")[5];

  return (
    <>
      <div className="flex gap-4 py-2 border-b">
        <Link
          to={`pro-players`}
          className={`${
            currentTab === "pro-players" || !currentTab
              ? ` py-1 border-b border-[#f9d25f] text-[#f9d25f]`
              : `hover:text-[#f9d25f]   py-1`
          } `}
        >
          Players
        </Link>

        <Link
          to={"teams"}
          className={`${
            currentTab === "teams"
              ? ` py-1 border-b border-[#f9d25f] text-[#f9d25f]`
              : `hover:text-[#f9d25f]   py-1`
          } `}
        >
          Teams
        </Link>

        <Link
          to={"maps"}
          className={`${
            currentTab === "maps"
              ? ` py-1 border-b border-[#f9d25f] text-[#f9d25f]`
              : ` hover:text-[#f9d25f]   py-1`
          } `}
        >
          Maps
        </Link>
      </div>
      <>
        <Routes>
          <Route path="/" element={<ProPlayers playerData={playerData} />} />
          <Route
            path={`pro-players`}
            element={<ProPlayers playerData={playerData} />}
          />
          <Route path={`teams`} element={<Teams slug={slug} />} />
          <Route path={`maps`} element={<Maps slug={slug} />} />
        </Routes>
      </>
    </>
  );
}

export default TableView;
