import React from "react";

import { formatToCurrency } from "lib/utils";

const PlayerSide = ({ playersData }) => {
  if (!playersData) {
    return "Infamy";
  }
  return (
    <div className="p-6 sm:p-10 bg-gradient-to-b sm:bg-gradient-to-r from-[#674ea741] to-[#191b2a] flex gap-10 items-center w-full overflow-x-auto ">
      {playersData?.map((player, index) => (
        <div
          key={player.pro_player_id}
          className=" bg-[#191b2a]  rounded-xl border-2 border-solid border-[#AF9DDD80] "
        >
          <div className="bg-gradient-to-t rounded-t-xl  from-[#674EA7] to-[#000000] w-[250px] flex items-end pt-6">
            <img
              src={`${player?.pro_player_image_url}`}
              alt={`${player?.pro_player}`}
              width={250}
            />
          </div>
          <div className=" h-1 w-full bg-gradient-to-r  from-[#674EA7] to-[#FA2328] "></div>
          <div className="flex p-4 items-center gap-4 bg-gradient-to-r  from-[#0000000a] to-[#674ea747] relative overflow-hidden z-10">
            <h1 className="absolute bottom-0 -right-[50%] text-6xl opacity-5">
              INFAMY
            </h1>
            <div className="flex flex-col gap-2">
              <p
                className="font-medium text-xl"
                title={`${player?.pro_player}`}
              >
                {" "}
                Name:{" "}
                {player?.pro_player.length < 9
                  ? player?.pro_player
                  : player?.pro_player.slice(0, 8) + "..."}
              </p>

              <p className="text-[#f9d25f] secondary-font font-medium text-lg">
                Price: {formatToCurrency(player.price, "USD")}
              </p>
              <h1 className=" flex gap-2 ">
                Role:{" "}
                {player?.role?.map((r) => (
                  <span key={r}>{r}</span>
                ))}
              </h1>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PlayerSide;
