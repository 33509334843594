import { isSelected, formatToNumber } from "lib/utils";
import { useSelector } from "react-redux";
import SelectButton from "./components/SelectButton";
import TableSkeleton from "components/skeletons/Table";

const ProPlayers = ({ statsError, statsLoading, playerData }) => {
  const { selectedPlayers, disabledPlayers } = useSelector(
    (state) => state.create_team_r6
  );

  if (statsLoading) {
    return <TableSkeleton header={["lol", "Team", "Name", "Price", "Add"]} />;
  }

  if (statsError) {
    return (
      <div className=" bg-[#2C2A36] flex justify-center items-center">
        Error: {statsError.message}
      </div>
    );
  }
  return (
    <div className="relative overflow-auto whitespace-nowrap ">
      <table className=" text-left w-full ">
        <thead>
          <tr>
            <th>Group</th>
            <th>Team</th>
            <th>Name</th>
            <th>Price</th>
            <th className=" sticky bg-[#ffffff0d] right-0   text-center ">
              Add
            </th>
          </tr>
        </thead>
        <tbody>
          {playerData?.map((player, index) => (
            <tr
              key={player.pro_player_id}
              className={`${
                isSelected(player, selectedPlayers)
                  ? ""
                  : disabledPlayers[player.pro_player_id]
                  ? " bg-gray-700 opacity-40"
                  : ""
              } relative`}
            >
              <td>{player?.group}</td>
              <td
                className={`${
                  isSelected(player, selectedPlayers)
                    ? " bg-[#191b2a] opacity-20"
                    : ""
                }`}
              >
                <div className="flex gap-4 items-center">
                  <img src={`${player?.team_logo_url}`} alt={``} width={24} />
                  <p className="hidden sm:flex">{player.team_name}</p>
                </div>
              </td>
              <td
                className={`${
                  isSelected(player, selectedPlayers)
                    ? " bg-[#191b2a] opacity-20"
                    : ""
                }`}
              >
                <div className="flex gap-4 items-center">
                  <img
                    src={`${player?.pro_player_image_url}`}
                    alt={`${player?.pro_player}`}
                    width={24}
                    className="hidden xl:table-cell"
                  />

                  <p className="" title={`${player?.pro_player}`}>
                    {player?.pro_player}
                  </p>
                </div>
              </td>
              <td
                className={`${
                  isSelected(player, selectedPlayers)
                    ? " bg-[#191b2a] opacity-20"
                    : ""
                }`}
              >
                <p className="text-[#f9d25f] secondary-font font-medium text-lg">
                  ${formatToNumber(player.price, 0)}
                </p>
              </td>

              <td className="flex justify-center sticky bg-[#ffffff0d] right-0 ">
                <SelectButton playerDetails={player} playerData={playerData} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ProPlayers;
