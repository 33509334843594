import InfamyBarChart from "components/InfamyBarChart";
import React, { useEffect, useState } from "react";
import { generatePlayerViewChartData, generateTeamViewChartData, DataFilter } from "pages/Games/siege_r6/lib/utils";

const InfamyScoreChart = ({
  statsLoading,
  statsError,
  infamyScoreChartData,
  viewType,
  team,
}) => {

  const [data, setData] = useState({});
  const [axisData, setAxisData] = useState({});

  useEffect(() => {

    const filteredData = new DataFilter(infamyScoreChartData)
    .filterByTeam(team)
    .getData();

    if (viewType === "viewByPlayers") {
      // create chart for viewByPlayer
      let chartData = generatePlayerViewChartData(filteredData);
      console.log(chartData);
      setData(chartData);
      
      setAxisData(
        {
          x:"Players",
          y:"Points",
        }
        )
      } else {
        // create chart for viewByTeam
        let chartData = generateTeamViewChartData(filteredData);
        setData(chartData);

      setAxisData(
        {
          x:"Teams",
          y:"Points",
        }
      )
    }
  }, [viewType,team,infamyScoreChartData]);

  if (statsLoading) {
    return (
      <div className="m-2 h-[50px] bg-gradient-to-r from-[#f9d25f80] to-[#191b2a00] p-2 border-l-2 border-[#F9D25F] border-solid font-semibold w-[300px] animate-pulse flex items-center">
        <p>Loading Infamy Data...</p>
      </div>
    );
  }

  if(!infamyScoreChartData){
    return(<div className="bg-gradient-to-r from-[#a74e4e80] to-[#191b2a00] p-2 border-l-2 border-[#a74e4e] border-solid font-semibold w-[300px]">No Data Found</div>)
  }

  if (statsError) {
    return (
      <div className=" bg-[#2C2A36] flex justify-center items-center">
        Error: {statsError.message}
      </div>
    );
  }
 
  return (
   <>
      <InfamyBarChart chartData={data} axisLabels={axisData} />
   </>
  
  );
};

export default InfamyScoreChart;
