import { IoMdCloseCircleOutline } from "react-icons/io";
import { useEffect, useState } from "react";
import { addToDisabledPlayers } from "lib/utils";
import { disablePlayers } from "./DisablePlayers";
import { disableTeams } from "./DisableTeams";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedPlayer,
  setDisabledPlayer,
  setIsOpen,
  setTempPlayer,
  setDisabledTeams
} from "data/r6/CreateTeamSlice";
import useGetQuery from "hooks/useGetQuery";


const PositionPopUp = ({playerData,slug}) => {
  const positions = ["Pro 1", "Pro 2", "Pro 3", "Entry", "Support"];
  const [unSelectedPositions, setUnSelectedPositions] = useState(positions);

  const { selectedPlayers, isOpen, tempPlayer,selectedTeam } = useSelector(
    (state) => state.create_team_r6
  );

  const teamRosterDataUrl=
  "https://script.google.com/macros/s/AKfycbxDaYLQH4dMclrGHbSs4A1-68DTKaDY_ThqATZO2fzIT7IfTfYH9m2EoNSJThglvwp9sg/exec";

  const [data] = useGetQuery(
    `${teamRosterDataUrl}?id=${slug}`
  );

  useEffect(() => {
    let newUnSelectedPlayers = positions.filter(
      (position) => !selectedPlayers.map((p) => p.position).includes(position)
    );

    setUnSelectedPositions(newUnSelectedPlayers);
  }, [selectedPlayers]);

  const dispatch = useDispatch();

  function closePopUp() {
    dispatch(setIsOpen(false));
    dispatch(setTempPlayer({}));
  }

  function selectPlayer(position) {
    let player = tempPlayer;
    let newSelectedPlayers = [
      ...selectedPlayers,
      {
        ...player,
        position: position,
        index: positions.indexOf(position),
      },
    ];
    
    dispatch(
      setSelectedPlayer({
        ...player,
        position: position,
        index: positions.indexOf(position),
      })
    );

    const { disabledPlayers } = disablePlayers(
      newSelectedPlayers,
      selectedTeam,
      playerData
    );

    const { disabledTeams } = disableTeams({}, newSelectedPlayers, data?.data);
    dispatch(setDisabledTeams(disabledTeams));

    dispatch(setDisabledPlayer(disabledPlayers));
    dispatch(setIsOpen(false));
    dispatch(setTempPlayer({}));

    const numberOfPlayers = 5;
    if (newSelectedPlayers.length >= numberOfPlayers) {
      const disabledPlayers = addToDisabledPlayers(playerData);
      dispatch(setDisabledPlayer(disabledPlayers));
    }
  }

  if (!isOpen) {
    return <></>;
  }

  return (
    <section className="bg-[#00000080] h-screen flex  justify-center items-center absolute top-0 bottom-0 left-0 right-0 m-auto text-xl">
      <div className="flex flex-col justify-end bg-[#191B2A] p-2 w-1/2 md:w-1/4 pb-4 rounded-lg ">
        <figure className="w-full flex justify-end">
        <IoMdCloseCircleOutline
          className="text-[#F25F4C] cursor-pointer"
          onClick={(e) => closePopUp()}
          size={24}
        />
        </figure>
        <div className=" text-center flex flex-col gap-2">
          {unSelectedPositions.map((position) => (
            <button
              key={position}
              className="text-[#ffffff] hover:text-[#f9d25f] hover:scale-110 duration-300 "
              onClick={() => {
                selectPlayer(position);
              }}
            >
              {position}
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PositionPopUp;
