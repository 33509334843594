import React from 'react'

const TeamSide = ({teamData}) => {
    if (!teamData) {
        return "Infamy";
      }
  return (
    <div className="p-2 sm:p-10 bg-gradient-to-t sm:bg-gradient-to-l from-[#674ea741] to-[#191b2a] flex flex-col items-center justify-center gap-5 sm:gap-10 overflow-hidden border-b-2 sm:border-r-2 sm:border-b-0 border-solid border-[#674ea780] ">
        <h1>
        {teamData.team_name}
        </h1>
        <img src={`${teamData.team_logo_url}`} alt={`${teamData.team_name}`} />
        <p>
          {
            teamData.group === ""?"":`Group ${teamData.group}`
          }
        </p>
    </div>
  )
}

export default TeamSide