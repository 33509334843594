"use client";
import React from "react";
import AccoladesCards from "./AccoladesCards";
import { ErrorBoundary } from "react-error-boundary";

const Accolades = ({ statsLoading, statsError, accoladesData }) => {
  console.log(accoladesData);
  if (statsLoading) {
    return (
      <div className=" h-[50px] bg-gradient-to-r from-[#f9d25f80] to-[#191b2a00] p-2 border-l-2 border-[#F9D25F] border-solid font-semibold w-[300px] animate-pulse flex items-center">
        <p>Accolades Data is Loading...</p>
      </div>
    );
  }

  if (statsError) {
    return (
      <div className=" bg-[#2C2A36] flex justify-center items-center">
        Error: {statsError.message}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-3 sm:gap-5">
      <h1 className="text-xl sm:text-2xl">Accolades</h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className=" p-4 sm:p-8 flex flex-col items-center gap-4 border-[#ffffff16] border-2 border-solid rounded-lg">
          <h3 className="sm:text-xl sm:font-semibold">Highest Infamy Points</h3>
 
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <AccoladesCards
              cardDetails={accoladesData.highest_infamy_points}
              unit={"pts"}
              dSpace={2}
              unitValuesKey={"infamy_score"}
            />
          </ErrorBoundary>

        </div>
        <div className="w-full p-4 sm:p-8 flex flex-col items-center gap-4 border-[#ffffff16] border-2 border-solid rounded-lg">
          <h3 className="sm:text-xl sm:font-semibold">Lowest Infamy Points</h3>
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <AccoladesCards
            cardDetails={accoladesData.lowest_infamy_points}
            unit={"pts"}
            dSpace={2}
            unitValuesKey={"infamy_score"}
          />
          </ErrorBoundary>
        </div>
        <div className="w-full p-4 sm:p-8 flex flex-col items-center gap-4 border-[#ffffff16] border-2 border-solid rounded-lg">
          <h3 className="sm:text-xl sm:font-semibold">Highest Kills</h3>
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <AccoladesCards
            cardDetails={accoladesData.highest_kills}
            unit={"kills"}
            dSpace={0}
            unitValuesKey={"kills"}
          />
          </ErrorBoundary>
        </div>
        <div className="w-full p-4 sm:p-8 flex flex-col items-center gap-4 border-[#ffffff16] border-2 border-solid rounded-lg">
          <h3 className="sm:text-xl sm:font-semibold">Highest 1st Kill / 1st Death Ratio</h3>
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <AccoladesCards
            cardDetails={accoladesData.highest_1k_1d_ratio}
            unit={"deaths"}
            dSpace={0}
            unitValuesKey={"deaths"}
          />
          </ErrorBoundary>
        </div>
        <div className="w-full p-4 sm:p-8 flex flex-col items-center gap-4 border-[#ffffff16] border-2 border-solid rounded-lg">
          <h3 className="sm:text-xl sm:font-semibold">Lowest Point Value</h3>
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <AccoladesCards
            cardDetails={accoladesData.lowest_point_value}
            unit={"picks"}
            dSpace={0}
            unitValuesKey={"picks"}
            />
            </ErrorBoundary>
        </div>
        <div className="w-full p-4 sm:p-8 flex flex-col items-center gap-4 border-[#ffffff16] border-2 border-solid rounded-lg">
          <h3 className="sm:text-xl sm:font-semibold">Highest Point Value</h3>
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <AccoladesCards
            cardDetails={accoladesData.highest_point_value}
            unit={"pts"}
            dSpace={2}
            unitValuesKey={"point_value"}
            />
            </ErrorBoundary>
        </div>
        <div className="w-full p-4 sm:p-8 flex flex-col items-center gap-4 border-[#ffffff16] border-2 border-solid rounded-lg">
          <h3 className="sm:text-xl sm:font-semibold">Highest K/D Ratio</h3>
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
          <AccoladesCards
            cardDetails={accoladesData.highest_k_d_ratio}
            unit={"K/D"}
            dSpace={2}
            unitValuesKey={"kd_ratio"}
            />
            </ErrorBoundary>
        </div>
        
      </div>
    </div>
  );
};

export default Accolades;
