import React, { useState, useEffect } from "react";
import TournamentDetailsHeader from "../../components/TournamentDetailsHeader";
import TeamsRoster from "./components/TeamsRoster";
import TeamsRosterTableView from "./components/TeamsRosterTableView";
import {
  DataFilter,
  groupPlayersByTeams,
  orderData,
} from "../../../../../lib/utils";
import FilterInput from "./components/FilterInput";
import { useSelector } from "react-redux";
import useGetQuery from "hooks/useGetQuery";
import { ErrorBoundary } from "react-error-boundary";
import TeamBuilder from "./components/TeamBuilder";

const PlayerRoster = ({ tournamentSlug }) => {
  const RosterDataUrl =
    "https://script.google.com/macros/s/AKfycbys06ZWszmnDQvOxPvtxIyoREzORm76YkwTQ_nSOLVOVvrheBuK78s9E_Yw6uZFA4L70w/exec";

  const [tableView, setTableView] = useState(true);
  const [data, isLoading, error] = useGetQuery(
    `${RosterDataUrl}?id=${tournamentSlug}`
  );

  const filterRoster = useSelector((state) => state.filter_roster_valorant);
  const [groupedTeam, setGroupedTeam] = useState({});
  const [filterPlayerData, setFilterPlayerData] = useState([]);

  useEffect(() => {
    if (data) {
      let filteredData = new DataFilter(data.data)
        .filterByPlayerName(filterRoster.playerName)
        .filterByTeam(filterRoster.teamName)
        .filterByPrice(...filterRoster.price)
        .filterByRoles(filterRoster.role)
        .getData();
      filteredData = orderData(filteredData, "team_id");
      setFilterPlayerData(filteredData);
      setGroupedTeam(groupPlayersByTeams(filteredData));
    }
  }, [data, filterRoster]);

  return (
    <div className=" p-2 sm:p-5 h-full overflow-y-scroll flex flex-col gap-5 ">
      <div className="bg-[#191B2A] rounded-xl p-3 sm:p-8 flex flex-col gap-8 primary-font shadow-lg">
        <h1 className="text-xl sm:text-2xl md:text-3xl font-semibold py-2 text-center sm:text-left">
          Roster
        </h1>

        <ErrorBoundary
          fallback={<div>Error:Tournament Details Failed to Load</div>}
        >
          <TournamentDetailsHeader tournamentSlug={tournamentSlug} />
        </ErrorBoundary>
        <ErrorBoundary
          fallback={<div>Error:Tournament Details Failed to Load</div>}
        >
          <FilterInput />
        </ErrorBoundary>

        <p>
          Players Found :{" "}
          <span className="text-[#f9d25f] font-bold">
            {filterPlayerData?.length}
          </span>
        </p>
        <div>
          <button
            className={`${
              tableView
                ? `bg-[#f9d25f] text-[#191B2A] px-4 py-2 rounded-lg`
                : `font-bold hover:text-[#f9d25f]  px-4 py-2`
            } `}
            onClick={(e) => setTableView(!tableView)}
          >
            Table View
          </button>
          <button
            className={`${
              tableView
                ? `font-bold hover:text-[#f9d25f]  px-4 py-2`
                : `bg-[#f9d25f] text-[#191B2A] px-4 py-2 rounded-lg`
            }`}
            onClick={(e) => setTableView(!tableView)}
          >
            Card View
          </button>
        </div>
      </div>

      {tableView ? (
        // <div className="w-full">

        <div className=" xl:grid xl:grid-cols-[auto,minmax(300px,_500px)] gap-5 w-full relative">
          <div className="bg-[#191B2A] rounded-xl flex flex-col gap-4 primary-font shadow-lg p-4 overflow-x-auto">
            <ErrorBoundary
              fallback={<div>Error:Teams Roster Table View Failed to Load</div>}
            >
              <h2 className="py-2 text-xl">Roster Table</h2>
              <TeamsRosterTableView
                statsLoading={isLoading}
                statsError={error}
                playerData={filterPlayerData}
                setFilterPlayerData={setFilterPlayerData}
              />
            </ErrorBoundary>
          </div>
          <ErrorBoundary
            fallback={<div>Error:Teams Builder Failed to Load</div>}
          >
            <TeamBuilder slug={tournamentSlug} playerData={filterPlayerData} />
          </ErrorBoundary>
        </div>
      ) : (
        <div className="bg-[#191B2A] rounded-xl p-3 sm:p-8 primary-font shadow-lg space-y-8">
          <ErrorBoundary
            fallback={<div>Error:Teams Roster Failed to Load</div>}
          >
            <TeamsRoster
              statsLoading={isLoading}
              statsError={error}
              teamPlayers={groupedTeam}
              playerData={filterPlayerData}
            />
          </ErrorBoundary>
        </div>
      )}
    </div>
  );
};

export default PlayerRoster;
