import React from "react";
import Select from "react-select";

export default function ThemedSelect({
  options,
  onChange,
  className,
  placeholder,
  defaultValue,
}) {
  return (
    <Select
      options={options}
      placeholder={placeholder}
      defaultValue={defaultValue}
      className={className}
      isClearable={true}
      onChange={onChange}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: "#674EA780", // option hover background color
          primary: "#674EA7", //control border color and selected option background color
          neutral80: "white", //control text color
          neutral0: "#191B2A", // control background color
        },
      })}
    />
  );
}
