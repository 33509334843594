import React from "react";
import TournamentDetailsHeader from "../../components/TournamentDetailsHeader";
import LeaderBoardTable from "./components/LeaderBoardTable";
import { ErrorBoundary } from "react-error-boundary";

const LeaderBoard = ({tournamentSlug}) => {
  return (
    <div className="p-2 sm:p-5 h-full overflow-y-scroll flex flex-col gap-5 ">
      <div className="bg-[#191B2A] rounded-xl p-3 sm:p-8 flex flex-col gap-8 primary-font shadow-lg">
      <h1 className="text-xl sm:text-2xl md:text-3xl font-semibold py-2 text-center sm:text-left">Leaderboard</h1>

      <ErrorBoundary fallback={<div>Error:Tournament Details Failed to Load</div>}>
          <TournamentDetailsHeader tournamentSlug={tournamentSlug} />
        </ErrorBoundary>
      </div>
     
      {/* MAIN TABLE */}
      <div className="bg-[#191B2A] rounded-xl p-3 sm:p-8 flex flex-col gap-8 primary-font shadow-lg">
      <ErrorBoundary fallback={<div>Error:Leader Board Table Details Failed to Load</div>}>
      <LeaderBoardTable tournamentSlug={tournamentSlug}/>
        </ErrorBoundary>
      </div>
    </div>
  );
};

export default LeaderBoard;
