"use client";
import React from "react";
import { ScaleLoader } from "react-spinners";

const RulesList = ({ rulesLoading, rulesError, rulesListData }) => {
  if (rulesLoading) {
    return (
      <div className="bg-[#2C2A36] flex justify-center items-center">
        <ScaleLoader color="#F9D25F" />
      </div>
    );
  }

  if (rulesError) {
    return (
      <div className="h-screen bg-[#2C2A36] flex justify-center items-center">
        Error: {rulesError.message}
      </div>
    );
  }

  return (
    <>
      <ul className=" list-disc space-y-4 secondary-font text-sm sm:text-base overflow-auto">
        <li>
          Use the given{" "}
          <span className="text-[#f9d25f]">
            {" "}
            Salary {rulesListData?.salary}
          </span>{" "}
          to pick{" "}
          <span className="text-[#f9d25f]"> 5 pros & 1 Team & 1 Map</span>.
        </li>
        <li>
          The total cost of your roster cannot exceed the Salary Cap, otherwise
          it is invalid.
        </li>
        <li>
          There is no restriction based on Team, Region or most played OPS. If
          team cost fits the salary, then its valid
        </li>
        <li>
          2 of pros on your team get special multipliers - Entry & Support
          <br /> <br />
          <ul className="space-y-2 ">
            <li>
              You can choose any Pro on your team to be your Entry or Support.
              Its up to you to use their most played Operators (OPS) to
              determine the best picks
            </li>
            <li>
              Please specify which pros are your ENT/SUP. If none are specified
              then we will auto-assign them to be the last 2 pros on your team
            </li>
            <li className="md:grid md:grid-cols-[170px,_auto]">
              <span className="text-[#f9d25f]">Entry (ENT) -</span>
              <p className="flex flex-col">
                <span>
                  Receive a 1.5X multiplier applied to their 1st Kills & 1st
                  Death Stats
                </span>
                <span>
                  Keep in mind if they go negative in 1st K/1st D, then that
                  will be multiplied so pick carefully
                </span>
              </p>
            </li>
            <li className="md:grid md:grid-cols-[170px,_auto]">
              <span className="text-[#f9d25f]">Support (SUP) -</span>
              <p className="flex flex-col">
                <span>Receive a 2X multiplier to Plant Stats</span>
              </p>
            </li>
          </ul>
        </li>
        <li>
          Team Rounds
          <ul className="space-y-2">
            <li>You pick 1 Team to earn you Round Win points</li>
            <li>
              The Team is to be considered the same as a pro when it comes to
              salary. Each Team will have their own cost and must be factored
              into your overall Salary that is shared with the Pros
            </li>
            <li>
              Teams earn points for Round Wins and loose points for Round Losses
            </li>
            <li>
              There is no direct bonus or interact by picking a Pro & their
              Team. Their points do not directly interact with one another.{" "}
            </li>
          </ul>
        </li>
        <li>
          Map Points
          <ul className="space-y-2">
            <li>Pick 1 Map from the Competitive Pool to earn Map Points</li>
            <li>
              Every time the Map you picked is played, you earn 1 Map Point
            </li>
            <li>
              Currently Maps do not cost anything, so they do not impact your
              remaining Salary.
            </li>
          </ul>
        </li>
        <li>
          There is no reward to any Salary money left over so spend wisely.
        </li>
        <li>
          <span className="text-[#f9d25f]">How to Submit Your Team</span>
          <ul className="space-y-2 list-decimal">
            <li>
              Once you made your picks, click Create Team button to generate the
              Team Entry Template.
            </li>
            <li>Click the Copy button.</li>
            <li>
              Paste your Team template in the #fantasy-r6 channel in the Infamy
              Discord{" "}
              <a
                target="_blank"
                href="https://discord.gg/pYxKpat"
                className="text-[#f9d25f] hover:underline"
              >
                https://discord.gg/pYxKpat
              </a>
            </li>
            <li>
              Add the robot 🤖 reaction to your post. Our discord bot will add
              your team to the Tournament's Leaderboard.
            </li>
            <li>
              Once the 🤖 reaction changes to the check mark ✅your team has
              been successfully entered.
            </li>
            <li>
              As you make your picks, your remaining budget will be displayed
              above your Team.
            </li>
            <li>
              If there is an error in the Template or your team itself, our
              discord bot will message you with the error.
            </li>
            <li>
              If you'd like to change your team, please re-pick and paste your
              updated team in the #fantasy-r6 channel w/ the 🤖 reaction. It
              will replace your original team in the Leaderbaord.{" "}
            </li>
          </ul>
        </li>
        <li>
          Team Entry Template: <br />
          <br />
          <pre className="bg-[#00000040] overflow-auto p-2 lg:p-4 rounded">
            R6 Siege <br />
            Tournament Id---0 <br />
            Tournament Name---Gamers8 Fantasy Teams <br />
            ----------
            <br />
            Team--Price
            <br />
            ----------
            <br />
            M80--14 <br />
            ----------
            <br />
            Position--Pro_Player--Price
            <br />
            ----------
            <br />
            Pro 1--Joker619--15
            <br />
            Pro 2--Alem40--16
            <br />
            Pro 3--Benjamaster--17
            <br />
            Entry--kondz--15
            <br />
            Support--Psycho--22
            <br />
            ----------
            <br />
          </pre>
        </li>
        <li className="flex flex-col gap-2 items-start">
          Player Scoring Breakdown:
          <table>
            <thead>
              <tr>
                <th>Stats</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(rulesListData).map((stat, index) => {
                if (index > 0 && index < 8) {
                  return (
                    <tr key={index}>
                      <td>{stat[0]}</td>
                      <td>{stat[1]}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </li>
        <li className="flex flex-col gap-2 items-start">
          Team Score Breakdown:
          <table>
            <thead>
              <tr>
                <th>Stats</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(rulesListData).map((stat, index) => {
                if (index > 7 && index < 11) {
                  return (
                    <tr key={index}>
                      <td>{stat[0]}</td>
                      <td>{stat[1]}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </li>
        <li className="flex flex-col gap-2 items-start">
          Map Score Breakdown:
          <table>
            <thead>
              <tr>
                <th>Stats</th>
                <th>Points</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(rulesListData).map((stat, index) => {
                if (index > 10) {
                  return (
                    <tr key={index}>
                      <td>{stat[0]}</td>
                      <td>{stat[1]}</td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
        </li>
      </ul>
    </>
  );
};

export default RulesList;
