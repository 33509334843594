import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedPlayers: [],
  selectedRole: {},
  disabledPlayers: {},
  numberOfDisabledPlayers: 0,
  numberOfDisabledTeams: 0,
  numberOfDisabledMaps: 0,
  selectedTeam: {},
  tempPlayer: {},
  isOpen: false,
  disabledTeams:{},
  disabledMaps:{},
  selectedMap: {},
};

export const CreateTeamSlice = createSlice({
  name: "create_team",
  initialState,
  reducers: {
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload;
    },
    setSelectedPlayer: (state, action) => {
      let playerDetails = action.payload;
      state.selectedPlayers.push(playerDetails);
    },
    setTempPlayer: (state, action) => {
      state.tempPlayer = action.payload;
    },
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
    setSelectedTeam: (state, action) => {
      state.selectedTeam = action.payload;
    },

    setSelectedMap: (state, action) => {
      state.selectedMap = action.payload;
    },
    removeSelectedPlayer: (state, action) => {
      let position = action.payload;
      state.selectedPlayers.splice(position, 1);
    },
    replaceSelectedPlayer: (state, action) => {
      let [position, player] = action.payload;
      state.selectedPlayers.splice(position, 1, player);
    },

    setDisabledMaps: (state, action) => {
      const disabledMaps = action.payload;
      state.numberOfDisabledMaps = Object.keys(disabledMaps).length;
      state.disabledMaps = disabledMaps;
    },

    setDisabledTeams: (state, action) => {
      const disabledTeams = action.payload;
      state.numberOfDisabledTeams = Object.keys(disabledTeams).length;
      state.disabledTeams = disabledTeams;
    },

    setDisabledPlayer: (state, action) => {
      const disabledPlayers = action.payload;
      state.numberOfDisabledPlayers = Object.keys(disabledPlayers).length;
      state.disabledPlayers = disabledPlayers;
    },
    reset: (state) => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  setSelectedRole,
  setSelectedPlayer,
  setDisabledPlayer,
  removeSelectedPlayer,
  reset,
  replaceSelectedPlayer,
  setTempPlayer,
  setIsOpen,
  setSelectedTeam,
  setDisabledTeams,
  setSelectedMap,
  setDisabledMaps,
} = CreateTeamSlice.actions;

export default CreateTeamSlice.reducer;
