import { isSelected, getPosition } from "lib/utils";
import { disablePlayers } from "./DisablePlayers";
import { useSelector, useDispatch } from "react-redux";
import {
  setDisabledPlayer,
  removeSelectedPlayer,
  setTempPlayer,
  setIsOpen,
} from "data/r6/CreateTeamSlice";
import { IoIosAdd, IoIosRemove } from "react-icons/io";

const SelectButton = ({ playerDetails, playerData }) => {
  const { selectedPlayers, disabledPlayers,selectedTeam } = useSelector(
    (state) => state.create_team_r6
  );
  const dispatch = useDispatch();

  function removePlayer(player) {
    let newSelectedPlayers = selectedPlayers.filter(
      (item) => item?.pro_player_id !== player?.pro_player_id
    );

    dispatch(removeSelectedPlayer(getPosition(player, selectedPlayers)));

    const { disabledPlayers } = disablePlayers(
      newSelectedPlayers,
      selectedTeam,
      playerData
    );

    dispatch(setDisabledPlayer(disabledPlayers));
  }

  function showPopUp(player, isOpen) {
    if (isOpen) {
      dispatch(setTempPlayer(player));
      dispatch(setIsOpen(isOpen));
    }
  }

  return (
    <>
      {isSelected(playerDetails, selectedPlayers) ? (
        <button
          className="p-1 border-2 border-[#F25F4C] w-fit flex content-end rounded cursor-pointer  text-[#F25F4C] "
          onClick={() => removePlayer(playerDetails)}
        >
          <IoIosRemove size={24} />
        </button>
      ) : (
        <button
          className="p-1 border w-fit flex content-end rounded cursor-pointer relative group  "
          onClick={() => showPopUp(playerDetails, true)}
          disabled={disabledPlayers[playerDetails.pro_player_id]}
        >
          <IoIosAdd size={24} />
        </button>
      )}
    </>
  );
};

export default SelectButton;
