import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  performance: {
    team_name: "",
    pro_player: "",
    infamy_score: ["", ""],
    kills: ["", ""],
    deaths: ["", ""],
    "1st_kill": ["", ""],
    "1st_death": ["", ""],
    "1vx": ["", ""],
    clutch: ["", ""],
    plants: ["", ""],
    explosion: ["", ""],
    point_value: ["", ""],
    picks: ["", ""],
    pick_rate: ["", ""],
    price: ["", ""],
    option: [],
  },

  leaderboard: {
    rank: ["", ""],
    players_left: ["", ""],
    pro_1_points: ["", ""],
    pro_2_points: ["", ""],
    pro_3_points: ["", ""],
    entry_points: ["", ""],
    support_points: ["", ""],
    team_points: ["", ""],
    map_points: ["", ""],
    infamy_score: ["", ""],
    percentile: ["", ""],
    discord_name: "",
  },
};

export const FilterPerformanceSlice = createSlice({
  name: "filter_performance_overwatch",
  initialState,
  reducers: {
    setText: (state, action) => {
      let payload = action.payload;
      state[payload.tab][payload.key] = payload.value;
    },

    // setOption: (state, action) => {
    //   let payload = action.payload;
    //   state[payload.key] = payload.value;
    // },

    setRange: (state, action) => {
      let payload = action.payload;
      state[payload.tab][payload.key] = [...payload.value];
    },

    resetColumn: (state, action) => {
      let payload = action.payload;
      state[payload.tab][payload.key] = initialState[payload.tab][payload.key];
    },

    reset: (state) => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setText, setOption, setRange, reset, resetColumn } =
  FilterPerformanceSlice.actions;

export default FilterPerformanceSlice.reducer;
