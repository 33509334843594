import React, { useState } from "react";
import PlayerDetails from "./PlayerDetails";
import { ErrorBoundary } from "react-error-boundary";
import { useSelector } from "react-redux";

const PlayerSide = ({ playersData, statsLoading, statsError }) => {
  const [showDetails, setShowDetails] = useState(false);
  const [playerData, setPlayerData] = useState({});
  const [rank, setRank] = useState(0);

  const filterPerformance = useSelector((state) => state.filter_performance_r6);

  const unit = {
    infamy_score: { value: "infamy_score", label: "Pts", dp: 2 },
    kills: { value: "kills", label: "Kills", dp: 0 },
    deaths: { value: "deaths", label: "Deaths", dp: 0 },
    '1st_kill': { value: "1st_kill", label: "1st Kill", dp: 0 },
    '1st_death': { value: "1st_death", label: "1st death", dp: 0 },
    pick_rate: { value: "pick_rate", label: "%", dp: 2 },
    point_value: { value: "point_value", label: "pts", dp: 2 },
    price: { value: "price", label: "", dp: 2 },
  };

  const handleShowDetailsButton = (data, i) => {
    setPlayerData(data);
    setShowDetails(true);
    setRank(i + 1);
  };
  if (statsLoading) {
    return (
      <div className=" h-[50px] bg-gradient-to-r from-[#f9d25f80] to-[#191b2a00] p-2 border-l-2 border-[#F9D25F] border-solid font-semibold w-[300px] animate-pulse flex items-center">
        <p>Loading...</p>
      </div>
    );
  }

  if (statsError) {
    return (
      <div className=" bg-[#2C2A36] flex justify-center items-center">
        Error: {statsError.message}
      </div>
    );
  }

  return (
    <div className=" flex flex-col gap-8">
      <h1 className=" text-2xl">Players</h1>

      <div className=" grid grid-cols-fluid gap-5 justify-center  ">
        {showDetails ? (
          <ErrorBoundary
            fallback={<div>Error:Player Details Failed to Load</div>}
          >
            <PlayerDetails
              playerData={playerData}
              callBack={setShowDetails}
              rank={rank}
            />
          </ErrorBoundary>
        ) : (
          <></>
        )}
        {playersData?.map((player, index) => (
          <div
            key={player?.pro_player_id}
            className=" bg-[#191b2a]  rounded-xl border-2 border-solid border-[#AF9DDD80] "
          >
            <div
              className={`bg-gradient-to-t rounded-t-xl ${
                index === 0
                  ? `from-[#f9d25f]`
                  : index === 1
                  ? `from-[#D9D9D9]`
                  : index === 2
                  ? `from-[#874300]`
                  : `from-[#674EA7]`
              } to-[#000000] flex items-end justify-center pt-6 h-[300px] relative overflow-hidden`}
            >
              <img
                src={`${player?.pro_player_image_url}`}
                alt={`${player?.pro_player}`}
                className="absolute top-10"
              />
            </div>
            <div className=" h-1 w-full bg-gradient-to-r  from-[#674EA7] to-[#FA2328] "></div>
            <div className="flex p-4 items-center gap-4 bg-gradient-to-r  from-[#0000000a] to-[#674ea747] relative overflow-hidden z-10">
              <h1 className="absolute bottom-0 -right-[50%] text-6xl opacity-5">
                INFAMY
              </h1>
              <div className="flex flex-col gap-2">
                <p
                  className="font-medium text-xl"
                  title={`${player?.pro_player}`}
                >
                  {" "}
                  Name:{" "}
                  {player?.pro_player.length < 9
                    ? player?.pro_player
                    : player?.pro_player.slice(0, 8) + "..."}
                </p>

                <p className="text-[#f9d25f] secondary-font font-medium text-lg">
                  {filterPerformance.orderBy === "price" ? "$" : ""}
                  {player[filterPerformance.orderBy] === ""
                    ? +player[filterPerformance.orderBy]
                    : +player[filterPerformance.orderBy].toFixed(
                        unit[filterPerformance.orderBy].dp
                      )}{" "}
                  {unit[filterPerformance.orderBy].label}
                  {/* {console.log(player)} */}
                </p>
                {/* <h1 className=" flex gap-2 ">
                  Role:{" "}
                  {player?.role?.map((r) => (
                    <span key={r}>{r}</span>
                  ))}
                </h1> */}
              </div>
            </div>
            <div className=" h-0.5 w-full bg-gradient-to-r  to-[#674EA7] from-[#FA2328] "></div>
            <button
              className="py-3 text-center w-full"
              onClick={() => handleShowDetailsButton(player, index)}
            >
              Show Details
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlayerSide;
