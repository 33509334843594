import React, { useState } from "react";
import PlayerDetails from "./PlayerDetails";
import { ErrorBoundary } from "react-error-boundary";
import { formatToCurrency, formatToNumber } from "lib/utils";
import { useSelector } from "react-redux";
import ColumnControls, {
  FilterByRange,
  FilterByText,
  ResetColumn,
  SortData,
} from "pages/Games/components/columnControls/ColumnControls";

const PlayerSide = ({
  playersData,
  statsLoading,
  statsError,
  setFilterPlayerData,
  columns,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [playerData, setPlayerData] = useState({});
  const [rank, setRank] = useState(0);

  const handleShowDetailsButton = (data, i) => {
    setPlayerData(data);
    setShowDetails(true);
    setRank(i + 1);
  };

  const filterPerformance = useSelector(
    (state) => state.filter_performance_r6.performance
  );

  if (statsLoading) {
    return (
      <div className=" h-[50px] bg-gradient-to-r from-[#f9d25f80] to-[#191b2a00] p-2 border-l-2 border-[#F9D25F] border-solid font-semibold w-[300px] animate-pulse flex items-center ">
        <p>Loading...</p>
      </div>
    );
  }

  if (statsError) {
    return (
      <div className=" bg-[#2C2A36] flex justify-center items-center">
        Error: {statsError.message}
      </div>
    );
  }

  return (
    <div className=" flex flex-col gap-8">
      <h1 className=" text-2xl">Players</h1>
      {showDetails ? (
        <ErrorBoundary
          fallback={<div>Error:Player Details Failed to Load</div>}
        >
          <PlayerDetails
            playerData={playerData}
            callBack={setShowDetails}
            rank={rank}
          />
        </ErrorBoundary>
      ) : (
        <></>
      )}
      <div className="overflow-x-auto">
        <table className="text-left w-[2500px] overflow-auto min-h-[300px]  ">
          <thead>
            {columns.map((column, index) => (
              <th key={index} className="align-top">
                <ColumnControls
                  columnName={column.label}
                  columnType={column.type}
                  data={playersData}
                  setData={setFilterPlayerData}
                  OrderByName={column.value}
                  isAscending={false}
                ></ColumnControls>
              </th>
            ))}
          </thead>
          <tbody>
            {playersData?.map((player, index) => (
              <tr
                key={player?.pro_player_id}
                onClick={() => handleShowDetailsButton(player, index)}
                className=" cursor-pointer"
              >
                <td>
                  <div className="flex gap-4 items-center">
                    <img
                      src={`${player?.team_logo_url}`}
                      alt={`${player?.team_name}`}
                      width={24}
                    />
                    <p className="hidden sm:table-cell">{player.team_name}</p>
                  </div>
                </td>
                <td>
                  <div className=" flex gap-2">
                    <img
                      src={`${player?.pro_player_image_url}`}
                      alt={`${player?.pro_player}`}
                      width={24}
                      className="hidden sm:table-cell"
                    />
                    <p>{player?.pro_player}</p>
                    <>
                      {index === 0
                        ? "🥇"
                        : index === 1
                        ? "🥈"
                        : index === 2
                        ? "🥉"
                        : ""}
                    </>
                  </div>
                </td>

                <td className="text-[#f9d25f] secondary-font font-medium text-lg">
                  {formatToNumber(player?.infamy_score, 0)}
                </td>
                <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                  {formatToNumber(player?.kills)}
                </td>
                <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                  {formatToNumber(player?.deaths)}
                </td>
                <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                  {formatToNumber(player["1st_kill"])}
                </td>
                <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                  {formatToNumber(player["1st_death"])}
                </td>
                <td className=" secondary-font font-medium text-lg">
                  {formatToNumber(playerData["1vx"], 0)}
                </td>
                <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                  {formatToNumber(player?.clutch)}
                </td>
                <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                  {formatToNumber(player?.plants)}
                </td>
                <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                  {formatToNumber(player?.explosion)}
                </td>
                <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                  {formatToNumber(player?.point_value)}
                </td>
                <td className=" secondary-font text-lg hidden lg:table-cell font-normal">
                  {formatToNumber(player?.picks)}
                </td>
                <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                  {formatToNumber(player?.pick_rate)}
                </td>
                <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                  {formatToCurrency(player?.price)}
                </td>
                <td className="secondary-font text-lg hidden lg:table-cell font-normal">
                  {formatToCurrency(+player?.infamy_score / +player?.price)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlayerSide;
