import React from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { formatToNumber, formatToPercentage } from "lib/utils";

const PlayerDetails = ({ playerDetails, callBack }) => {
  return (
    <div className="h-full  bg-[#0000007d] fixed top-0 left-0 w-full flex justify-center items-center  sm:p-8 secondary-font  lg:text-xl animate-fade-in z-50 ">
      <div className="bg-[#191B2A] h-5/6 p-4 w-[90%] rounded-lg flex flex-col gap-8 sm:p-8 sm:h-auto sm:m-0 ">
        <div className="flex justify-between items-center">
          <h1 className=" text-2xl ">
            {playerDetails?.discord_name}
            {playerDetails?.emojis}
          </h1>
          <IoMdCloseCircleOutline
            className="text-[#F25F4C] cursor-pointer"
            onClick={(e) => callBack(false)}
            size={24}
          />
        </div>
        <div className="flex flex-col gap-2 sm:gap-8 overflow-auto text-sm sm:text-base ">
          <div className="flex flex-col gap-2  sm:flex-wrap ">
            <p className="flex-1">
              <span className=" text-[#F9D25F]">Rank : </span>
              {playerDetails?.rank}
            </p>
            <p className="flex-1">
              <span className=" text-[#F9D25F]">Infamy Score : </span>
              {formatToNumber(playerDetails?.infamy_score, 2)}
            </p>
            <p className="flex-1">
              <span className=" text-[#F9D25F]">Percentile : </span>
              {formatToPercentage(playerDetails?.percentile, 2)}
            </p>
          </div>
          <div className="grid grid-cols-1 pt-4 sm:p-0 sm:grid-cols-2 gap-y-4 sm:gap-y-8">
            {/* Player 1 */}
            <div>
              <div>
                <p>{playerDetails?.pro_1}</p>
                <p>
                  <span className=" text-[#AF9DDD] ">Points : </span>
                  {formatToNumber(playerDetails.pro_1_points, 0)}
                </p>
              </div>
            </div>
            {/* Player 2 */}
            <div>
              <div>
                <p>{playerDetails?.pro_2}</p>
                <p>
                  <span className=" text-[#AF9DDD] ">Points : </span>
                  {formatToNumber(playerDetails.pro_2_points, 0)}
                </p>
              </div>
            </div>
            {/* Player 3 */}
            <div>
              <div>
                <p>{playerDetails?.pro_3}</p>
                <p>
                  <span className=" text-[#AF9DDD] ">Points : </span>
                  {formatToNumber(playerDetails.pro_3_points, 0)}
                </p>
              </div>
            </div>
            {/* Entry */}
            <div>
              <div>
                <p>
                  <span className="  text-[#AF9DDD]">Entry : </span>
                  {playerDetails?.entry}
                </p>
                <p>
                  <span className=" text-[#AF9DDD] ">Points : </span>
                  {formatToNumber(
                    +playerDetails?.entry_points +
                      +playerDetails?.entry_bonus_points,
                    0
                  )}
                </p>
              </div>
            </div>
            {/* Support */}
            <div>
              <div>
                <p>
                  <span className="  text-[#AF9DDD]">Support : </span>
                  {playerDetails?.support}
                </p>
                <p>
                  <span className=" text-[#AF9DDD] ">Points : </span>
                  {formatToNumber(
                    +playerDetails?.support_points +
                      +playerDetails?.support_bonus_points,
                    0
                  )}
                </p>
              </div>
            </div>
            {/* Team */}
            <div>
              <div>
                <p>
                  <span className="  text-[#AF9DDD]">Team : </span>
                  {playerDetails?.team}
                </p>
                <p>
                  <span className=" text-[#AF9DDD] ">Points : </span>
                  {formatToNumber(+playerDetails?.team_points, 0)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerDetails;
