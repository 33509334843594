import React from "react";
import { Route, Routes } from "react-router-dom";
import SideBar from "../../components/SideBar";
import LeaderBoard from "./LeaderBoard/LeaderBoard";
import QuickStats from "./StatsOverview/QuickStats";
import PlayerRoster from "./ProPlayerRoster/PlayerRoster";
import PlayerPerformance from "./ProPlayerPerformance/PlayerPerformance";
import Rules from "./Rules/Rules";
import Matches from "./Matches/Matches";

const TournamentDetails = () => {
  let currentPath = window.location.pathname;
  currentPath = currentPath.split("/");
  const tournamentSlug = currentPath[currentPath.length - 1];

  return (
    <div className=" grid grid-cols-[auto_3fr] h-[100%] overflow-hidden text-white secondary-font">
      <SideBar slug={tournamentSlug} game={"overwatch"} />

      <Routes>
        <Route
          path={`/rules/${tournamentSlug}`}
          element={<Rules tournamentSlug={tournamentSlug} />}
        />
        <Route
          path={`/quick-stats/${tournamentSlug}`}
          element={<QuickStats tournamentSlug={tournamentSlug} />}
        />
        <Route
          path={`/leaderboard/${tournamentSlug}`}
          element={<LeaderBoard tournamentSlug={tournamentSlug} />}
        />
        <Route
          path={`/roster/${tournamentSlug}`}
          element={<PlayerRoster tournamentSlug={tournamentSlug} />}
        />
        <Route
          path={`/performance/${tournamentSlug}`}
          element={<PlayerPerformance tournamentSlug={tournamentSlug} />}
        />

        <Route
          path={`/matches/${tournamentSlug}`}
          element={<Matches tournamentSlug={tournamentSlug} />}
        />
      </Routes>
    </div>
  );
};

export default TournamentDetails;
