import { create } from "zustand";

export const useDetailsStore = create((set) => ({
  open: false,
  setOpen: (value) => setOpen(value, set),
  openMoreDetails: false,
  setOpenMoreDetails: (value) => setOpenMoreDetails(value, set),
}));

function setOpen(value = true, set) {
  set({ open: value });
}

function setOpenMoreDetails(value = true, set) {
  set({ openMoreDetails: value });
}
