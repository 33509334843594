import React, { useEffect, useState } from "react";
import {
  generateKDAPlayerViewChartData,
  generateKDATeamViewChartData,
  DataFilter,
} from "pages/Games/overwatch/lib/utils";
import InfamyBarChart from "components/InfamyBarChart";

const KDAPointsChart = ({
  statsLoading,
  statsError,
  KDAChartData,
  viewType,
  team,
}) => {
  const [data, setData] = useState({});
  const [axisData, setAxisData] = useState({});

  useEffect(() => {
    const filteredData = new DataFilter(KDAChartData)
      .filterByTeam(team)
      .getData();

    if (viewType === "viewByPlayers") {
      // create chart for viewByPlayer
      let chartData = generateKDAPlayerViewChartData(filteredData);
      setData(chartData);

      setAxisData({
        x: "Players",
        y: "Points",
      });
    } else {
      // create chart for viewByTeam
      let chartData = generateKDATeamViewChartData(filteredData);
      setData(chartData);

      setAxisData({
        x: "Teams",
        y: "Points",
      });
    }
  }, [viewType, team, KDAChartData]);

  if (statsLoading) {
    return (
      <div className="m-2 h-[50px] bg-gradient-to-r from-[#f9d25f80] to-[#191b2a00] p-2 border-l-2 border-[#F9D25F] border-solid font-semibold w-[300px] animate-pulse flex items-center">
        <p>Loading KDA Data...</p>
      </div>
    );
  }

  if (statsError) {
    return (
      <div className=" bg-[#2C2A36] flex justify-center items-center">
        Error: {statsError.message}
      </div>
    );
  }

  if (!KDAChartData) {
    return (
      <div className="bg-gradient-to-r from-[#a74e4e80] to-[#191b2a00] p-2 border-l-2 border-[#a74e4e] border-solid font-semibold w-[300px]">
        No Data Found
      </div>
    );
  }

  return (
    <>
      <InfamyBarChart chartData={data} axisLabels={axisData} />
    </>
  );
};

export default KDAPointsChart;
