import React from "react";
import { Link } from "react-router-dom";

const options = { month: "short", day: "numeric", year: "numeric" };
export default function TournamentTableList({ tournaments, tab }) {
  return (
    <table>
      <thead>
        <tr>
          <th>Tournament</th>
          <th>Start Date</th>
          <th>End Date</th>
          <th>Infamy Tier</th>
          <th>Notoriety</th>
        </tr>
      </thead>
      <tbody>
        {tournaments?.map((tournament) => (
          <tr
            className=" bg-[#674ea72d] hover:bg-[#674ea76d]  rounded-lg items-center secondary-font animate-fade-in duration-300"
            key={tournament.tournament_slug}
          >
            <td>
              <Link
                className="flex gap-2 items-center"
                to={`${tab}/${tournament.tournament_slug}`}
              >
                <img
                  src={tournament.tournament_logo_link}
                  alt={"logo"}
                  // alt={tournament.tournament_name}
                  className="w-8"
                />
                <div className="lg:flex-1 lg:text-center">
                  {tournament.tournament_name}
                </div>
              </Link>
            </td>
            <td>
              <Link
                className="flex gap-2 items-center"
                to={`${tab}/${tournament.tournament_slug}`}
              >
                {new Date(tournament.tournament_start_date).toLocaleDateString(
                  "en-US",
                  options
                )}
              </Link>
            </td>
            <td>
              <Link
                className="flex gap-2 items-center"
                to={`${tab}/${tournament.tournament_slug}`}
              >
                {new Date(tournament.tournament_end_date).toLocaleDateString(
                  "en-US",
                  options
                )}
              </Link>
            </td>
            <td>
              <Link
                className="flex gap-2 items-center"
                to={`${tab}/${tournament.tournament_slug}`}
              >
                {tournament.infamy_tier}
              </Link>
            </td>
            <td>
              <Link
                className="flex gap-2 items-center"
                to={`${tab}/${tournament.tournament_slug}`}
              >
                {getNotority(tournament.infamy_tier)}
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function getNotority(infamy_tier) {
  const obj = {
    Regional: 1,
    "Regional Playoffs": 1.25,
    "Major International": 1.5,
    Championship: 2,
  };
  const points = +obj[infamy_tier] * 50;
  return isNaN(points) ? 0 : points;
}
