import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // tid,tounemanet name, desciption, tournament Image, google sheet link,start date, end date, tier,
};

export const selectedTournamentSlice = createSlice({
  name: "tournament_details",
  initialState,
  reducers: {
    setTournament: (state, action) => {
      return action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setTournament } = selectedTournamentSlice.actions;

export default selectedTournamentSlice.reducer;
